/**
 * @generated SignedSource<<faf1f6a2cd2857cc0b3a821d67b81563>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type CompetitionCodePageQuery$variables = {
  fileKind: ProjectVersionFileKind;
  slug: string;
};
export type CompetitionCodePageQuery$data = {
  readonly competitionBySlug: {
    readonly slug: string;
    readonly title: string;
    readonly useCase: {
      readonly " $fragmentSpreads": FragmentRefs<"ProjectFileBrowserFragment">;
    };
  } | null | undefined;
};
export type CompetitionCodePageQuery = {
  response: CompetitionCodePageQuery$data;
  variables: CompetitionCodePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "fileKind"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionCodePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "args": (v5/*: any*/),
                "kind": "FragmentSpread",
                "name": "ProjectFileBrowserFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionCodePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectVersion",
                    "kind": "LinkedField",
                    "name": "version",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "version",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersionFile",
                        "kind": "LinkedField",
                        "name": "files",
                        "plural": true,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "concreteType": "ProjectVersionFile",
                        "kind": "LinkedField",
                        "name": "fileByKind",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProjectVersion",
                            "kind": "LinkedField",
                            "name": "projectVersion",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Competition",
                                    "kind": "LinkedField",
                                    "name": "competition",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v6/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Project",
                "abstractKey": "__isProject"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0036307fee385a28ac9e052b19fe2e63",
    "id": null,
    "metadata": {},
    "name": "CompetitionCodePageQuery",
    "operationKind": "query",
    "text": "query CompetitionCodePageQuery(\n  $slug: String!\n  $fileKind: ProjectVersionFileKind!\n) {\n  competitionBySlug(slug: $slug) {\n    slug\n    title\n    useCase {\n      ...ProjectFileBrowserFragment_4pSsFL\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectDownloadButtonFragment on ProjectVersionFile {\n  id\n  kind\n  projectVersion {\n    project {\n      __typename\n      competition {\n        slug\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectFileBrowserFragment_4pSsFL on Project {\n  __isProject: __typename\n  __typename\n  id\n  version {\n    version\n    files {\n      id\n      kind\n    }\n    fileByKind(kind: $fileKind) {\n      id\n      kind\n      ...ProjectDownloadButtonFragment\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "46c2b6bb80ec6fc1a219eb717faa383f";

export default node;
