import React, { useContext, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "./location";

export const ACCESS_TOKEN_COOKIE_NAME = "aqora-access-token";

export interface TokenClaims {
  sub: string;
  sss: string;
  typ: "refresh" | "access";
  iat: number;
  exp: number;
}

export const jwtClaims = (token: string): TokenClaims => jwtDecode(token);

export interface AuthContextValue {
  getToken: () => string | undefined;
  setTokens: (tokens: { access?: string; refresh?: string }) => void;
  revokeToken: (token_type: "access" | "refresh") => void;
  revokeTokens: () => void;
  isAuthenticated: boolean;
  userId?: string | undefined;
}

export const AuthContext = React.createContext<AuthContextValue>({
  getToken: () => undefined,
  setTokens: () => {},
  revokeToken: () => {},
  revokeTokens: () => {},
  isAuthenticated: false,
});

export const useAuth = () => useContext(AuthContext);

export const useOnAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  return useCallback(() => {
    if (searchParams.has("login_redirect")) {
      const redirect = searchParams.get("login_redirect")!;
      return location.replace(redirect);
    }
    if (searchParams.has("client_id")) {
      return navigate({
        pathname: "/oauth2/authorize",
        search: searchParams.toString(),
      });
    }
    location.replace("/");
  }, [navigate, searchParams, location]);
};
