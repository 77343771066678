import { FormattedMessage } from "react-intl";
import { ImSpinner } from "react-icons/im";
import { IconBaseProps } from "react-icons";

export function ActivityIndicator({ className, ...props }: IconBaseProps) {
  return <ImSpinner {...props} className={`${className} animate-spin`} />;
}

export function LoadingMessage() {
  return (
    <div className="flex flex-col gap-2 items-center">
      <ActivityIndicator size={24} />
      <p className="text-gray-500">
        <FormattedMessage defaultMessage="Loading, please wait..." />
      </p>
    </div>
  );
}
