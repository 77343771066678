import React, { useState, PropsWithChildren, isValidElement } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import { FaDownload } from "react-icons/fa6";
import { FormattedMessage, MessageDescriptor, defineMessage } from "react-intl";
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { NavLink } from "react-router-dom";
import { assertNever } from "../utils/helpers";
import {
  ProjectDownloadButtonFragment$data,
  ProjectDownloadButtonFragment$key,
} from "./__generated__/ProjectDownloadButtonFragment.graphql";
import {
  ArchiveKind,
  ProjectDownloadButtonDownloadArchiveQuery as DownloadArchiveQueryType,
} from "./__generated__/ProjectDownloadButtonDownloadArchiveQuery.graphql";
import { ActivityIndicator } from "./ActivityIndicator";
import Button from "./Button";
import Code from "./Code";
import DropdownItem from "./DropdownItem";
import * as Popover from "./Popover";
import download from "../utils/download";

const ProjectDownloadButtonFragment = graphql`
  fragment ProjectDownloadButtonFragment on ProjectVersionFile {
    id
    kind
    projectVersion {
      project {
        competition {
          slug
        }
      }
    }
  }
`;

export interface ProjectDownloadButtonProps extends PropsWithChildren {
  fragment: ProjectDownloadButtonFragment$key;
}

export default function ProjectDownloadButton(
  props: ProjectDownloadButtonProps,
) {
  const fragment = useFragment(ProjectDownloadButtonFragment, props.fragment);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <DownloadButton>
          {isValidElement(props.children) ? (
            props.children
          ) : (
            <DownloadButtonLabel {...fragment} />
          )}
        </DownloadButton>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className="w-96 border shadow-lg">
          <DownloadPopoverContent {...fragment} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const DownloadButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren
>(({ children, ...rest }, ref) => (
  <Button ref={ref} kind="secondary" className="space-x-2" {...rest}>
    <span>{children}</span>
    <FaDownload className="inline-block" />
  </Button>
));

function DownloadButtonLabel({ kind }: ProjectDownloadButtonFragment$data) {
  switch (kind) {
    case "TEMPLATE":
      return <FormattedMessage defaultMessage="Download template" />;
    case "DATA":
      return <FormattedMessage defaultMessage="Download data" />;
    case "PACKAGE":
      return <FormattedMessage defaultMessage="Download use-case" />;
    case "SUBMISSION_EVALUATION":
      return (
        <FormattedMessage defaultMessage="Download submission evaluation" />
      );
    default:
      assertNever(kind);
  }
}

function DownloadPopoverContent({
  id,
  kind,
  projectVersion: {
    project: { competition },
  },
}: ProjectDownloadButtonFragment$data) {
  switch (kind) {
    case "TEMPLATE":
      return <TemplateInstructions competition={competition.slug} />;
    case "DATA":
    case "PACKAGE":
    case "SUBMISSION_EVALUATION":
      return (
        <>
          <DownloadArchive projectVersionFileId={id} archKind="TAR" />
          <DownloadArchive projectVersionFileId={id} archKind="ZIP" />
        </>
      );
    default:
      assertNever(kind);
  }
}

const TemplateInstructions = ({ competition }: { competition: string }) => (
  <div className="space-y-4 p-2">
    <p className="text-balance">
      <FormattedMessage defaultMessage="In order to download this template, please follow these instructions:" />
    </p>

    <ol className="list-decimal list-inside space-y-1">
      <li>
        <a
          href="https://pypi.org/project/aqora-cli/"
          target="_blank"
          className="text-blue-600 underline inline-flex flex-row items-center space-x-1"
        >
          <span>
            <FormattedMessage defaultMessage="Install Aqora CLI" />
          </span>
          <HiOutlineExternalLink />
        </a>
      </li>
      <li>
        <FormattedMessage defaultMessage="Run:" />{" "}
        <Code content={`aqora template ${competition}`} />
      </li>
    </ol>

    <p className="text-balance">
      <FormattedMessage
        defaultMessage="Please, find out more instructions <a>in our tutorial</a>!"
        values={{
          a: (chunks) => (
            <NavLink
              to={`/competitions/h2-groundstate-energy`}
              className="text-blue-600 underline"
            >
              {chunks}
            </NavLink>
          ),
        }}
      />
    </p>
  </div>
);

interface DownloadArchiveProps {
  projectVersionFileId: ID;
  archKind: ArchiveKind;
}

const DownloadArchiveQuery = graphql`
  query ProjectDownloadButtonDownloadArchiveQuery(
    $id: ID!
    $archKind: ArchiveKind!
  ) {
    node(id: $id) {
      ... on ProjectVersionFile {
        downloadUrl(archKind: $archKind)
      }
    }
  }
`;

function DownloadArchive({
  projectVersionFileId: id,
  archKind,
}: DownloadArchiveProps) {
  const relay = useRelayEnvironment();
  const [pending, setPending] = useState(false);

  const onClick = async (evt: React.MouseEvent) => {
    evt.preventDefault();

    setPending(true);
    try {
      const query = await fetchQuery<DownloadArchiveQueryType>(
        relay,
        DownloadArchiveQuery,
        {
          id,
          archKind,
        },
      ).toPromise();

      if (!query || !query.node.downloadUrl) {
        throw new Error(`Unexpected empty response`);
      }

      download(query.node.downloadUrl);
    } finally {
      setPending(false);
    }
  };

  return (
    <DropdownItem
      className="flex gap-2 items-center"
      onClick={onClick}
      disabled={pending}
      icon={pending && <ActivityIndicator size={16} />}
    >
      <FormattedMessage
        {...downloadMessages[archKind]}
        values={{ em: (text) => <code>{text}</code> }}
      />
    </DropdownItem>
  );
}

const downloadMessages: { [K in ArchiveKind]: MessageDescriptor } = {
  TAR: defineMessage({ defaultMessage: "Download <em>tar.gz</em>" }),
  ZIP: defineMessage({ defaultMessage: "Download <em>zip</em>" }),
};
