import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionTopicCreatePageQuery as CompetitionTopicCreatePageQueryType } from "./__generated__/CompetitionTopicCreatePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionTopicCreatePageQuery as CompetitionTopicCreatePageQueryType } from "./__generated__/CompetitionTopicCreatePageQuery.graphql";

export const CompetitionTopicCreatePageQuery = graphql`
  query CompetitionTopicCreatePageQuery($slug: String!) {
    competitionBySlug(slug: $slug) {
      id
      slug
    }
  }
`;

export const competitionTopicCreatePageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionTopicCreatePageQueryType>(
      environment,
      CompetitionTopicCreatePageQuery,
      { slug },
    );
  };
};

export const useCompetitionTopicCreatePageQuery = () =>
  usePreloaded<CompetitionTopicCreatePageQueryType>();
