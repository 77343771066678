/**
 * @generated SignedSource<<798d12ebc789d5460efa157b2b281ed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InitUploadFileInput = {
  contentLength: number;
  contentType: string;
  filename: string;
};
export type TextAreaDragNDropUploadZoneInitMutation$variables = {
  input: InitUploadFileInput;
};
export type TextAreaDragNDropUploadZoneInitMutation$data = {
  readonly initUploadFile: {
    readonly key: string;
    readonly uploadUrl: any;
  };
};
export type TextAreaDragNDropUploadZoneInitMutation = {
  response: TextAreaDragNDropUploadZoneInitMutation$data;
  variables: TextAreaDragNDropUploadZoneInitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "InitUploadFile",
    "kind": "LinkedField",
    "name": "initUploadFile",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TextAreaDragNDropUploadZoneInitMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TextAreaDragNDropUploadZoneInitMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6bff847988bc02d5a83332836ff5aed4",
    "id": null,
    "metadata": {},
    "name": "TextAreaDragNDropUploadZoneInitMutation",
    "operationKind": "mutation",
    "text": "mutation TextAreaDragNDropUploadZoneInitMutation(\n  $input: InitUploadFileInput!\n) {\n  initUploadFile(input: $input) {\n    key\n    uploadUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "d07296e332142bfa413af2d648ef3426";

export default node;
