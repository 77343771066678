import React from "react";
import ErrorPage from "../pages/ErrorPage";
import * as Sentry from "@sentry/react";

export default function ErrorBoundary({ children }: React.PropsWithChildren) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <ErrorPage message={error?.toString()} />;
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
