/**
 * @generated SignedSource<<4a005e7b07a43a38bce4f9d7a446b83b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentEditFormQuery$variables = Record<PropertyKey, never>;
export type CommentEditFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MemberSelectAllEntitiesAutoCompleteFragment">;
};
export type CommentEditFormQuery = {
  response: CommentEditFormQuery$data;
  variables: CommentEditFormQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentEditFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MemberSelectAllEntitiesAutoCompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommentEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 10
          }
        ],
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEntity"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10)"
      }
    ]
  },
  "params": {
    "cacheID": "65984416e6be428bfcdacf3403c48137",
    "id": null,
    "metadata": {},
    "name": "CommentEditFormQuery",
    "operationKind": "query",
    "text": "query CommentEditFormQuery {\n  ...MemberSelectAllEntitiesAutoCompleteFragment\n}\n\nfragment MemberSelectAllEntitiesAutoCompleteFragment on Query {\n  entities(first: 10) {\n    ...MemberSelectAllEntitiesFragment\n  }\n}\n\nfragment MemberSelectAllEntitiesFragment on EntityConnection {\n  edges {\n    node {\n      __typename\n      id\n      ...MemberSelectEntityFragment\n    }\n  }\n}\n\nfragment MemberSelectEntityFragment on Entity {\n  __isEntity: __typename\n  id\n  username\n  displayName\n}\n"
  }
};

(node as any).hash = "813ee7b1e6ae7b57d2a0a0c1d5db0ef6";

export default node;
