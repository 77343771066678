/**
 * @generated SignedSource<<1c306747184cde6afbdc56c049b6acdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileViewerFragment$data = {
  readonly contentLength: number;
  readonly contentType: string;
  readonly downloadUrl: any;
  readonly name: string;
  readonly " $fragmentType": "FileViewerFragment";
};
export type FileViewerFragment$key = {
  readonly " $data"?: FileViewerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileViewerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FileViewerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentLength",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    }
  ],
  "type": "FileBrowserFileEntry",
  "abstractKey": null
};

(node as any).hash = "e5273ff49131fd306dca706f801694df";

export default node;
