/**
 * @generated SignedSource<<c4aeb884c59f97ca9aa7235e6eaaa870>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
import { FragmentRefs } from "relay-runtime";
export type ProjectDownloadButtonFragment$data = {
  readonly id: string;
  readonly kind: ProjectVersionFileKind;
  readonly projectVersion: {
    readonly project: {
      readonly competition: {
        readonly slug: string;
      };
    };
  };
  readonly " $fragmentType": "ProjectDownloadButtonFragment";
};
export type ProjectDownloadButtonFragment$key = {
  readonly " $data"?: ProjectDownloadButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectDownloadButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectDownloadButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectVersion",
      "kind": "LinkedField",
      "name": "projectVersion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Competition",
              "kind": "LinkedField",
              "name": "competition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectVersionFile",
  "abstractKey": null
};

(node as any).hash = "ef9143dc64b5b1da6e731f0465aa1a8e";

export default node;
