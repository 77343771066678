import { graphql, Environment } from "react-relay";
import { ScaffoldQuery as ScaffoldQueryType } from "./__generated__/ScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { ScaffoldQuery as ScaffoldQueryType } from "./__generated__/ScaffoldQuery.graphql";

export const ScaffoldQuery = graphql`
  query ScaffoldQuery {
    ...HeaderFragment
  }
`;

export const scaffoldQueryLoader = (environment: Environment) => {
  return () => preload<ScaffoldQueryType>(environment, ScaffoldQuery);
};

export const useScaffoldQuery = () => usePreloaded<ScaffoldQueryType>();
