import { Comparator } from "../../utils/comparator";
import { TimetableEventInit } from "./types";

export function splitDateTime(datetime: string): [string, string] {
  const [date, time] = datetime.split("T", 2);
  return [date, time];
}

export function dateTime(date: string, time: string) {
  return `${date}T${time}`;
}

export const EVENT_COMPARATOR: Comparator<TimetableEventInit> =
  Comparator.andThen(
    Comparator.by((ev) => ev.date, Comparator.string),
    Comparator.by((ev) => ev.time, Comparator.string),
  );
