import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import {
  CompetitionCodePageQuery as CompetitionCodePageQueryType,
  ProjectVersionFileKind,
} from "./__generated__/CompetitionCodePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionCodePageQuery as CompetitionCodePageQueryType } from "./__generated__/CompetitionCodePageQuery.graphql";

export const CompetitionCodePageQuery = graphql`
  query CompetitionCodePageQuery(
    $slug: String!
    $fileKind: ProjectVersionFileKind!
  ) {
    competitionBySlug(slug: $slug) {
      slug
      title
      useCase {
        ...ProjectFileBrowserFragment @arguments(kind: $fileKind)
      }
    }
  }
`;

export const fileKind = (kind: string | undefined) =>
  kind ? (kind.toUpperCase() as ProjectVersionFileKind) : "TEMPLATE";

export const competitionCodePageQueryLoader = (environment: Environment) => {
  return ({ params: { slug, kind } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionCodePageQueryType>(
      environment,
      CompetitionCodePageQuery,
      {
        slug,
        fileKind: fileKind(kind),
      },
    );
  };
};

export const useCompetitionCodePageQuery = () =>
  usePreloaded<CompetitionCodePageQueryType>();
