import { graphql, useMutation, useFragment } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { UserEditFormFragment$key } from "./__generated__/UserEditFormFragment.graphql";
import {
  UserEditFormMutation$variables,
  UserEditFormMutation as UserEditFormMutationType,
} from "./__generated__/UserEditFormMutation.graphql";
import { UserEditFormDeleteMutation as UserEditFormDeleteMutationType } from "./__generated__/UserEditFormDeleteMutation.graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import EntityEditForm, {
  FormData as EntityFormData,
  SubmitData,
} from "../components/EntityEditForm";
import EntityDeleteForm from "./EntityDeleteForm";
import { MAX_VARCHAR_LENGTH, MIN_PASSWORD_LENGTH } from "../utils/validation";
import { logger } from "../common/logger";

const UserEditFormFragment = graphql`
  fragment UserEditFormFragment on User {
    ...EntityProfilePicFragment @arguments(thumbnail: false)
    id
    displayName
    username
    linkedin
    github
    location
    website
    bio
    image
    email
    organization
    jobTitle
    viewerCanDelete: viewerCan(action: DELETE_USER)
  }
`;

const UserEditFormMutation = graphql`
  mutation UserEditFormMutation($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      node {
        id
        displayName
        username
        email
        linkedin
        github
        organization
        location
        jobTitle
        website
        bio
        image
        imageThumbnail
        createdAt
      }
    }
  }
`;

const UserEditFormDeleteMutation = graphql`
  mutation UserEditFormDeleteMutation($id: ID!) {
    deleteOrganization(id: $id)
  }
`;

interface FormData extends EntityFormData {
  email: string;
  jobTitle: string;
  organization: string;
  password: string;
  oldPassword: string;
}

interface Props {
  user: UserEditFormFragment$key;
}

export default function UserEditForm({ user: userFragment }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useFragment(UserEditFormFragment, userFragment);
  const form = useForm<FormData>();
  const {
    register,
    formState: { errors, dirtyFields },
  } = form;

  const [commitEditMutation, isEditMutationInFlight] =
    useMutation<UserEditFormMutationType>(UserEditFormMutation);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<UserEditFormDeleteMutationType>(UserEditFormDeleteMutation);

  const onSubmit = ({ data, image, setFormError }: SubmitData<FormData>) => {
    setFormError(undefined);
    const variables: UserEditFormMutation$variables = {
      id: user.id,
      input: {},
    };
    for (const dirtyField in dirtyFields) {
      const field: keyof FormData = dirtyField as keyof FormData;
      if (dirtyFields[field]) {
        if (!data[field]) {
          variables.input[field] = null;
        } else {
          variables.input[field] = data[field];
        }
      }
    }
    variables.input.image = image.variable;
    const uploadables: UploadableMap = {};
    if (image.uploadable) {
      uploadables["variables.input.image"] = image.uploadable;
    }
    commitEditMutation({
      variables,
      uploadables,
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not update account. Username or email may already exist. Check and try again.",
          }),
        );
      },
      onCompleted: (results) => {
        navigate(`/${results.updateUser.node.username}`);
      },
    });
  };

  const onDeleteSubmit = (setFormError: (error: string) => void) => {
    commitDeleteMutation({
      variables: { id: user.id },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not delete organization. Check and try again.",
          }),
        );
      },
      onCompleted: () => {
        navigate("/");
      },
    });
  };

  const errorMessages = {
    email: {
      required: intl.formatMessage({ defaultMessage: "Email is required" }),
      maxLength: intl.formatMessage({
        defaultMessage: "Email must be at most 255 characters",
      }),
    },
    password: {
      minLength: intl.formatMessage({
        defaultMessage: "Password must be at least 6 characters",
      }),
    },
    oldPassword: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
    },
  };

  return (
    <div className="pb-10">
      <EntityEditForm
        form={form}
        onSubmit={onSubmit}
        isDisabled={isEditMutationInFlight}
        defaultValues={user}
      >
        <FormGroup label={intl.formatMessage({ defaultMessage: "Job Title" })}>
          <TextInput
            defaultValue={user.jobTitle || undefined}
            {...register("jobTitle", {
              setValueAs: (value) => value.trim(),
            })}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Organization" })}
        >
          <TextInput
            defaultValue={user.organization || undefined}
            {...register("organization", {
              setValueAs: (value) => value.trim(),
            })}
          />
        </FormGroup>
        <div className="py-5">
          <hr />
        </div>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Email" })}
          error={
            typeof errors.email?.type === "string" &&
            errorMessages.email[
              errors.email.type as keyof typeof errorMessages.email
            ]
          }
        >
          <TextInput
            type="email"
            defaultValue={user.email}
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", {
              required: true,
              maxLength: MAX_VARCHAR_LENGTH,
              setValueAs: (value) => value.trim(),
            })}
          />
        </FormGroup>
        <div className="py-5">
          <hr />
        </div>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Old Password" })}
          error={
            typeof errors.oldPassword?.type === "string" &&
            errorMessages.oldPassword[
              errors.oldPassword.type as keyof typeof errorMessages.oldPassword
            ]
          }
        >
          <TextInput
            type="password"
            aria-invalid={errors.oldPassword ? "true" : "false"}
            {...register("oldPassword", {
              minLength: MIN_PASSWORD_LENGTH,
              validate: {
                requiredIfPassword: (value, values) =>
                  values.password.length > 0 ? value.length > 0 : true,
              },
            })}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "New Password" })}
          error={
            typeof errors.password?.type === "string" &&
            errorMessages.password[
              errors.password.type as keyof typeof errorMessages.password
            ]
          }
        >
          <TextInput
            type="password"
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", {
              minLength: MIN_PASSWORD_LENGTH,
            })}
          />
        </FormGroup>
      </EntityEditForm>
      {user.viewerCanDelete && (
        <>
          <div className="py-5">
            <hr />
          </div>
          <EntityDeleteForm
            disabled={isDeleteMutationInFlight}
            username={user.username}
            onSubmit={onDeleteSubmit}
          />
        </>
      )}
    </div>
  );
}
