import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { CompetitionTagFragment$key } from "./__generated__/CompetitionTagFragment.graphql";

const CompetitionTagFragment = graphql`
  fragment CompetitionTagFragment on Tag {
    name
  }
`;

interface TagProps {
  tag: CompetitionTagFragment$key;
}

export default function Tag({ tag: tagFragment }: TagProps) {
  const { name } = useFragment(CompetitionTagFragment, tagFragment);
  return (
    <div className="my-2 bg-indigo/20 rounded w-max">
      <p className="font-semibold text-sm font-mono p-1 px-2">{name}</p>
    </div>
  );
}
