/**
 * @generated SignedSource<<529cd98ccd720630e24c317f5b54c2cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AuthScaffoldQuery$variables = Record<PropertyKey, never>;
export type AuthScaffoldQuery$data = {
  readonly viewer: {
    readonly id: string;
  };
};
export type AuthScaffoldQuery = {
  response: AuthScaffoldQuery$data;
  variables: AuthScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthScaffoldQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthScaffoldQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d8969f0a6f96f72e9d3f6e0635778bff",
    "id": null,
    "metadata": {},
    "name": "AuthScaffoldQuery",
    "operationKind": "query",
    "text": "query AuthScaffoldQuery {\n  viewer @ifAllowed {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1b40ca881865d1570cbf9a7fba262d5";

export default node;
