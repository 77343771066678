import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cn } from "../utils/tailwind";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

const Root = TabsPrimitive.Root;

const List = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center bg-transparent text-m",
      className,
    )}
    {...props}
  />
));

const Trigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      " inline-flex items-center justify-center whitespace-nowrap rounded-t-md px-4 py-2 text-sm font-medium border-gray-300  ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-midnight focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-midnight data-[state=active]:border data-[state=active]:border-b-0 ",
      className,
    )}
    {...props}
  />
));

const Content = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "-mt-0.5 p-2 border border-gray-300  ring-offset-gray-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-midnight focus-visible:ring-offset-2",
      className,
    )}
    {...props}
  />
));

export { Root, List, Trigger, Content };
