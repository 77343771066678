import { graphql, useMutation, useFragment } from "react-relay";
import { ApproveProjectVersionButtonCreateMutation as ApproveProjectVersionButtonCreateMutationType } from "./__generated__/ApproveProjectVersionButtonCreateMutation.graphql";
import { ApproveProjectVersionButtonDeleteMutation as ApproveProjectVersionButtonDeleteMutationType } from "./__generated__/ApproveProjectVersionButtonDeleteMutation.graphql";
import { ApproveProjectVersionButtonFragment$key } from "./__generated__/ApproveProjectVersionButtonFragment.graphql";
import Button from "./Button";

const AprroveProjectVersionButtonFragment = graphql`
  fragment ApproveProjectVersionButtonFragment on ProjectVersion {
    id
    approval {
      id
    }
    ...SubmissionStatusBadgeFragment
  }
`;

const ApproveProjectVersionButtonCreateMutation = graphql`
  mutation ApproveProjectVersionButtonCreateMutation($projectVersionId: ID!) {
    createProjectVersionApproval(projectVersionId: $projectVersionId) {
      node {
        id
        projectVersion {
          ...ApproveProjectVersionButtonFragment
        }
      }
    }
  }
`;

const ApproveProjectVersionButtonDeleteMutation = graphql`
  mutation ApproveProjectVersionButtonDeleteMutation(
    $projectVersionApprovalId: ID!
  ) {
    deleteProjectVersionApproval(
      projectVersionApprovalId: $projectVersionApprovalId
    ) @deleteRecord
  }
`;

interface Props {
  projectVersion: ApproveProjectVersionButtonFragment$key;
}

export default function ApproveProjectVersionButton({
  projectVersion: projectVersionFragment,
}: Props) {
  const projectVersion = useFragment(
    AprroveProjectVersionButtonFragment,
    projectVersionFragment,
  );
  const [commitCreateMutation, isCreateMutationInFlight] =
    useMutation<ApproveProjectVersionButtonCreateMutationType>(
      ApproveProjectVersionButtonCreateMutation,
    );
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<ApproveProjectVersionButtonDeleteMutationType>(
      ApproveProjectVersionButtonDeleteMutation,
    );

  if (projectVersion.approval) {
    return (
      <Button
        kind="success"
        disabled={isDeleteMutationInFlight}
        onClick={() =>
          commitDeleteMutation({
            variables: {
              projectVersionApprovalId: projectVersion.approval!.id,
            },
          })
        }
      >
        Approved
      </Button>
    );
  }
  return (
    <Button
      kind="secondary"
      disabled={isCreateMutationInFlight}
      onClick={() =>
        commitCreateMutation({
          variables: {
            projectVersionId: projectVersion.id,
          },
        })
      }
    >
      Approve
    </Button>
  );
}
