/**
 * @generated SignedSource<<f5767df707ba47b8424851e99ca611c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserEditFormFragment$data = {
  readonly bio: string | null | undefined;
  readonly displayName: string;
  readonly email: string;
  readonly github: string | null | undefined;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly jobTitle: string | null | undefined;
  readonly linkedin: string | null | undefined;
  readonly location: string | null | undefined;
  readonly organization: string | null | undefined;
  readonly username: string;
  readonly viewerCanDelete: boolean;
  readonly website: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  readonly " $fragmentType": "UserEditFormFragment";
};
export type UserEditFormFragment$key = {
  readonly " $data"?: UserEditFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserEditFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserEditFormFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkedin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "github",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": "viewerCanDelete",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "DELETE_USER"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"DELETE_USER\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ad7e3d7d4924ef237190025026ccb4ed";

export default node;
