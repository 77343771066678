import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/auth";

export default function SignupCallout() {
  const { userId } = useAuth();
  if (userId) {
    return null;
  }
  return (
    <div className="flex justify-center">
      <div className="bg-slate-50 px-4 py-2 text-lg rounded border">
        <FormattedMessage
          defaultMessage="<x>Sign up for free</x> to join the discussion, or <y>log in</y> if you already have an account."
          values={{
            x: (chunks) => (
              <Link to="/signup" className="text-blue-600 underline">
                {chunks}
              </Link>
            ),
            y: (chunks) => (
              <Link to="/login" className="text-blue-600 underline">
                {chunks}
              </Link>
            ),
          }}
        />
      </div>
    </div>
  );
}
