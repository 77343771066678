import { FieldValues } from "react-hook-form";
import * as Radix from "@radix-ui/react-switch";
import { useController, UseControllerProps } from "react-hook-form";

interface Props<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {}

export default function Switch<TFieldValues extends FieldValues>({
  name,
  control,
  defaultValue,
}: Props<TFieldValues>) {
  const { field } = useController({ name, control, defaultValue });
  return (
    <Radix.Root
      className="w-12 h-7 bg-grey rounded-full relative data-[state=checked]:bg-indigo outline-none cursor-default shadow-inner hover:cursor-pointer"
      checked={field.value}
      onCheckedChange={(checked) => field.onChange(checked)}
      onBlur={field.onBlur}
      disabled={field.disabled}
      ref={field.ref}
    >
      <Radix.Thumb className="block w-7 h-7 shadow-md bg-white rounded-full transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-6" />
    </Radix.Root>
  );
}
