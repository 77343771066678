import { FormattedMessage, useIntl } from "react-intl";
import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "./ConfirmDialog";
import { ForumDeleteButtonMutation as ForumDeleteButtonMutationType } from "./__generated__/ForumDeleteButtonMutation.graphql";
import { useForm } from "react-hook-form";
import TextInput from "./TextInput";

const ForumDeleteButtonMutation = graphql`
  mutation ForumDeleteButtonMutation($id: ID!, $connections: [ID!]!) {
    deleteForum(id: $id) @deleteEdge(connections: $connections)
  }
`;

type FormData = {
  title: string;
};

interface Props {
  id: string;
  title: string;
}

export default function ForumDeleteButton({ id, title }: Readonly<Props>) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    getFieldState,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  const errorMessages = {
    required: intl.formatMessage({
      defaultMessage: "Forum title is required",
    }),
    matches: intl.formatMessage(
      { defaultMessage: "Forum title must match {title}" },
      { title },
    ),
  };

  const forumTitleError =
    typeof errors.title?.type === "string" &&
    errorMessages[errors.title.type as keyof typeof errorMessages];

  const navigate = useNavigate();
  const [commitMutation, isMutationInFlight] =
    useMutation<ForumDeleteButtonMutationType>(ForumDeleteButtonMutation);

  const onDelete = handleSubmit(() => {
    if (isMutationInFlight) {
      return;
    }
    commitMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID("root", "ForumsFragment_forums"),
        ],
      },
      onCompleted: () => {
        reset();
        navigate("/discussions");
      },
    });
  });

  return (
    <ConfirmDialog
      onConfirm={(event) => {
        event.stopPropagation();
        onDelete();
      }}
      kind="danger"
      title={<FormattedMessage defaultMessage="Delete the forum" />}
      buttonMessage={<FormattedMessage defaultMessage="Delete" />}
      disabled={
        isMutationInFlight || getFieldState("title").error ? true : false
      }
      message={
        <FormattedMessage
          defaultMessage="By clicking on the button below, this will permanently delete {forumTitle} forum."
          values={{ forumTitle: <strong>{title}</strong> }}
        />
      }
    >
      {forumTitleError && (
        <p className="pt-1 text-sm text-red-500">{forumTitleError}</p>
      )}

      <TextInput
        aria-invalid={errors.title ? "true" : "false"}
        placeholder={title}
        {...register("title", {
          setValueAs: (value) => value.trim(),
          required: true,
          validate: {
            matches: (value) => value === title,
          },
        })}
      />
    </ConfirmDialog>
  );
}
