/**
 * @generated SignedSource<<6b0a3902f258bb6c45ff4abb435841f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicDeleteButtonFragment$data = {
  readonly __id: string;
  readonly competition: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "TopicDeleteButtonFragment";
};
export type TopicDeleteButtonFragment$key = {
  readonly " $data"?: TopicDeleteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicDeleteButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicDeleteButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Competition",
      "kind": "LinkedField",
      "name": "competition",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "93bff061b2832340e3da3dafaf70b220";

export default node;
