/**
 * @generated SignedSource<<c8e061c8747a9f64bf4aa06fd3b8b6e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingsNotificationsPageUnsubscribeFromAllMutation$variables = Record<PropertyKey, never>;
export type UserSettingsNotificationsPageUnsubscribeFromAllMutation$data = {
  readonly unsubscribeFromAllNotifications: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserSettingsNotificationsPageFragment">;
    };
  };
};
export type UserSettingsNotificationsPageUnsubscribeFromAllMutation = {
  response: UserSettingsNotificationsPageUnsubscribeFromAllMutation$data;
  variables: UserSettingsNotificationsPageUnsubscribeFromAllMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsNotificationsPageUnsubscribeFromAllMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "unsubscribeFromAllNotifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSettingsNotificationsPageFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsNotificationsPageUnsubscribeFromAllMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserEdge",
        "kind": "LinkedField",
        "name": "unsubscribeFromAllNotifications",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserNotifications",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ef2bbc2a3f033696f4cf756e9f91ccd",
    "id": null,
    "metadata": {},
    "name": "UserSettingsNotificationsPageUnsubscribeFromAllMutation",
    "operationKind": "mutation",
    "text": "mutation UserSettingsNotificationsPageUnsubscribeFromAllMutation {\n  unsubscribeFromAllNotifications {\n    node {\n      id\n      ...UserSettingsNotificationsPageFragment\n    }\n  }\n}\n\nfragment UserSettingsNotificationsPageFragment on User {\n  id\n  notifications {\n    enabled\n    disabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbee3dfe37e3aff985999988c2efc56f";

export default node;
