import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionOverviewPageQuery as CompetitionOverviewPageQueryType } from "./__generated__/CompetitionOverviewPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionOverviewPageQuery as CompetitionOverviewPageQueryType } from "./__generated__/CompetitionOverviewPageQuery.graphql";

export const CompetitionOverviewPageQuery = graphql`
  query CompetitionOverviewPageQuery($slug: String!) {
    competitionBySlug(slug: $slug) {
      id
      description
      viewerCanUpdate: viewerCan(action: UPDATE_COMPETITION)
      ...MemberSelectCompetitionFragment
    }
  }
`;

export const competitionOverviewPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionOverviewPageQueryType>(
      environment,
      CompetitionOverviewPageQuery,
      { slug },
    );
  };
};

export const useCompetitionOverviewPageQuery = () =>
  usePreloaded<CompetitionOverviewPageQueryType>();
