import { graphql, usePaginationFragment, RefetchFnDynamic } from "react-relay";
import { OperationType } from "relay-runtime";
import { CompetitionLeaderboardFragment$key } from "./__generated__/CompetitionLeaderboardFragment.graphql";
import { FormattedMessage, FormattedDate, FormattedNumber } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import { Link } from "react-router-dom";
import LoadMore from "./LoadMore";

const CompetitionLeaderboardFragment = graphql`
  fragment CompetitionLeaderboardFragment on Competition
  @refetchable(queryName: "CompetitionLeaderboardFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    leaderboard(after: $cursor, first: $count)
      @connection(key: "CompetitionLeaderboardFragment_leaderboard") {
      edges {
        node {
          id
          score
          createdAt
          submission {
            entity {
              id
              username
              ...EntityProfilePicFragment @arguments(thumbnail: true)
            }
          }
        }
      }
    }
  }
`;

export type RefetchFn = RefetchFnDynamic<
  OperationType,
  CompetitionLeaderboardFragment$key
>;

interface Props {
  competition: CompetitionLeaderboardFragment$key;
  registerRefresh: (refetchFn: RefetchFn) => void;
}

export default function CompetitionLeaderboard({
  competition,
  registerRefresh,
}: Props) {
  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment(CompetitionLeaderboardFragment, competition);
  registerRefresh(refetch);
  if (data.leaderboard.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Submissions" />
      </p>
    );
  }
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Submitted At" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Score" />
            </th>
          </tr>
        </thead>
        <tbody>
          {data.leaderboard.edges.map(({ node }) => {
            const entity = node.submission.entity;
            if (!entity || !node.score) {
              return null;
            }
            return (
              <tr key={node.id}>
                <td className="whitespace-nowrap py-2 px-3">
                  <Link to={`/${entity.username}`}>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                      <div className="pr-4">
                        <EntityProfilePic entity={entity} size="12" />
                      </div>
                      <span>{entity.username}</span>
                    </div>
                  </Link>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  <FormattedDate
                    value={node.createdAt}
                    month="long"
                    day="numeric"
                    year="numeric"
                  />
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  <FormattedNumber value={node.score} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
