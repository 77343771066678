/**
 * @generated SignedSource<<ca8b082ad3b453cd318b3506b828a27e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionEntitySubmissionStatusCompetitionFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly submission: {
    readonly id: string;
    readonly latest: {
      readonly id: string;
      readonly version: string;
      readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
    } | null | undefined;
    readonly maxEvaluation: {
      readonly projectVersion: {
        readonly id: string;
        readonly version: string;
        readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "CompetitionEntitySubmissionStatusCompetitionFragment";
};
export type CompetitionEntitySubmissionStatusCompetitionFragment$key = {
  readonly " $data"?: CompetitionEntitySubmissionStatusCompetitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionEntitySubmissionStatusCompetitionFragment">;
};

import CompetitionEntitySubmissionStatusCompetitionQuery_graphql from './CompetitionEntitySubmissionStatusCompetitionQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "version",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SubmissionStatusBadgeFragment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": CompetitionEntitySubmissionStatusCompetitionQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CompetitionEntitySubmissionStatusCompetitionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Submission",
      "kind": "LinkedField",
      "name": "submission",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectVersion",
          "kind": "LinkedField",
          "name": "latest",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectVersionEvaluation",
          "kind": "LinkedField",
          "name": "maxEvaluation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectVersion",
              "kind": "LinkedField",
              "name": "projectVersion",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};
})();

(node as any).hash = "c4c4d5d8e9dd08d210b673d67efca12a";

export default node;
