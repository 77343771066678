import { MdDelete } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";
import { ConfirmDialog } from "./ConfirmDialog";
import { CommentDeleteFragment$key } from "./__generated__/CommentDeleteFragment.graphql";
import { CommentDeleteMutation as CommentDeleteMutationType } from "./__generated__/CommentDeleteMutation.graphql";

const CommentDeleteFragment = graphql`
  fragment CommentDeleteFragment on Comment {
    id
    author {
      id
    }
    topic {
      id
    }
    parent {
      id
    }
  }
`;

const CommentDeleteMutation = graphql`
  mutation CommentDeleteMutation($id: ID!, $connections: [ID!]!) {
    deleteComment(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface DeleteProps {
  comment: CommentDeleteFragment$key;
}

export default function DeleteComment({
  comment: commentFragment,
}: DeleteProps) {
  const comment = useFragment(CommentDeleteFragment, commentFragment);
  const [commitMutation, _isMutationInFlight] =
    useMutation<CommentDeleteMutationType>(CommentDeleteMutation);
  const deleteComment = () => {
    commitMutation({
      variables: {
        id: comment.id,
        connections: [
          ConnectionHandler.getConnectionID(
            comment.author.id,
            "UserCommentsPageFragment_comments",
          ),
        ],
      },
    });
  };

  return (
    <ConfirmDialog
      onConfirm={deleteComment}
      TriggerIcon={MdDelete}
      kind="danger"
      title={<FormattedMessage defaultMessage="Delete the comment" />}
      buttonMessage={<FormattedMessage defaultMessage="Delete" />}
      message={
        <FormattedMessage defaultMessage="By clicking on the button below, this will permanently delete the comment." />
      }
    />
  );
}
