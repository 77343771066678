import { IntlShape } from "react-intl";
import { createSearchParams } from "react-router-dom";

export type VotableOrder = "HOT" | "NEWEST" | "OLDEST";

export const DEFAULT_VOTABLE_ORDER: VotableOrder = "HOT";

export const VOTABLE_ORDERS: ReadonlyArray<VotableOrder> = [
  "HOT",
  "NEWEST",
  "OLDEST",
];

export const parseVotableOrder = (order: string): VotableOrder | undefined => {
  const orderUppercase = order.toUpperCase() as VotableOrder;
  if (VOTABLE_ORDERS.includes(orderUppercase)) {
    return orderUppercase;
  }
  return undefined;
};

export const getVotableOrderParam = (
  request: Request,
): VotableOrder | undefined => {
  const orderParam = createSearchParams(new URL(request.url).search).get(
    "order",
  );
  return (orderParam && parseVotableOrder(orderParam)) || undefined;
};

export const formatVotableOrder = (intl: IntlShape, order: VotableOrder) => {
  switch (order) {
    case "HOT":
      return intl.formatMessage({ defaultMessage: "Hot" });
    case "NEWEST":
      return intl.formatMessage({ defaultMessage: "Newest" });
    case "OLDEST":
      return intl.formatMessage({ defaultMessage: "Oldest" });
  }
};
