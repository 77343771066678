import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { OrganizationUsersFragment$key } from "./__generated__/OrganizationUsersFragment.graphql";
import { OrganizationUsersDeleteMutation as OrganizationUsersDeleteMutationType } from "./__generated__/OrganizationUsersDeleteMutation.graphql";
import { OrganizationUsersPageTransferOwnershipMutation as OrganizationUsersPageTransferOwnershipMutationType } from "./__generated__/OrganizationUsersPageTransferOwnershipMutation.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import { formatOrganizationMembershipKind } from "../utils/format";
import { useAuth } from "../utils/auth";
import LoadMore from "./LoadMore";
import { Link } from "react-router-dom";
import { ToolbarButton } from "./Button";
import { logger } from "../common/logger";

const OrganizationUsersFragment = graphql`
  fragment OrganizationUsersFragment on Organization
  @refetchable(queryName: "OrganizationUsersFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)
    viewerCanTransferOwnership: viewerCan(
      action: TRANSFER_ORGANIZATION_OWNERSHIP
    )
    users(first: $count, after: $cursor)
      @connection(key: "OrganizationUsersFragment_users") {
      edges {
        node {
          id
          kind
          viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)
          user {
            id
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
      }
    }
  }
`;

const OrganizationUsersPageTransferOwnershipMutation = graphql`
  mutation OrganizationUsersPageTransferOwnershipMutation(
    $organizationId: ID!
    $toUserId: ID!
    $connections: [ID!]!
  ) {
    transferOrganizationOwnership(
      organizationId: $organizationId
      toUserId: $toUserId
    ) @prependEdge(connections: $connections) {
      node {
        id
        kind
        organization {
          id
          username
          displayName
          viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
        user {
          id
          username
          displayName
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
      }
    }
  }
`;

const OrganizationUsersDeleteMutation = graphql`
  mutation OrganizationUsersDeleteMutation($id: ID!, $connections: [ID!]!) {
    removeOrganizationMember(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  user: OrganizationUsersFragment$key;
}

export default function OrganizationUsers({ user: userFragment }: Props) {
  const { userId: viewerId } = useAuth();
  const intl = useIntl();
  const {
    data: organization,
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(OrganizationUsersFragment, userFragment);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<OrganizationUsersDeleteMutationType>(
      OrganizationUsersDeleteMutation,
    );
  const [commitTransferOwnershipMutation, isTransferOwnershipMutationInFlight] =
    useMutation<OrganizationUsersPageTransferOwnershipMutationType>(
      OrganizationUsersPageTransferOwnershipMutation,
    );
  if (organization.users.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Users" />
      </p>
    );
  }
  const onDelete = (id: string, userId: string) => {
    commitDeleteMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            organization.id,
            "OrganizationUsersFragment_users",
          ),
          ConnectionHandler.getConnectionID(
            userId,
            "UserOrganizationsFragment_organizations",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
      onCompleted: () => {
        if (userId === viewerId) {
          refetch({});
        }
      },
    });
  };
  const onTransferOwnershipSubmit = (userId: string) => {
    commitTransferOwnershipMutation({
      variables: {
        organizationId: organization.id,
        toUserId: userId,
        connections: [
          ConnectionHandler.getConnectionID(
            organization.id,
            "OrganizationUsersFragment_users",
          ),
          ConnectionHandler.getConnectionID(
            userId,
            "UserOrganizationsFragment_organizations",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
    });
  };
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Membership" />
            </th>
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          {organization.users.edges.map(({ node: membership }) => (
            <tr key={membership.id}>
              <td className="whitespace-nowrap py-2 px-3">
                <Link to={`/${membership.user.username}`}>
                  <div className="flex flex-row items-center hover:cursor-pointer">
                    <div className="pr-4">
                      <EntityProfilePic entity={membership.user} size="12" />
                    </div>
                    <span>{membership.user.displayName}</span>
                  </div>
                </Link>
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {formatOrganizationMembershipKind(intl, membership.kind)}
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                <Dropdown
                  trigger={
                    <ToolbarButton size="sm">
                      <MdMoreHoriz />
                    </ToolbarButton>
                  }
                >
                  {membership.kind !== "OWNER" && (
                    <>
                      {membership.viewerCanDelete && (
                        <Dropdown.Item
                          disabled={isDeleteMutationInFlight}
                          onClick={() => {
                            onDelete(membership.id, membership.user.id);
                          }}
                        >
                          <FormattedMessage defaultMessage="Remove user" />
                        </Dropdown.Item>
                      )}
                      {organization.viewerCanTransferOwnership && (
                        <Dropdown.Item
                          disabled={isTransferOwnershipMutationInFlight}
                          onClick={() => {
                            onTransferOwnershipSubmit(membership.user.id);
                          }}
                        >
                          <FormattedMessage defaultMessage="Transfer Ownership" />
                        </Dropdown.Item>
                      )}
                    </>
                  )}
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
