// Origin: https://github.com/component/textarea-caret-position

const properties = [
  "direction",
  "boxSizing",
  "width",
  "height",
  "overflowX",
  "overflowY",
  "borderTopWidth",
  "borderRightWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderStyle",
  "paddingTop",
  "paddingRight",
  "paddingBottom",
  "paddingLeft",
  "fontStyle",
  "fontVariant",
  "fontWeight",
  "fontStretch",
  "fontSize",
  "fontSizeAdjust",
  "lineHeight",
  "fontFamily",
  "textAlign",
  "textTransform",
  "textIndent",
  "textDecoration",
  "letterSpacing",
  "wordSpacing",
  "tabSize",
  "MozTabSize",
] as const;

const WORD_REGEX = /[\w@#]+/g;
const WORD_START_REGEX = /\S*$/;
const WORD_END_REGEX = /^\S*/;
const SPACE_REGEX = /\s/g;

const isBrowser = typeof window !== "undefined";
const isFirefox = isBrowser && "mozInnerScreenX" in window;

interface CaretIndex {
  start: number;
  end: number;
}

function getCaretPosition(element: HTMLTextAreaElement): CaretIndex {
  return {
    start: element.selectionStart ?? 0,
    end: element.selectionEnd ?? 0,
  };
}

export function getCurrentWord(element: HTMLTextAreaElement): string {
  const text = element.value;
  const { start: caretStartIndex } = getCaretPosition(element);

  const findWordStart = (text: string, index: number) => {
    const beforeCaret = text.slice(0, index);
    const match = beforeCaret.match(WORD_START_REGEX);
    return match ? index - match[0].length : index;
  };

  const findWordEnd = (text: string, index: number) => {
    const afterCaret = text.slice(index);
    const match = afterCaret.match(WORD_END_REGEX);
    return match ? index + match[0].length : index;
  };

  const start = findWordStart(text, caretStartIndex);
  const end = findWordEnd(text, caretStartIndex);
  return text.substring(start, end);
}

export function replaceWord(element: HTMLTextAreaElement, value: string): void {
  const text = element.value;
  const caretPos = element.selectionStart;

  const findWordToReplace = (text: string) => {
    let startIndex: number | undefined;
    let endIndex: number | undefined;

    const matches = Array.from(text.matchAll(WORD_REGEX));
    const matchedWord = matches.find((match) => {
      startIndex = match.index;
      if (startIndex === undefined) return false;
      endIndex = startIndex + match[0].length;
      return caretPos >= startIndex && caretPos <= endIndex;
    });

    return matchedWord ? { startIndex, endIndex } : null;
  };

  const wordIndices = findWordToReplace(text);

  if (
    wordIndices &&
    wordIndices.startIndex !== undefined &&
    wordIndices.endIndex !== undefined
  ) {
    const { startIndex, endIndex } = wordIndices;
    const selectionStart = element.selectionStart;
    const selectionEnd = element.selectionEnd;

    const newText =
      text.slice(0, startIndex) + value.split(" ")[0] + text.slice(endIndex);

    element.value = newText;

    const offset = value.length - (endIndex - startIndex);
    element.setSelectionRange(selectionStart + offset, selectionEnd + offset);
  }
}

export interface CaretCoordinates {
  top: number;
  left: number;
  height: number;
}

interface CaretCoordinatesProps {
  element: HTMLTextAreaElement;
  position: number;
  options?: { debug?: boolean } | undefined;
}

function createDebugDiv(): HTMLDivElement {
  const div = document.createElement("div");
  div.id = "input-textarea-caret-position-mirror-div";
  div.style.whiteSpace = "pre-wrap";
  div.style.overflowWrap = "break-word";
  div.style.position = "absolute";
  return div;
}

function applyStyles(
  sourceElement: HTMLElement,
  targetElement: HTMLElement,
): void {
  const computed = window.getComputedStyle(sourceElement);
  properties.forEach((prop) => {
    // @ts-expect-error - Not fully typed
    targetElement.style[prop] = computed[prop];
  });
}

function getElementStyles(element: HTMLElement, property: string): number {
  return parseInt(
    window.getComputedStyle(element).getPropertyValue(property),
    10,
  );
}

export function getCaretCoordinates({
  element,
  position,
  options = {},
}: Readonly<CaretCoordinatesProps>): CaretCoordinates {
  if (!isBrowser) {
    throw new Error(
      "textarea-caret-position#getCaretCoordinates should only be called in a browser",
    );
  }

  const { debug = false } = options;

  if (debug) {
    const existingDebugEl = document.querySelector(
      "#input-textarea-caret-position-mirror-div",
    );
    if (existingDebugEl)
      existingDebugEl.parentNode?.removeChild(existingDebugEl);
  }

  const div = createDebugDiv();
  document.body.appendChild(div);

  if (!debug) {
    div.style.visibility = "hidden";
  }

  applyStyles(element, div);

  if (isFirefox && element.scrollHeight > getElementStyles(element, "height")) {
    div.style.overflowY = "scroll";
  } else {
    div.style.overflow = "hidden";
  }

  div.textContent = element.value.substring(0, position);
  if (element.nodeName === "INPUT") {
    div.textContent = div.textContent.replace(SPACE_REGEX, "\u00a0");
  }

  const span = document.createElement("span");
  span.textContent = element.value.substring(position) || "";
  div.appendChild(span);

  const coordinates: CaretCoordinates = {
    top: span.offsetTop + getElementStyles(element, "border-top-width"),
    left: span.offsetLeft + getElementStyles(element, "border-left-width"),
    height: getElementStyles(element, "line-height"),
  };

  if (!debug) {
    document.body.removeChild(div);
  } else {
    span.style.backgroundColor = "#aaa";
  }

  return coordinates;
}
