import Button, { ButtonProps } from "./Button";
import * as Dialog from "./Dialog";
import { type ReactNode, useCallback } from "react";
import { type IconType } from "react-icons";

interface ConfirmDialogProps extends Dialog.RootCredenzaProps {
  title: ReactNode;
  message: ReactNode;
  buttonMessage: ReactNode;
  onConfirm: (event: React.MouseEvent) => void;
  kind?: ButtonProps["kind"];
  TriggerIcon?: IconType;
  ButtonIcon?: IconType | null;
  onCancel?: () => void;
  disabled?: boolean;
}

const ConfirmDialogContent = ({
  title,
  message,
  buttonMessage,
  onConfirm,
  onCancel,
  kind,
  disabled,
  children,
}: Omit<ConfirmDialogProps, "TriggerIcon" | "ButtonIcon">) => (
  <Dialog.Content>
    <Dialog.Close onClick={onCancel} className="fixed top-3 right-3" />
    <Dialog.Title className="p-4 text-center">{title}</Dialog.Title>
    <div className="flex flex-col w-full px-4 md:py-2 py-4 gap-4">
      <Dialog.Description>{message}</Dialog.Description>
      {children}
      <Button
        kind={kind}
        onClick={onConfirm}
        disabled={disabled}
        className="w-full"
      >
        {buttonMessage}
      </Button>
    </div>
  </Dialog.Content>
);

const ConfirmDialogTrigger = ({
  TriggerIcon,
  ButtonIcon,
  buttonMessage,
  kind,
}: Pick<
  ConfirmDialogProps,
  "TriggerIcon" | "ButtonIcon" | "buttonMessage" | "kind"
>) =>
  TriggerIcon ? (
    <Dialog.Trigger>
      <TriggerIcon className="hover:cursor-pointer" />
    </Dialog.Trigger>
  ) : (
    <Dialog.Trigger asChild>
      <Button kind={kind} className="flex flex-row items-center gap-3">
        {buttonMessage}
        {ButtonIcon && <ButtonIcon />}
      </Button>
    </Dialog.Trigger>
  );

export function ConfirmDialog({
  disabled = false,
  kind = "primary",
  title,
  message,
  buttonMessage,
  onConfirm,
  TriggerIcon,
  ButtonIcon,
  onCancel,
  children,
  ...rest
}: Readonly<ConfirmDialogProps>) {
  const handleConfirm = useCallback(
    (event: React.MouseEvent) => {
      onConfirm(event);
    },
    [onConfirm],
  );

  return (
    <Dialog.Root {...rest}>
      <ConfirmDialogTrigger
        TriggerIcon={TriggerIcon}
        ButtonIcon={ButtonIcon}
        buttonMessage={buttonMessage}
        kind={kind}
      />
      <ConfirmDialogContent
        title={title}
        message={message}
        buttonMessage={buttonMessage}
        onConfirm={handleConfirm}
        onCancel={onCancel}
        kind={kind}
        disabled={disabled}
      >
        {children}
      </ConfirmDialogContent>
    </Dialog.Root>
  );
}
