import Outlet from "../common/Outlet";
import { useScaffoldQuery } from "./loaders/Scaffold";
import Header from "../components/Header";
import { ScrollRestoration } from "react-router-dom";

export default function Scaffold() {
  const { query } = useScaffoldQuery();
  return (
    <div className="container mx-auto max-w-screen-lg space-y-6 h-full flex flex-col px-4">
      <Header query={query} />
      <Outlet />
      <ScrollRestoration />
    </div>
  );
}
