/**
 * @generated SignedSource<<ed016c09d9f0163c0e192e26f23458d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicEditFormQuery$variables = Record<PropertyKey, never>;
export type TopicEditFormQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MemberSelectAllEntitiesAutoCompleteFragment">;
};
export type TopicEditFormQuery = {
  response: TopicEditFormQuery$data;
  variables: TopicEditFormQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicEditFormQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MemberSelectAllEntitiesAutoCompleteFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TopicEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 10
          }
        ],
        "concreteType": "EntityConnection",
        "kind": "LinkedField",
        "name": "entities",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEntity"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "entities(first:10)"
      }
    ]
  },
  "params": {
    "cacheID": "8c0d26876e12538cbd04beec029d0ce6",
    "id": null,
    "metadata": {},
    "name": "TopicEditFormQuery",
    "operationKind": "query",
    "text": "query TopicEditFormQuery {\n  ...MemberSelectAllEntitiesAutoCompleteFragment\n}\n\nfragment MemberSelectAllEntitiesAutoCompleteFragment on Query {\n  entities(first: 10) {\n    ...MemberSelectAllEntitiesFragment\n  }\n}\n\nfragment MemberSelectAllEntitiesFragment on EntityConnection {\n  edges {\n    node {\n      __typename\n      id\n      ...MemberSelectEntityFragment\n    }\n  }\n}\n\nfragment MemberSelectEntityFragment on Entity {\n  __isEntity: __typename\n  id\n  username\n  displayName\n}\n"
  }
};

(node as any).hash = "4e7916581c3f6596af096b3e7ba27299";

export default node;
