/**
 * @generated SignedSource<<57996be9313f5c8a242ab96329b124c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityProfilePicFragment$data = {
  readonly image?: any | null | undefined;
  readonly imageThumbnail?: any | null | undefined;
  readonly " $fragmentType": "EntityProfilePicFragment";
};
export type EntityProfilePicFragment$key = {
  readonly " $data"?: EntityProfilePicFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "thumbnail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityProfilePicFragment",
  "selections": [
    {
      "condition": "thumbnail",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageThumbnail",
          "storageKey": null
        }
      ]
    },
    {
      "condition": "thumbnail",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "image",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};

(node as any).hash = "fbb4d457841ea7a798aa5b15a2089793";

export default node;
