import { parse as csvparse } from "csv-parse/browser/esm/sync";
import { FormattedMessage } from "react-intl";

export interface CsvViewerProps {
  children: string;
}

export default function CsvViewer(props: CsvViewerProps) {
  const rows: string[][] = csvparse(props.children, {
    trim: true,
    relaxColumnCount: true,
    skipEmptyLines: true,
  });

  if (rows.length <= 0) {
    return (
      <p>
        <FormattedMessage defaultMessage="This file is empty" />
      </p>
    );
  }

  return (
    <table className="border">
      <thead>
        <tr className="border bg-slate-50">
          {rows[0].map((column) => (
            <th className="p-1 text-start">{column}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.slice(1).map((row, index) => (
          <tr key={index} className="border hover:bg-gray-50">
            {row.map((column) => (
              <td className="p-1">
                <code>{column}</code>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
