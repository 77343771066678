interface LoadingIconProps {
  outerClassName: string;
  innerClassName: string;
}

function LoadingIcon({ outerClassName, innerClassName }: LoadingIconProps) {
  return (
    <svg className="w-full" viewBox="-3 -3 46 46">
      <path
        className={outerClassName}
        fill="none"
        strokeWidth="6"
        d="M20,0 a20,20 0 0,1 20,20"
      />
      <path
        className={innerClassName}
        fill="none"
        strokeWidth="6"
        d="M20,0 a20,20 0 1,0 20,20"
      />
    </svg>
  );
}

type LoadingIconKind = "default" | "info";

const iconProps: Record<LoadingIconKind, LoadingIconProps> = {
  default: {
    outerClassName: "stroke-gray-200",
    innerClassName: "stroke-gray-300",
  },
  info: {
    outerClassName: "stroke-blue-300",
    innerClassName: "stroke-blue-400",
  },
};

interface Props {
  className?: string;
  kind?: LoadingIconKind;
}

export default function Loading({ className, kind = "default" }: Props) {
  return (
    <div className={`${className} animate-spin`}>
      <LoadingIcon {...iconProps[kind]} />
    </div>
  );
}
