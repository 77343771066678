import { graphql, usePaginationFragment } from "react-relay";
import { CompetitionsFragment$key } from "./__generated__/CompetitionsFragment.graphql";
import CompetitionCard from "./CompetitionCard";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

const CompetitionsFragment = graphql`
  fragment CompetitionsFragment on Query
  @refetchable(queryName: "CompetitionsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
  ) {
    viewer @ifAllowed {
      canCreateCompetition: can(action: CREATE_COMPETITION)
    }
    competitions(after: $cursor, first: $count)
      @connection(key: "CompetitionsFragment_competitions") {
      edges {
        node {
          id
          ...CompetitionCardFragment
        }
      }
    }
  }
`;

interface Props {
  query: CompetitionsFragment$key;
}

export default function Competitions({ query }: Props) {
  const { data /* loadNext, hasNext */ } = usePaginationFragment(
    CompetitionsFragment,
    query,
  );
  if (
    data.competitions.edges.length === 0 &&
    !data.viewer?.canCreateCompetition
  ) {
    return null;
  }
  return (
    <div className="space-y-6">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold font-poppins">
          <FormattedMessage defaultMessage="Competitions" />
        </h1>
        <div className="w-full" />
        {data.viewer?.canCreateCompetition && (
          <Button kind="primary" to="/competitions/new">
            <FormattedMessage defaultMessage="Create Competition" />
          </Button>
        )}
      </div>
      {data.competitions.edges.length > 0 ? (
        <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1">
          {data.competitions.edges.map(({ node }) => (
            <CompetitionCard key={node.id} competition={node} />
          ))}
        </div>
      ) : (
        <div className="text-gray-400">
          <FormattedMessage defaultMessage="No competitions yet" />
        </div>
      )}
    </div>
  );
}
