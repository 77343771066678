/**
 * @generated SignedSource<<284ef022509e200fa013a8262e84836e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderFragment$data = {
  readonly events: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly viewer: {
    readonly canCreateEvent: boolean;
    readonly displayName: string;
    readonly id: string;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
  readonly " $fragmentType": "HeaderFragment";
};
export type HeaderFragment$key = {
  readonly " $data"?: HeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": "canCreateEvent",
          "args": [
            {
              "kind": "Literal",
              "name": "action",
              "value": "CREATE_EVENT"
            }
          ],
          "kind": "ScalarField",
          "name": "can",
          "storageKey": "can(action:\"CREATE_EVENT\")"
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "thumbnail",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "EntityProfilePicFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "EventConnection",
      "kind": "LinkedField",
      "name": "events",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EventEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Event",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "events(first:1)"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "71c5cd61fbe75aac597d548fbedff714";

export default node;
