import { useMemo } from "react";
import { graphql, useFragment, useSubscription } from "react-relay";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { GraphQLSubscriptionConfig } from "relay-runtime";
import { CompetitionEntitySubmissionStatusEntityFragment$key } from "./__generated__/CompetitionEntitySubmissionStatusEntityFragment.graphql";
import { CompetitionEntitySubmissionStatusCompetitionFragment$key } from "./__generated__/CompetitionEntitySubmissionStatusCompetitionFragment.graphql";
import SubmissionStatusBadge from "./SubmissionStatusBadge";
import { CompetitionEntitySubmissionStatusSubscription as CompetitionEntitySubmissionStatusSubscriptionType } from "./__generated__/CompetitionEntitySubmissionStatusSubscription.graphql";
import { RefetchFn } from "./CompetitionLeaderboard";
import { MdFolderOpen } from "react-icons/md";

const CompetitionEntitySubmissionStatusEntityFragment = graphql`
  fragment CompetitionEntitySubmissionStatusEntityFragment on Entity {
    id
    username
  }
`;

const CompetitionEntitySubmissionStatusCompetitionFragment = graphql`
  fragment CompetitionEntitySubmissionStatusCompetitionFragment on Competition
  @refetchable(queryName: "CompetitionEntitySubmissionStatusCompetitionQuery") {
    id
    slug
    submission @ifAllowed {
      id
      latest {
        id
        version
        ...SubmissionStatusBadgeFragment
      }
      maxEvaluation {
        projectVersion {
          id
          version
          ...SubmissionStatusBadgeFragment
        }
      }
    }
  }
`;

const CompetitionEntitySubmissionStatusSubscription = graphql`
  subscription CompetitionEntitySubmissionStatusSubscription(
    $competitionId: ID!
    $entityId: ID!
  ) {
    projectVersionStatusUpdate(
      competitionId: $competitionId
      entityId: $entityId
    ) {
      latest
      evaluation {
        max
      }
      project {
        competition {
          ...CompetitionEntitySubmissionStatusCompetitionFragment
        }
      }
    }
  }
`;

interface Props {
  competition: CompetitionEntitySubmissionStatusCompetitionFragment$key;
  entity: CompetitionEntitySubmissionStatusEntityFragment$key;
  refetchLeaderboard?: RefetchFn;
}

export default function CompetitionEntitySubmissionStatus({
  competition: competitionFragment,
  entity: entityFragment,
  refetchLeaderboard,
}: Props) {
  const competition = useFragment(
    CompetitionEntitySubmissionStatusCompetitionFragment,
    competitionFragment,
  );
  const entity = useFragment(
    CompetitionEntitySubmissionStatusEntityFragment,
    entityFragment,
  );
  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<CompetitionEntitySubmissionStatusSubscriptionType> => ({
        variables: { competitionId: competition.id, entityId: entity.id },
        subscription: CompetitionEntitySubmissionStatusSubscription,
        updater: (_, result) => {
          const update = result?.projectVersionStatusUpdate;
          if (
            refetchLeaderboard &&
            (update?.latest || update?.evaluation?.max)
          ) {
            refetchLeaderboard(
              {},
              {
                fetchPolicy: "store-and-network",
                onComplete: (error) => {
                  if (error) {
                    console.error(error);
                  }
                },
              },
            );
          }
        },
      }),
      [competition.id, entity.id, refetchLeaderboard],
    ),
  );

  const latest = competition.submission?.latest;
  if (!latest) {
    // TODO return some info for participating
    return null;
  }
  const max = competition.submission?.maxEvaluation?.projectVersion;

  return (
    <div className="flex items-start flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0 md:items-center">
      <span>
        <FormattedMessage defaultMessage="Latest submissions:" />
      </span>
      <SubmissionStatusBadge
        label={`v${latest.version}`}
        projectVersion={latest}
      >
        <Link
          className="underline"
          to={`/${entity.username}/submissions/${competition.slug}/${latest.version}/code/package`}
        >
          <MdFolderOpen className="inline-block mr-1" />
          <FormattedMessage defaultMessage="Browse Files" />
        </Link>
      </SubmissionStatusBadge>
      {max && !(max.id == latest.id) && (
        <SubmissionStatusBadge label={`v${max.version}`} projectVersion={max}>
          <Link
            className="underline"
            to={`/${entity.username}/submissions/${competition.slug}/${max.version}/code/package`}
          >
            <MdFolderOpen className="inline-block mr-1" />
            <FormattedMessage defaultMessage="Browse Files" />
          </Link>
        </SubmissionStatusBadge>
      )}
    </div>
  );
}
