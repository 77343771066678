import { ConnectionHandler, graphql, useMutation } from "react-relay";
import Button from "./Button";
import { TbAwardOff } from "react-icons/tb";
import { toast } from "sonner";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { logger } from "../common/logger";

const Mutation = graphql`
  mutation EntityBadgeWithdrawButtonMutation($connections: [ID!]!, $id: ID!) {
    withdrawBadge(id: $id) @deleteEdge(connections: $connections)
  }
`;

export interface EntityBadgeWithdrawButtonProps {
  id: ID;
  entityId: ID;
  onWithdrawn?: (() => void) | undefined;
}

export function EntityBadgeWithdrawButton({
  id,
  entityId,
  onWithdrawn,
}: EntityBadgeWithdrawButtonProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [withdraw, withdrawing] = useMutation(Mutation);

  const onWithdraw = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    withdraw({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            entityId,
            "EntityBadgeList_entity_badges",
          ),
        ],
      },
      onCompleted: (_response) => {
        onWithdrawn?.();
        toast.success(
          intl.formatMessage({
            defaultMessage: "Badge has been successfully withdrawn.",
          }),
        );
        navigate(-1);
      },
      onError: (error) => {
        logger.error(error);
        toast.error(
          intl.formatMessage({
            defaultMessage:
              "There was an error while withdrawing this badge: please try again later...",
          }),
        );
      },
    });
  };

  return (
    <Button
      kind="danger"
      className="w-full flex flex-row justify-center items-center gap-2"
      disabled={withdrawing}
      onClick={onWithdraw}
    >
      Withdraw
      <TbAwardOff />
    </Button>
  );
}
