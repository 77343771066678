import { IoMdShare } from "react-icons/io";
import { useLocation } from "../utils/location";
import { ToolbarButton } from "./Button";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";
import {
  getLinkedInUrl,
  getTwitterUrl,
} from "../utils/generatedSocialSharingLink";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  title: string;

  injectedText?: string;
}

export function SocialSharingDropdown({
  injectedText,
  title,
}: Readonly<Props>) {
  const intl = useIntl();
  const location = useLocation();
  const currentUrl = location.href;

  const nativeSharingPayload = {
    title,
    text:
      injectedText ||
      intl.formatMessage({ defaultMessage: "Join the discussion on Aqora!" }),
    url: currentUrl,
  };

  return (
    <>
      <ToolbarButton
        size="sm"
        className="sm:hidden"
        onClick={() => navigator.share(nativeSharingPayload)}
      >
        <IoMdShare />
      </ToolbarButton>
      <div className="hidden sm:inline">
        <Dropdown
          trigger={
            <ToolbarButton size="sm">
              <IoMdShare />
            </ToolbarButton>
          }
        >
          <Link
            to={getTwitterUrl({ url: currentUrl, injectedText })}
            target="_blank"
          >
            <Dropdown.Item icon={<FaXTwitter />}>
              <FormattedMessage defaultMessage="Share on X" />
            </Dropdown.Item>
          </Link>
          <Link
            to={getLinkedInUrl({ url: currentUrl, injectedText })}
            target="_blank"
          >
            <Dropdown.Item icon={<FaLinkedin />}>
              <FormattedMessage defaultMessage="Share on LinkedIn" />
            </Dropdown.Item>
          </Link>
        </Dropdown>
      </div>
    </>
  );
}
