import { useCompetitionsPageQuery } from "./loaders/CompetitionsPage";
import Competitions from "../components/Competitions";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function CompetitionsPage() {
  const intl = useIntl();
  const { query } = useCompetitionsPageQuery();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({ defaultMessage: "Competitions" })}
    >
      <div className="pb-10">
        <Competitions query={query} />
      </div>
    </MetaLayout>
  );
}
