import { graphql } from "react-relay";
import { defineMessage } from "react-intl";
import { Badge as BadgeName } from "./__generated__/badges.graphql";
import { MessageDescriptor } from "react-intl";
import test from "./test.png";
import paris2024_winners from "./paris2024-winners.webp";
import paris2024_hackers from "./paris2024-hackers.webp";

graphql`
  fragment badges on EntityBadge {
    badge
  }
`;

export interface Badge {
  id: BadgeName;
  name: MessageDescriptor;
  description: MessageDescriptor;
  image: string;
}

function createBadges(...badges: Badge[]): Record<BadgeName, Badge> {
  return badges.reduce<Record<BadgeName, Badge>>(
    (acc, badge) => {
      const duplicated = acc[badge.id];
      if (duplicated) {
        throw new Error(
          `Duplicated badge id "${badge.id}": ${JSON.stringify(duplicated)} ${JSON.stringify(badge)}`,
        );
      }
      return { ...acc, [badge.id]: badge };
    },
    {} as Record<BadgeName, Badge>,
  );
}

export const badges: Record<BadgeName, Badge> = createBadges(
  {
    id: "TEST",
    name: defineMessage({
      id: "test-name",
      defaultMessage: "Quantum Badge",
    }),
    description: defineMessage({
      id: "test-description",
      defaultMessage:
        "You did some awesome work, this really deserves a badge!",
    }),
    image: test,
  },
  {
    id: "PARIS_2024_WINNERS",
    image: paris2024_winners,
    name: defineMessage({
      id: "paris2024-winners-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Winner",
    }),
    description: defineMessage({
      id: "paris2024-winners-badge-description",
      defaultMessage:
        "Won the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
  {
    id: "PARIS_2024_HACKERS",
    image: paris2024_hackers,
    name: defineMessage({
      id: "paris2024-hackers-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Participant",
    }),
    description: defineMessage({
      id: "paris2024-hackers-badge-description",
      defaultMessage:
        "Participated in the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
);
