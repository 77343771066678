import React from "react";
import { graphql, usePaginationFragment } from "react-relay";
import { CompetitionTopicsFragment$key } from "./__generated__/CompetitionTopicsFragment.graphql";
import TopicListItem from "./TopicListItem";
import LoadMore from "./LoadMore";
import { VotableOrder } from "../utils/votableOrder";
import { useOnChangeEffect } from "../utils/hooks";

const CompetitionTopicsFragment = graphql`
  fragment CompetitionTopicsFragment on Competition
  @refetchable(queryName: "CompetitionTopicsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    topics(after: $cursor, first: $count, order: $order)
      @connection(key: "CompetitionTopicsFragment_topics") {
      edges {
        node {
          id
          ...TopicListItemFragment
        }
      }
    }
  }
`;

interface Props {
  competition: CompetitionTopicsFragment$key;
  order: VotableOrder;
}

export default function CompetitionTopics({ competition, order }: Props) {
  const { data, loadNext, hasNext, isLoadingNext, refetch } =
    usePaginationFragment(CompetitionTopicsFragment, competition);
  useOnChangeEffect(order, (order) =>
    refetch({ order }, { fetchPolicy: "store-and-network" }),
  );
  return (
    <div>
      {data.topics.edges.map((edge, index) => (
        <React.Fragment key={edge.node.id}>
          <TopicListItem topic={edge.node} />
          {index < data.topics.edges.length - 1 && <hr className="my-4" />}
        </React.Fragment>
      ))}
      {data.topics.edges.length === 0 && (
        <p className="text-gray-400">No topics</p>
      )}
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
