import { useState } from "react";
import CompetitionTopics from "../components/CompetitionTopics";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import Button from "../components/Button";
import { MetaLayout } from "../common/MetaLayout";
import { useCompetitionTopicsPageQuery } from "./loaders/CompetitionTopicsPage";
import VotableOrderSelect from "../components/VotableOrderSelect";
import { VotableOrder } from "../utils/votableOrder";
import { useAuth } from "../utils/auth";

export default function CompetitionTopicsPage() {
  const { userId } = useAuth();
  const {
    query,
    variables: { slug, order: initialOrder },
  } = useCompetitionTopicsPageQuery();
  const intl = useIntl();
  const [order, setOrder] = useState<VotableOrder>(initialOrder);
  const competition = query?.competitionBySlug;
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{competitionTitle} | discussions" },
        { competitionTitle: query.competitionBySlug.title },
      )}
    >
      <div className="flex flex-row">
        <div className="flex flex-row w-full items-baseline space-x-4">
          <h1 className="text-2xl font-bold font-poppins">
            <FormattedMessage defaultMessage="Topics" />
          </h1>
          <VotableOrderSelect value={order} onChange={setOrder} />
        </div>
        {userId && competition.viewerCanCreateTopic && (
          <div className="flex-grow-0">
            <Button
              to={`/competitions/${slug}/discussions/new`}
              state={{ order }}
            >
              <FormattedMessage defaultMessage="New Topic" />
            </Button>
          </div>
        )}
      </div>
      <div className="pt-4 pb-8">
        <CompetitionTopics competition={competition} order={order} />
      </div>
    </MetaLayout>
  );
}
