import { useLazyLoadQuery, graphql } from "react-relay";
import { EntityEditPageQuery as EntityEditPageQueryType } from "./__generated__/EntityEditPageQuery.graphql";
import { useParams } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useIntl } from "react-intl";
import UserEditForm from "../components/UserEditForm";
import OrganizationEditForm from "../components/OrganizationEditForm";

const EntityEditPageQuery = graphql`
  query EntityEditPageQuery($username: String!) {
    entityByUsername(username: $username) {
      kind
      ... on User {
        ...UserEditFormFragment
      }
      ... on Organization {
        ...OrganizationEditFormFragment
      }
    }
  }
`;

export default function EntityEditPage() {
  const intl = useIntl();
  const { username } = useParams();
  const query = useLazyLoadQuery<EntityEditPageQueryType>(EntityEditPageQuery, {
    username: username ?? "",
  });
  const entity = query.entityByUsername;

  if (!entity) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username,
          },
        )}
      />
    );
  }

  if (entity.kind == "USER") {
    return <UserEditForm user={entity} />;
  } else {
    return <OrganizationEditForm organization={entity} />;
  }
}
