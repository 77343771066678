import ErrorPage from "./ErrorPage";
import { FormattedMessage, useIntl } from "react-intl";
import UserOrganizations from "../components/UserOrganizations";
import { useUserOrganizationsPageQuery } from "./loaders/UserOrganizationsPage";
import Button from "../components/Button";

export default function UserOrganizationsPage() {
  const intl = useIntl();
  const { variables, query } = useUserOrganizationsPageQuery();
  if (!query.entityByUsername) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }
  const user = query.entityByUsername;
  return (
    <div>
      {query.viewer?.canCreateOrganization && (
        <div className="flex flex-row justify-end pb-8">
          <Button to="/organization/new">
            <FormattedMessage defaultMessage="Create Organization" />
          </Button>
        </div>
      )}
      <UserOrganizations user={user} />
    </div>
  );
}
