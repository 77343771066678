import * as Radix from "@radix-ui/react-dialog";
import React from "react";
import { MdClose } from "react-icons/md";
import { cn } from "../../utils/tailwind";

export const Root = Radix.Root;

export const Trigger = Radix.Trigger;

export const Portal = Radix.Portal;

export const Overlay = React.forwardRef<
  HTMLDivElement,
  Radix.DialogOverlayProps
>(({ className, ...props }, ref) => (
  <Radix.Overlay
    ref={ref}
    {...props}
    className={cn(
      "bg-midnight/40 fixed inset-0 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
  />
));

export const Content = React.forwardRef<
  HTMLDivElement,
  Radix.DialogContentProps
>(({ className, ...props }, ref) => (
  <Portal>
    <Overlay />
    <Radix.Content
      ref={ref}
      {...props}
      className={cn(
        "fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex flex-col gap-3 bg-white rounded shadow focus:outline-none p-5 border border-slate-50",
        className,
      )}
    />
  </Portal>
));

export const Header = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className,
    )}
    {...props}
  />
);

export const Footer = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
      className,
    )}
    {...props}
  />
);

export const Title = ({ className, ...props }: Radix.DialogTitleProps) => (
  <Radix.Title
    {...props}
    className={cn(
      "text-lg font-semibold leading-none tracking-tigh",
      className,
    )}
  />
);

export const Description = Radix.Description;

export const Close = React.forwardRef<
  HTMLButtonElement,
  Radix.DialogCloseProps
>(({ className, ...props }, ref) =>
  props.asChild ? (
    <Radix.Close ref={ref} className={className} {...props} />
  ) : (
    <Radix.Close ref={ref} asChild>
      <button
        className={cn(
          "cursor-pointer bg-zinc-100 p-2 rounded focus:outline-none focus:ring-2 focus:ring-midnight focus:ring-offset-2 opacity-70 ring-offset-background transition-opacity hover:opacity-100 disabled:pointer-events-none data-[state=open]:bg-purple data-[state=open]:text-zinc-100",
          className,
        )}
      >
        <MdClose />
      </button>
    </Radix.Close>
  ),
);
