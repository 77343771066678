import { Environment, useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { UserSettingsNotificationsPageQuery as UserSettingsNotificationsPageQueryType } from "./__generated__/UserSettingsNotificationsPageQuery.graphql";
import {
  UserSettingsNotificationsPageFragment$data,
  UserSettingsNotificationsPageFragment$key,
} from "./__generated__/UserSettingsNotificationsPageFragment.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export const UserSettingsNotificationsPageFragment = graphql`
  fragment UserSettingsNotificationsPageFragment on User {
    id
    notifications {
      enabled
      disabled
    }
  }
`;

export const UserSettingsNotificationsPageQuery = graphql`
  query UserSettingsNotificationsPageQuery {
    viewer {
      id
      ...UserSettingsNotificationsPageFragment
    }
  }
`;

export const userSettingsNotificationsQueryLoader = (
  environment: Environment,
) => {
  return () =>
    preload<UserSettingsNotificationsPageQueryType>(
      environment,
      UserSettingsNotificationsPageQuery,
    );
};

export const useUserSettingsNotificationsQuery = () =>
  usePreloaded<UserSettingsNotificationsPageQueryType>();

export const useUserSettingsNotificationsFragment = (
  key: UserSettingsNotificationsPageFragment$key,
): UserSettingsNotificationsPageFragment$data => {
  return useFragment(UserSettingsNotificationsPageFragment, key);
};
