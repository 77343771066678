import { graphql, Environment } from "react-relay";
import { CompetitionCreatePageQuery as CompetitionCreatePageQueryType } from "./__generated__/CompetitionCreatePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

const CompetitionCreatePageQuery = graphql`
  query CompetitionCreatePageQuery {
    ...CompetitionTagInputFragment
  }
`;

export const competitionCreatePageQueryLoader = (environement: Environment) => {
  return () =>
    preload<CompetitionCreatePageQueryType>(
      environement,
      CompetitionCreatePageQuery,
    );
};

export const useCompetitionCreatePage = () =>
  usePreloaded<CompetitionCreatePageQueryType>();
