import React from "react";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
  ratio: number;
}

const AspectDiv = React.forwardRef<HTMLDivElement, Props>(
  ({ ratio: aspectRatio, className, style, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={`relative w-full ${className}`}
        style={{
          paddingBottom: `${Math.round(10000 / aspectRatio) / 100}%`,
          ...style,
        }}
        {...props}
      >
        <div className="absolute inset-0 w-full h-full">{children}</div>
      </div>
    );
  },
);

export default AspectDiv;
