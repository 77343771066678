import { graphql, usePaginationFragment } from "react-relay";
import { FileBrowserFragment$key } from "./__generated__/FileBrowserFragment.graphql";
import { FormattedNumber, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa6";
import FileTypeIcon from "./FileTypeIcon";
import PathBreadcrumbs from "./PathBreadcrumbs";
import Button from "../Button";

const FileBrowserFragment = graphql`
  fragment FileBrowserFragment on ProjectVersionFile
  @refetchable(queryName: "FileBrowserPaginationQuery")
  @argumentDefinitions(
    path: { type: "String" }
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
  ) {
    browse {
      readDir(path: $path, after: $cursor, first: $count)
        @connection(key: "FileBrowser_readDir") {
        edges {
          node {
            name
            kind
            ... on FileBrowserFileEntry {
              contentType
              contentLength
            }
          }
        }
      }
    }
  }
`;

interface Props {
  root: string;
  path?: string;
  file: FileBrowserFragment$key;
}

const uniformPath = (path: string) => {
  if (path.startsWith("/")) {
    path = path.slice(1);
  }
  if (path.length > 0 && !path.endsWith("/")) {
    path = path + "/";
  }
  return path;
};

export default function FileBrowser({
  file: fileFragment,
  path: nonUniformPath,
  root,
}: Props) {
  const { data, loadNext, hasNext } = usePaginationFragment(
    FileBrowserFragment,
    fileFragment,
  );
  const navigate = useNavigate();
  const path = uniformPath(nonUniformPath || "");
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row items-center">
        <Button
          disabled={path.length < 1}
          className="flex flex-row space-x-2 items-center"
          kind="text"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaArrowLeft />
          <span>
            <FormattedMessage defaultMessage="back" />
          </span>
        </Button>

        <PathBreadcrumbs root={root} path={path} />
      </div>
      <table className="border">
        <thead className="text-left border bg-slate-50">
          <tr>
            <th colSpan={2} className="px-4 py-2">
              Name
            </th>
            <th className="px-4 py-2">Type</th>
            <th className="px-4 py-2">Size</th>
          </tr>
        </thead>

        <tbody className="px-4">
          {data.browse.readDir.edges.map(({ node: entry }) => {
            return (
              <tr
                key={entry.name}
                className="hover:bg-gray-50 cursor-pointer text-nowrap"
                onClick={() => {
                  if (entry.kind === "FILE") {
                    navigate(`${root}/blob/${path}${entry.name}`);
                  } else {
                    navigate(`${root}/tree/${path}${entry.name}`);
                  }
                }}
              >
                <td className="text-slate-700 ps-4 py-2">
                  <FileTypeIcon fileType={entry.kind} />
                </td>
                <td className="text-blue-600 ps-2 pe-4 py-2 w-full">
                  {entry.name}
                </td>
                <td className="text-slate-500 ps-2 pe-4 py-2">
                  {entry.kind === "FILE" && entry.contentType}
                </td>
                <td className="px-4">
                  {entry.contentLength && (
                    <FormattedNumber
                      value={entry.contentLength}
                      notation="compact"
                      style="unit"
                      unit="byte"
                      unitDisplay="narrow"
                    />
                  )}
                </td>
              </tr>
            );
          })}
          {hasNext && (
            <tr>
              <td colSpan={4} className="text-center">
                <Button
                  kind="text"
                  onClick={() => {
                    loadNext(100);
                  }}
                >
                  <FormattedMessage defaultMessage="Load more" />
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
