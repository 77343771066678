/**
 * @generated SignedSource<<1cf3055fa56c72ea7655c3b6010ac36b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileBrowserEntryKind = "DIRECTORY" | "FILE";
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type CompetitionCodeReadmePageQuery$variables = {
  fileKind: ProjectVersionFileKind;
  slug: string;
};
export type CompetitionCodeReadmePageQuery$data = {
  readonly competitionBySlug: {
    readonly id: string;
    readonly useCase: {
      readonly latest: {
        readonly fileByKind: {
          readonly browse: {
            readonly readMeta: {
              readonly kind: FileBrowserEntryKind;
              readonly " $fragmentSpreads": FragmentRefs<"FileViewerFragment">;
            } | null | undefined;
          };
          readonly " $fragmentSpreads": FragmentRefs<"FileBrowserFragment">;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type CompetitionCodeReadmePageQuery = {
  response: CompetitionCodeReadmePageQuery$data;
  variables: CompetitionCodeReadmePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "fileKind"
  }
],
v5 = [
  {
    "kind": "Literal",
    "name": "path",
    "value": "README.md"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentLength",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionCodeReadmePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "FileBrowserFragment"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileBrowser",
                        "kind": "LinkedField",
                        "name": "browse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "readMeta",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "FileViewerFragment"
                                  }
                                ],
                                "type": "FileBrowserFileEntry",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": "readMeta(path:\"README.md\")"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionCodeReadmePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileBrowser",
                        "kind": "LinkedField",
                        "name": "browse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "concreteType": "FileBrowserEntryConnection",
                            "kind": "LinkedField",
                            "name": "readDir",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FileBrowserEntryEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v6/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/)
                                        ],
                                        "type": "FileBrowserFileEntry",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "readDir(first:100)"
                          },
                          {
                            "alias": null,
                            "args": (v7/*: any*/),
                            "filters": [
                              "path"
                            ],
                            "handle": "connection",
                            "key": "FileBrowser_readDir",
                            "kind": "LinkedHandle",
                            "name": "readDir"
                          },
                          {
                            "alias": null,
                            "args": (v5/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "readMeta",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v6/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "downloadUrl",
                                    "storageKey": null
                                  }
                                ],
                                "type": "FileBrowserFileEntry",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": "readMeta(path:\"README.md\")"
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e1bf4ea7d054af3563f68f84f71543b2",
    "id": null,
    "metadata": {},
    "name": "CompetitionCodeReadmePageQuery",
    "operationKind": "query",
    "text": "query CompetitionCodeReadmePageQuery(\n  $slug: String!\n  $fileKind: ProjectVersionFileKind!\n) {\n  competitionBySlug(slug: $slug) {\n    id\n    useCase {\n      latest {\n        fileByKind(kind: $fileKind) {\n          ...FileBrowserFragment\n          browse {\n            readMeta(path: \"README.md\") {\n              __typename\n              kind\n              ... on FileBrowserFileEntry {\n                ...FileViewerFragment\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FileBrowserFragment on ProjectVersionFile {\n  browse {\n    readDir(first: 100) {\n      edges {\n        node {\n          __typename\n          name\n          kind\n          ... on FileBrowserFileEntry {\n            contentType\n            contentLength\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  id\n}\n\nfragment FileViewerFragment on FileBrowserFileEntry {\n  name\n  contentType\n  contentLength\n  downloadUrl\n}\n"
  }
};
})();

(node as any).hash = "c52992894597eb756aacfca4309c74be";

export default node;
