import { graphql, Environment } from "react-relay";
import { AuthScaffoldQuery as AuthScaffoldQueryType } from "./__generated__/AuthScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { AuthScaffoldQuery as AuthScaffoldQueryType } from "./__generated__/AuthScaffoldQuery.graphql";

export const AuthScaffoldQuery = graphql`
  query AuthScaffoldQuery {
    viewer @ifAllowed {
      id
    }
  }
`;

export const authScaffoldQueryLoader = (environment: Environment) => {
  return () => preload<AuthScaffoldQueryType>(environment, AuthScaffoldQuery);
};

export const useAuthScaffoldQuery = () => usePreloaded<AuthScaffoldQueryType>();
