/**
 * @generated SignedSource<<14f720c9069ac29a2df8f1dcb5fdfacc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type FileBrowserEntryKind = "DIRECTORY" | "FILE";
import { FragmentRefs } from "relay-runtime";
export type FileBrowserFragment$data = {
  readonly browse: {
    readonly readDir: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly contentLength?: number;
          readonly contentType?: string;
          readonly kind: FileBrowserEntryKind;
          readonly name: string;
        };
      }>;
    };
  };
  readonly id: string;
  readonly " $fragmentType": "FileBrowserFragment";
};
export type FileBrowserFragment$key = {
  readonly " $data"?: FileBrowserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FileBrowserFragment">;
};

import FileBrowserPaginationQuery_graphql from './FileBrowserPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "browse",
  "readDir"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 100,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "path"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": FileBrowserPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "FileBrowserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FileBrowser",
      "kind": "LinkedField",
      "name": "browse",
      "plural": false,
      "selections": [
        {
          "alias": "readDir",
          "args": [
            {
              "kind": "Variable",
              "name": "path",
              "variableName": "path"
            }
          ],
          "concreteType": "FileBrowserEntryConnection",
          "kind": "LinkedField",
          "name": "__FileBrowser_readDir_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FileBrowserEntryEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "kind",
                      "storageKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contentType",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contentLength",
                          "storageKey": null
                        }
                      ],
                      "type": "FileBrowserFileEntry",
                      "abstractKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ProjectVersionFile",
  "abstractKey": null
};
})();

(node as any).hash = "f419400c4dc68ee1a7420e70c0cb5355";

export default node;
