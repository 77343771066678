import { useEffect, useReducer } from "react";
import {
  useWatch,
  useController,
  UseControllerProps,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import TextInput from "./TextInput";

enum EditState {
  Unfocused,
  Focused,
  Changed,
}

enum EditEvent {
  Focus,
  Blur,
  Change,
}

const editStateReducer = (state: EditState, event: EditEvent) => {
  switch (state) {
    case EditState.Unfocused:
      switch (event) {
        case EditEvent.Focus:
          return EditState.Focused;
        case EditEvent.Blur:
        case EditEvent.Change:
          return state;
      }
      break;
    case EditState.Focused:
      switch (event) {
        case EditEvent.Change:
          return EditState.Changed;
        case EditEvent.Blur:
          return EditState.Unfocused;
        case EditEvent.Focus:
          return state;
      }
      break;
    case EditState.Changed:
      switch (event) {
        case EditEvent.Focus:
        case EditEvent.Blur:
        case EditEvent.Change:
          return state;
      }
      break;
  }
};

interface Props<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {
  auto?: boolean;
  prefix?: string;
  watchName: Path<TFieldValues>;
  watchDefaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  maxLength?: number;
  className?: string;
}

export default function SlugInput<TFieldValues extends FieldValues>({
  auto = false,
  prefix,
  control,
  watchName,
  watchDefaultValue,
  rules,
  maxLength = 35,
  className,
  ...useControllerRest
}: Props<TFieldValues>) {
  const [state, dispatch] = useReducer(editStateReducer, EditState.Unfocused);
  const {
    field: { onChange, onBlur, value, disabled, ref },
    fieldState: { error },
  } = useController({
    control,
    rules: {
      pattern: /^[-a-zA-Z0-9_]*$/,
      maxLength,
      ...rules,
    },
    ...useControllerRest,
  });
  const watched = useWatch({
    control,
    name: watchName,
    defaultValue: watchDefaultValue,
  });
  useEffect(() => {
    if (auto && watched && state != EditState.Changed) {
      onChange(
        watched
          .toLowerCase()
          .replace(/[^a-z0-9-]+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "")
          .substring(0, maxLength),
      );
    }
  }, [onChange, watched, state, auto, maxLength]);
  return (
    <TextInput
      prefix={prefix}
      value={value || ""}
      onChange={(event) => {
        dispatch(EditEvent.Change);
        onChange(event.target.value.trim());
      }}
      onFocus={() => {
        dispatch(EditEvent.Focus);
      }}
      onBlur={() => {
        dispatch(EditEvent.Blur);
        onBlur();
      }}
      disabled={disabled}
      ref={ref}
      aria-invalid={error ? "true" : "false"}
      className={className}
    />
  );
}
