import { useState } from "react";
import { MdLink, MdCheck } from "react-icons/md";
import { toast } from "sonner";

interface Props {
  to: string;
}

export default function CopyLink({ to }: Props) {
  const [copied, setCopied] = useState(false);
  return (
    <button
      onClick={() => {
        navigator.clipboard.writeText(to);
        setCopied(true);
        toast.success("Copied to clipboard");
      }}
    >
      {copied ? <MdCheck /> : <MdLink />}
    </button>
  );
}
