/**
 * @generated SignedSource<<9e4329bff29425e1eea16c8ba9472ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationKind = "AWARD_BADGE" | "CONTENT_MENTIONED" | "CREATE_SUBMISSION" | "CREATE_TOPIC" | "REPLY_TOPIC" | "SYSTEM";
import { FragmentRefs } from "relay-runtime";
export type UserSettingsNotificationsPageFragment$data = {
  readonly id: string;
  readonly notifications: {
    readonly disabled: ReadonlyArray<NotificationKind>;
    readonly enabled: ReadonlyArray<NotificationKind>;
  };
  readonly " $fragmentType": "UserSettingsNotificationsPageFragment";
};
export type UserSettingsNotificationsPageFragment$key = {
  readonly " $data"?: UserSettingsNotificationsPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingsNotificationsPageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingsNotificationsPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserNotifications",
      "kind": "LinkedField",
      "name": "notifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "7577dce52cfc612aa4b40340b66b808c";

export default node;
