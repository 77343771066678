import { graphql, useMutation } from "react-relay";
import { EventOverviewPageUpdateEventMutation as EventOverviewPageUpdateEventMutationType } from "./__generated__/EventOverviewPageUpdateEventMutation.graphql";
import { useEventOverviewPageQuery } from "./loaders/EventOverviewPage";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import EditMarkdownSection from "../components/EditMarkdownSection";
import EventCompetitionsCarousel from "../components/EventCompetitionsCarousel";
import EventTeams from "../components/EventTeams";
import { logger } from "../common/logger";

const EventOverviewPageUpdateEventMutation = graphql`
  mutation EventOverviewPageUpdateEventMutation(
    $id: ID!
    $input: UpdateEventInput!
  ) {
    updateEvent(id: $id, input: $input) {
      node {
        id
        description
      }
    }
  }
`;

export default function EventOverviewPage() {
  const intl = useIntl();
  const { query } = useEventOverviewPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<EventOverviewPageUpdateEventMutationType>(
      EventOverviewPageUpdateEventMutation,
    );
  if (!query.eventBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  const event = query.eventBySlug;
  return (
    <div className="space-y-2">
      <EventCompetitionsCarousel event={event} />
      <EventTeams event={event} />
      <EditMarkdownSection
        members={{
          kind: "event",
          members: event,
        }}
        canUploadFiles
        canEdit={event.viewerCanUpdate}
        disabled={isMutationInFlight}
        defaultValue={event.description || ""}
        fallback={
          <p className="text-gray-400">
            <FormattedMessage defaultMessage="No description" />
          </p>
        }
        onSubmit={(value, setError, onCompleted) => {
          commitMutation({
            variables: {
              id: event.id,
              input: {
                description: value || null,
              },
            },
            onError: (error) => {
              logger.error(error);
              setError(
                intl.formatMessage({
                  defaultMessage:
                    "An error occurred while saving the description. Please, try again later.",
                }),
              );
            },
            onCompleted,
          });
        }}
      />
    </div>
  );
}
