/**
 * @generated SignedSource<<8c4111947573e0d8074f3c36ec7ab729>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectVersionStatus = "AWAITING_APPROVAL" | "AWAITING_EVALUATION" | "AWAITING_VALIDATION" | "ERROR" | "OK";
import { FragmentRefs } from "relay-runtime";
export type SubmissionStatusBadgeFragment$data = {
  readonly evaluation: {
    readonly error: string | null | undefined;
    readonly finalizedAt: string | null | undefined;
    readonly max: boolean;
    readonly score: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly status: ProjectVersionStatus;
  readonly version: string;
  readonly " $fragmentType": "SubmissionStatusBadgeFragment";
};
export type SubmissionStatusBadgeFragment$key = {
  readonly " $data"?: SubmissionStatusBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmissionStatusBadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectVersionEvaluation",
      "kind": "LinkedField",
      "name": "evaluation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "finalizedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "max",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProjectVersion",
  "abstractKey": null
};

(node as any).hash = "b68acd86275d68fd006b2398488eebde";

export default node;
