/**
 * @generated SignedSource<<2f3d5f71d8c68ef7999b7be4819e7d2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ResetPasswordInput = {
  password: string;
  secret: string;
};
export type ResetPasswordPageMutation$variables = {
  input: ResetPasswordInput;
};
export type ResetPasswordPageMutation$data = {
  readonly resetPassword: boolean;
};
export type ResetPasswordPageMutation = {
  response: ResetPasswordPageMutation$data;
  variables: ResetPasswordPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "resetPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9853455f591ead9dd888eea77ca2362b",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordPageMutation",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordPageMutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "09aac3d5c1146375394b661a161cda8e";

export default node;
