import { graphql, useMutation } from "react-relay";
import { UploadableMap } from "relay-runtime";
import { useEventEditPageQuery } from "./loaders/EventEditPage";
import {
  EventEditPageMutation as EventEditPageMutationType,
  EventEditPageMutation$variables,
} from "./__generated__/EventEditPageMutation.graphql";
import { useNavigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import CompetitionEditForm from "../components/CompetitionEditForm";
import EventDeleteButton from "../components/EventDeleteButton";
import { logger } from "../common/logger";
import { Link } from "react-router-dom";

const EventEditPageMutation = graphql`
  mutation EventEditPageMutation($id: ID!, $input: UpdateEventInput!) {
    updateEvent(id: $id, input: $input) {
      node {
        id
        title
        description
        slug
        banner
        thumbnail
        isPrivate
      }
    }
  }
`;

export default function EventEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query } = useEventEditPageQuery();
  const event = query.eventBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<EventEditPageMutationType>(EventEditPageMutation);
  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Edit Event"} />
      </h1>
      <div className="my-4">
        {event.viewerCanUpdateAgenda && (
          <Link to={`/events/${event.slug}/agenda/edit`}>
            <FormattedMessage defaultMessage="Edit the agenda" />
          </Link>
        )}
      </div>
      <CompetitionEditForm
        routePrefix="events"
        defaultValues={event}
        onSubmit={({ data, dirtyFields, banner, thumbnail, setFormError }) => {
          const variables: EventEditPageMutation$variables = {
            id: event.id,
            input: {
              title: dirtyFields.title ? data.title : undefined,
              shortDescription: dirtyFields.shortDescription
                ? data.shortDescription
                : undefined,
              slug: dirtyFields.slug ? data.slug : undefined,
              isPrivate: dirtyFields.isPrivate ? data.isPrivate : undefined,
              banner: banner.variable,
              thumbnail: thumbnail.variable,
            },
          };
          const uploadables: UploadableMap = {};
          if (banner.uploadable) {
            uploadables["variables.input.banner"] = banner.uploadable;
          }
          if (thumbnail.uploadable) {
            uploadables["variables.input.thumbnail"] = thumbnail.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError: (error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "Could not update competiton. The event URL may already be taken.",
                }),
              );
            },
            onCompleted: (results) => {
              navigate(`/events/${results.updateEvent.node.slug}`);
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
      <EventDeleteButton id={event.id} />
    </div>
  );
}
