import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { ForumTopicCreatePageQuery as ForumTopicCreatePageQueryType } from "./__generated__/ForumTopicCreatePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { ForumTopicCreatePageQuery as ForumTopicCreatePageQueryType } from "./__generated__/ForumTopicCreatePageQuery.graphql";

export const ForumTopicCreatePageQuery = graphql`
  query ForumTopicCreatePageQuery($slug: String!) {
    forumBySlug(slug: $slug) {
      id
      slug
      guidelines
    }
  }
`;

export const forumTopicCreatePageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<ForumTopicCreatePageQueryType>(
      environment,
      ForumTopicCreatePageQuery,
      { slug },
    );
  };
};

export const useForumTopicCreatePageQuery = () =>
  usePreloaded<ForumTopicCreatePageQueryType>();
