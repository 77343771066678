import { useEffect, useState } from "react";
import { graphql, useMutation } from "react-relay";
import Loading from "../components/Loading";
import { OAuth2AuthorizePageMutation as OAuth2AuthorizePageMutationType } from "./__generated__/OAuth2AuthorizePageMutation.graphql";
import { useAuth } from "../utils/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useLocation } from "../utils/location";
import { logger } from "../common/logger";

const OAuth2AuthorizePageMutation = graphql`
  mutation OAuth2AuthorizePageMutation($input: Oauth2AuthorizeInput!) {
    oauth2Authorize(input: $input) {
      unauthorized
      clientError
      redirectUri
    }
  }
`;

export default function OAuth2AuthorizePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { userId } = useAuth();
  const [commitMutation, _isMutationInFlight] =
    useMutation<OAuth2AuthorizePageMutationType>(OAuth2AuthorizePageMutation);
  const [hasError, setHasError] = useState<boolean>(false);
  useEffect(() => {
    if (userId == null) {
      return navigate({
        pathname: "/login",
        search: searchParams.toString(),
      });
    }
    const clientId = searchParams.get("client_id");
    if (clientId == null) {
      return setHasError(true);
    }
    const state = searchParams.get("state");
    const redirectUri = searchParams.get("redirect_uri");
    commitMutation({
      variables: {
        input: {
          clientId,
          state,
          redirectUri,
        },
      },
      onError: (error) => {
        logger.error(error);
        setHasError(true);
      },
      onCompleted: ({
        oauth2Authorize: { unauthorized, clientError, redirectUri },
      }) => {
        if (unauthorized) {
          return navigate({
            pathname: "/login",
            search: searchParams.toString(),
          });
        }
        if (clientError) {
          return setHasError(true);
        }
        if (redirectUri == null) {
          return setHasError(true);
        }
        location.replace(redirectUri);
      },
    });
  }, [searchParams, userId, commitMutation, setHasError, navigate, location]);

  if (hasError) {
    return <ErrorPage status={400} />;
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="w-16">
        <Loading />
      </div>
    </div>
  );
}
