import { graphql, useFragment } from "react-relay";
import {
  ProjectFileBrowserFragment$key,
  ProjectVersionFileKind,
} from "./__generated__/ProjectFileBrowserFragment.graphql";
import { FormattedMessage } from "react-intl";
import { assertNever } from "../utils/helpers";
import { useId } from "react";
import { useNavigate } from "react-router-dom";
import ProjectDownloadButton from "./ProjectDownloadButton";
import { Outlet } from "react-router-dom";

const ProjectFileBrowserFragment = graphql`
  fragment ProjectFileBrowserFragment on Project
  @argumentDefinitions(
    kind: { type: "ProjectVersionFileKind", defaultValue: TEMPLATE }
    version: { type: "Semver" }
  ) {
    __typename
    id
    version(version: $version) {
      version
      files {
        id
        kind
      }
      fileByKind(kind: $kind) {
        id
        kind
        ...ProjectDownloadButtonFragment
      }
    }
  }
`;

export interface ProjectFileBrowserProps {
  root: string;
  project: ProjectFileBrowserFragment$key;
  competition?: string | undefined;
}

export default function CompetitionFileBrowser({
  root,
  project: projectFragment,
}: ProjectFileBrowserProps) {
  const selectId = useId();
  const navigate = useNavigate();
  const project = useFragment(ProjectFileBrowserFragment, projectFragment);

  const selected = project.version?.fileByKind;
  let files = project.version?.files.concat() ?? [];
  files = files.filter((file) => file.kind in projectVersionFileKindOrder);
  files.sort(
    (lhs, rhs) =>
      projectVersionFileKindOrder[lhs.kind]! -
      projectVersionFileKindOrder[rhs.kind]!,
  );

  if (files.length <= 0) {
    return <p className="w-full py-6 text-center">No files available</p>;
  }

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row space-x-3 justify-start items-center">
          <label htmlFor={selectId} className="font-bold">
            <FormattedMessage defaultMessage="Show files in:" />
          </label>

          <select
            id={selectId}
            value={selected?.kind}
            onChange={(e) => {
              navigate(`${root}/${e.target.value.toLowerCase()}`);
            }}
            className="border border-slate-200 rounded-full px-2 py-1"
          >
            {files.map((file) => (
              <option key={file.kind} value={file.kind}>
                <ProjectVersionFileKindMessage
                  kind={file.kind}
                  inUseCase={project.__typename === "UseCase"}
                />
              </option>
            ))}
          </select>
        </div>

        {selected && (
          <div className="flex flex-row space-x-3 justify-end items-center">
            <ProjectDownloadButton fragment={selected} />
          </div>
        )}
      </div>
      <Outlet />
    </div>
  );
}

const projectVersionFileKindOrder: { [K in ProjectVersionFileKind]?: number } =
  {
    TEMPLATE: 0,
    DATA: 1,
    PACKAGE: 2,
  };

interface ProjectVersionFileKindMessageProps {
  kind: ProjectVersionFileKind;
  inUseCase?: boolean | undefined;
}

function ProjectVersionFileKindMessage({
  kind,
  inUseCase,
}: ProjectVersionFileKindMessageProps): React.ReactNode {
  switch (kind) {
    case "DATA":
      return <FormattedMessage defaultMessage="Data" />;
    case "PACKAGE":
      if (inUseCase) return <FormattedMessage defaultMessage="Use Case" />;
      else return <FormattedMessage defaultMessage="Submission" />;
    case "SUBMISSION_EVALUATION":
      return <FormattedMessage defaultMessage="Submission Evaluation" />;
    case "TEMPLATE":
      return <FormattedMessage defaultMessage="Submission Template" />;
    default:
      assertNever(kind);
  }
}
