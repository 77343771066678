/**
 * @generated SignedSource<<b0e2b6ecba248fe88de1a711d6686857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VotableOrder = "HOT" | "NEWEST" | "OLDEST";
export type ForumTopicsPageQuery$variables = {
  order: VotableOrder;
  slug: string;
};
export type ForumTopicsPageQuery$data = {
  readonly forumBySlug: {
    readonly id: string;
    readonly viewerCanCreateTopic: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ForumTopicsFragment">;
  } | null | undefined;
};
export type ForumTopicsPageQuery = {
  response: ForumTopicsPageQuery$data;
  variables: ForumTopicsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanCreateTopic",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_TOPIC"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"CREATE_TOPIC\")"
},
v5 = {
  "kind": "Variable",
  "name": "order",
  "variableName": "order"
},
v6 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  },
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": [
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "ForumTopicsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ForumTopicsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Forum",
        "kind": "LinkedField",
        "name": "forumBySlug",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "TopicConnection",
            "kind": "LinkedField",
            "name": "topics",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopicEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Topic",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "commentCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Competition",
                        "kind": "LinkedField",
                        "name": "competition",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Forum",
                        "kind": "LinkedField",
                        "name": "forum",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "author",
                        "plural": false,
                        "selections": [
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageThumbnail",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          },
                          {
                            "kind": "Literal",
                            "name": "order",
                            "value": "NEWEST"
                          }
                        ],
                        "concreteType": "CommentConnection",
                        "kind": "LinkedField",
                        "name": "comments",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "author",
                                "plural": false,
                                "selections": [
                                  (v10/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "displayName",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "comments(first:1,order:\"NEWEST\")"
                      },
                      (v9/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "votes",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EntityVote",
                            "kind": "LinkedField",
                            "name": "voted",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "score",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "viewerCanVote",
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "action",
                                "value": "PUBLISH_VOTE"
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "viewerCan",
                            "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
                          }
                        ],
                        "type": "Votable",
                        "abstractKey": "__isVotable"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "filters": [
              "order"
            ],
            "handle": "connection",
            "key": "ForumTopicsFragment_topics",
            "kind": "LinkedHandle",
            "name": "topics"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0388d02e8f9dba1b83ec7a0c1f6ca125",
    "id": null,
    "metadata": {},
    "name": "ForumTopicsPageQuery",
    "operationKind": "query",
    "text": "query ForumTopicsPageQuery(\n  $slug: String!\n  $order: VotableOrder!\n) {\n  forumBySlug(slug: $slug) {\n    id\n    viewerCanCreateTopic: viewerCan(action: CREATE_TOPIC)\n    ...ForumTopicsFragment_17VYVu\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  imageThumbnail\n}\n\nfragment ForumTopicsFragment_17VYVu on Forum {\n  topics(first: 10, order: $order) {\n    edges {\n      node {\n        id\n        ...TopicListItemFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment TopicListItemFragment on Topic {\n  id\n  title\n  url\n  createdAt\n  commentCount\n  competition {\n    slug\n    id\n  }\n  forum {\n    slug\n    id\n  }\n  author {\n    __typename\n    username\n    ...EntityProfilePicFragment_49kWkb\n    id\n  }\n  comments(first: 1, order: NEWEST) {\n    nodes {\n      createdAt\n      author {\n        username\n        displayName\n        id\n      }\n      id\n    }\n  }\n  ...VoteDisplayFragment\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "0a8606d4e27638732ff72ee706ef4c07";

export default node;
