import { useRef, useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import {
  FileViewerFragment$key,
  FileViewerFragment$data,
} from "./__generated__/FileViewerFragment.graphql";
import Loading from "../Loading";
import { Link } from "react-router-dom";
import CsvViewer from "./CsvViewer";
import Markdown from "../Markdown";
import CodeViewer from "../CodeViewer";
import PythonNotebookViewer from "./PythonNotebookViewer";
import { FormattedMessage } from "react-intl";
import { guessCodeLanguage } from "../../utils/guessCodeLanguage";
import { useFetchText, useIsomorphicLayoutEffect } from "../../utils/hooks";
import { MetaLayout } from "../../common/MetaLayout";

const FILE_VIEWER_MAX_SIZE = 10_000_000; // 10 MB

const FileViewerFragment = graphql`
  fragment FileViewerFragment on FileBrowserFileEntry {
    name
    contentType
    contentLength
    downloadUrl
  }
`;

interface Props {
  entry: FileViewerFragment$key;
  disableScroll?: boolean;
}

export default function FileViewer({
  entry: entryFragment,
  disableScroll = false,
}: Props) {
  const entry = useFragment(FileViewerFragment, entryFragment);
  const ref = useRef<HTMLDivElement>(null);
  const onLoad = useCallback(() => {
    if (ref.current && !disableScroll) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref, disableScroll]);

  return (
    <div ref={ref}>
      <div className="flex flex-row justify-between mb-6 bg-slate-50 border px-4 py-2">
        <div className="font-bold">{entry.name}</div>
        <Link to={entry.downloadUrl} className="text-blue-600">
          <FormattedMessage defaultMessage="Download" />
        </Link>
      </div>
      {entry.contentLength < FILE_VIEWER_MAX_SIZE ? (
        <FileDisplay entry={entry} onLoad={onLoad} />
      ) : (
        <FileTooBig />
      )}
    </div>
  );
}

function FileTooBig() {
  return <div className="text-center text-gray-500">File too big</div>;
}

interface FileDisplayProps {
  entry: FileViewerFragment$data;
  onLoad: () => void;
}

function FileDisplay({
  entry: { contentType, name, downloadUrl },
  onLoad,
}: FileDisplayProps) {
  const { isLoading, data, isText, error, aborted } = useFetchText(downloadUrl);
  useIsomorphicLayoutEffect(() => {
    if (!isLoading) {
      onLoad();
    }
  }, [isLoading, onLoad]);
  if (aborted) {
    return null;
  }
  if (isLoading) {
    return (
      <div
        className="w-full h-full flex items-center justify-center"
        style={
          {
            "--from-opacity": 0,
            "--to-opacity": 1,
            animation: "fade 0.2s ease-in",
          } as React.CSSProperties
        }
      >
        <div className="w-12">
          <Loading />
        </div>
      </div>
    );
  }
  if (error) {
    return error.toString();
  }

  if (data) {
    switch (contentType) {
      case "text/markdown":
        return <Markdown>{data}</Markdown>;

      case "text/csv":
        return <CsvViewer>{data}</CsvViewer>;

      case "application/vnd.jupyter":
        return <PythonNotebookViewer>{data}</PythonNotebookViewer>;

      default:
        if (isText) {
          return (
            <MetaLayout metaDescription={data}>
              <CodeViewer language={guessCodeLanguage(name) ?? "text"}>
                {data}
              </CodeViewer>
            </MetaLayout>
          );
        } else if (contentType.startsWith("image/")) {
          const imageSrc = `data:${contentType};base64,${data}`;
          return (
            <MetaLayout metaImageUrl={imageSrc} metaImageAlt={name}>
              <img src={`data:${contentType};base64,${data}`} alt={name} />
            </MetaLayout>
          );
        } else {
          return (
            <div className="text-center text-gray-500">
              <FormattedMessage defaultMessage="Cannot load binary file in browser" />
            </div>
          );
        }
    }
  }
}
