import React, { PropsWithChildren, useMemo } from "react";
import { graphql, useFragment, useSubscription } from "react-relay";
import { GraphQLSubscriptionConfig } from "relay-runtime";
import { SubmissionStatusBadgeFragment$key } from "./__generated__/SubmissionStatusBadgeFragment.graphql";
import { SubmissionStatusBadgeSubscription as SubmissionStatusBadgeSubscriptionType } from "./__generated__/SubmissionStatusBadgeSubscription.graphql";
import { MdErrorOutline, MdHourglassBottom } from "react-icons/md";
import {
  FormattedMessage,
  FormattedNumber,
  FormattedRelativeTime,
} from "react-intl";
import * as Popover from "./Popover";
import Note, { NoteColor } from "./Note";
import Loading from "./Loading";

const SubmissionStatusBadgeFragment = graphql`
  fragment SubmissionStatusBadgeFragment on ProjectVersion {
    id
    status
    version
    evaluation {
      score
      error
      finalizedAt
      max
    }
  }
`;

const SubmissionStatusBadgeSubscription = graphql`
  subscription SubmissionStatusBadgeSubscription($id: ID!) {
    projectVersionStatusUpdate(projectVersionId: $id) {
      ...SubmissionStatusBadgeFragment
    }
  }
`;

function Subscription({ id }: { id: string }) {
  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<SubmissionStatusBadgeSubscriptionType> => ({
        variables: { id },
        subscription: SubmissionStatusBadgeSubscription,
      }),
      [id],
    ),
  );
  return null;
}

interface Props extends PropsWithChildren {
  label?: React.ReactNode;
  subscribe?: boolean;
  projectVersion: SubmissionStatusBadgeFragment$key;
}

export default function SubmissionStatusBadge({
  label,
  children,
  subscribe,
  projectVersion: projectVersionFragment,
}: Props) {
  const { status, evaluation, id } = useFragment(
    SubmissionStatusBadgeFragment,
    projectVersionFragment,
  );

  const prefix = label ? <span>{label} |</span> : null;

  let message = null;
  if (typeof evaluation?.error === "string") {
    message = (
      <span>
        <FormattedMessage defaultMessage="Error" />
      </span>
    );
  } else if (typeof evaluation?.score === "number") {
    message = (
      <>
        <span>
          <FormattedMessage defaultMessage="Score" />:{" "}
          <span className="font-bold">
            <FormattedNumber value={evaluation.score} />
          </span>
        </span>
      </>
    );
  } else {
    message = (
      <span>
        <FormattedMessage defaultMessage="Pending" />
      </span>
    );
  }
  let color: NoteColor = "default";
  let icon = undefined;
  let info = null;
  const finalizedAt = evaluation?.finalizedAt
    ? new Date(evaluation.finalizedAt)
    : new Date();

  switch (status) {
    case "OK":
      info = (
        <>
          {evaluation?.max && (
            <p>
              <FormattedMessage defaultMessage="Project's best score!" />
            </p>
          )}
          <p suppressHydrationWarning>
            Evaluated{" "}
            <FormattedRelativeTime
              numeric="auto"
              value={(finalizedAt.getTime() - Date.now()) / 1000}
              updateIntervalInSeconds={60}
            />
          </p>
        </>
      );
      break;
    case "ERROR":
      color = "red";
      icon = <MdErrorOutline />;
      info = (
        <code
          className="whitespace-pre-line overflow-y-auto"
          style={{ maxHeight: "50vh" }}
        >
          {evaluation?.error}
        </code>
      );
      break;
    case "AWAITING_VALIDATION":
      color = "blue";
      icon = <MdHourglassBottom />;
      info = (
        <p>
          <FormattedMessage defaultMessage="Awaiting Validation" />
        </p>
      );
      break;
    case "AWAITING_EVALUATION":
      color = "blue";
      icon = <Loading kind="info" className="w-4" />;
      info = (
        <p>
          <FormattedMessage defaultMessage="Evaluating" />
        </p>
      );
      break;
    case "AWAITING_APPROVAL":
      color = "blue";
      icon = <MdHourglassBottom />;
      info = (
        <p>
          <FormattedMessage defaultMessage="Awaiting Approval" />
        </p>
      );
      break;
  }

  return (
    <>
      {subscribe && <Subscription id={id} />}
      <Popover.Root>
        <Popover.Trigger asChild>
          <Note prefix={prefix} color={color} icon={icon}>
            {message}
          </Note>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content className="border">
            {info}
            {children}
            <Popover.Arrow />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
}
