import { graphql } from "react-relay";
import { EventCompetitionsCarouselFragment$key } from "./__generated__/EventCompetitionsCarouselFragment.graphql";
import { usePaginationFragment } from "react-relay";
import CompetitionCard from "./CompetitionCard";

const EventCompetitionsCarouselFragment = graphql`
  fragment EventCompetitionsCarouselFragment on Event
  @refetchable(queryName: "EventCompetitionsCarouselFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    competitions(first: $count, after: $cursor)
      @connection(key: "EventCompetitionsCarouselFragment_competitions") {
      edges {
        node {
          id
          competition {
            ...CompetitionCardFragment
          }
        }
      }
    }
  }
`;

interface Props {
  event: EventCompetitionsCarouselFragment$key;
}

export default function EventCompetitionsCarousel({
  event: eventFragment,
}: Props) {
  const { data: event } = usePaginationFragment(
    EventCompetitionsCarouselFragment,
    eventFragment,
  );
  if (event.competitions.edges.length == 0) {
    return null;
  }
  return (
    <div className="overflow-x-auto flex flex-nowrap p-2">
      {event.competitions.edges.map(({ node }) => (
        <div
          key={node.id}
          className="w-1/2 max-sm:w-full flex-shrink-0 pl-6 first:pl-0"
        >
          <CompetitionCard key={node.id} competition={node.competition} />
        </div>
      ))}
    </div>
  );
}
