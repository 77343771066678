import { badges } from "../assets/badges";
import { FormattedMessage, useIntl } from "react-intl";
import { BadgeImage } from "../components/EntityBadge";
import { EntityBadgeWithdrawButton } from "../components/EntityBadgeWithdrawButton";
import Button from "../components/Button";
import { FaLinkedin } from "react-icons/fa";
import { FaCopy, FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { Badge } from "../components/__generated__/EntityBadgeListFragment.graphql";
import { MetaLayout } from "../common/MetaLayout";
import { useAuth } from "../utils/auth";
import * as Dialog from "./Dialog";
import { useLocation } from "../utils/location";
import {
  getLinkedInUrl,
  getTwitterUrl,
} from "../utils/generatedSocialSharingLink";

export interface EntityShareBadgeModalProps {
  entityId: ID;
  badge: Badge;
  username: string;
  isOpen: boolean;
  viewerCanWithdraw: boolean;
  badgeId: ID;
}

export function EntityShareBadgeModal({
  entityId,
  badge,
  isOpen,
  username,
  viewerCanWithdraw,
  badgeId,
}: Readonly<EntityShareBadgeModalProps>) {
  const location = useLocation();
  const { userId: viewerId } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const selectedBadge = badges[badge];

  const origin = location.origin;
  const currentUrl = location.href;

  const onCopy = async (evt: React.MouseEvent) => {
    if (!navigator.clipboard) return;
    evt.preventDefault();
    await navigator.clipboard.writeText(currentUrl);
    toast(
      intl.formatMessage({ defaultMessage: "Link copied into the clipboard!" }),
    );
  };

  const metaDescription = intl.formatMessage(
    {
      defaultMessage: "{name}: {description}",
    },
    {
      name: intl.formatMessage(selectedBadge.name),
      description: intl.formatMessage(selectedBadge.description),
    },
  );

  const metaTitle = intl.formatMessage(
    {
      defaultMessage: "{username} | {badgeName}",
    },
    {
      username,
      badgeName: intl.formatMessage(selectedBadge.name),
    },
  );

  const metaImageAlt = intl.formatMessage(
    {
      defaultMessage: "User badge: {badgeName}",
    },
    {
      badgeName: intl.formatMessage(selectedBadge.name),
    },
  );

  const injectedText = intl.formatMessage({
    defaultMessage: "I got a new Badge on Aqora!!",
  });

  const viewerIsOwner = viewerId === entityId;

  return (
    <MetaLayout
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      metaImageUrl={`${origin}${selectedBadge.image}`}
      metaImageAlt={metaImageAlt}
    >
      <Dialog.Root open={isOpen} onOpenChange={() => navigate("")}>
        <Dialog.Content>
          <div className="flex flex-col items-center relative">
            <div className="absolute top-0 right-0">
              <Dialog.Close.Desktop />
            </div>
            <div className="w-48 md:w-56 lg:w-64 xl:w-72 h-full mt-4">
              <BadgeImage src={selectedBadge.image} />
            </div>
            <div className="mt-8 gap-4 w-full px-4">
              <p className="font-bold text-lg">
                <FormattedMessage {...selectedBadge.name} />
              </p>
              <p>
                <FormattedMessage {...selectedBadge.description} />
              </p>
              <div className="flex flex-col gap-2 my-4">
                {viewerIsOwner && (
                  <div className="flex flex-col md:flex-row gap-2 mb-2">
                    <Button
                      to={getLinkedInUrl({
                        injectedText,
                        url: currentUrl,
                      })}
                      target="_blank"
                      kind="primary"
                      className="w-full flex flex-row justify-center items-center gap-2"
                    >
                      <FormattedMessage defaultMessage="Share on LinkedIn" />
                      <FaLinkedin />{" "}
                    </Button>
                    <Button
                      to={getTwitterUrl({
                        injectedText,
                        url: currentUrl,
                      })}
                      target="_blank"
                      kind="primary"
                      className="w-full flex flex-row justify-center items-center gap-2 "
                    >
                      <FormattedMessage defaultMessage="Share on Twitter" />
                      <FaXTwitter />
                    </Button>
                    <Button
                      kind="secondary"
                      className="w-full flex flex-row justify-center items-center gap-2"
                      onClick={onCopy}
                    >
                      <FormattedMessage defaultMessage="Copy link" />
                      <FaCopy />
                    </Button>
                  </div>
                )}
                {viewerCanWithdraw && (
                  <EntityBadgeWithdrawButton entityId={entityId} id={badgeId} />
                )}
                <Dialog.Close.Mobile className="mt-8">
                  <Button kind="secondary" className="w-full">
                    <FormattedMessage defaultMessage="Close" />
                  </Button>
                </Dialog.Close.Mobile>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Root>
    </MetaLayout>
  );
}
