import { useCompetitionCodeBlobPageQuery } from "./loaders/CompetitionCodeBlobPage";
import { FormattedMessage, useIntl } from "react-intl";
import FileBrowser from "../components/FileBrowser";
import FileViewer from "../components/FileBrowser/FileViewer";
import { MetaLayout } from "../common/MetaLayout";

export default function CompetitionCodeBlobPage() {
  const intl = useIntl();
  const {
    query,
    variables: { parentPath, slug, fileKind },
  } = useCompetitionCodeBlobPageQuery();
  const file = query.competitionBySlug?.useCase.latest?.fileByKind;
  const entry =
    query.competitionBySlug?.useCase.latest?.fileByKind?.browse.readMeta;
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{competitionTitle} | files | {fileKind}" },
        { competitionTitle: query.competitionBySlug?.title, fileKind },
      )}
    >
      <div className="space-y-6">
        {file && (
          <FileBrowser
            root={`/competitions/${slug}/code/${fileKind.toLowerCase()}`}
            file={file}
            path={parentPath || ""}
          />
        )}
        {entry?.kind == "FILE" ? (
          <FileViewer entry={entry} />
        ) : (
          <div className="text-center text-gray-500">
            <FormattedMessage defaultMessage="File not found" />
          </div>
        )}
      </div>
    </MetaLayout>
  );
}
