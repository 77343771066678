/**
 * @generated SignedSource<<f111c0bcdefda229da3c2e592c4149dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionApprovalPageSubscription$variables = {
  competitionId: string;
};
export type CompetitionApprovalPageSubscription$data = {
  readonly projectVersionStatusUpdate: {
    readonly project: {
      readonly latest: {
        readonly " $fragmentSpreads": FragmentRefs<"ApproveProjectVersionButtonFragment" | "SubmissionStatusBadgeFragment">;
      } | null | undefined;
    };
  };
};
export type CompetitionApprovalPageSubscription = {
  response: CompetitionApprovalPageSubscription$data;
  variables: CompetitionApprovalPageSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "competitionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "competitionId",
    "variableName": "competitionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionApprovalPageSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SubmissionStatusBadgeFragment"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ApproveProjectVersionButtonFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionApprovalPageSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProjectVersion",
        "kind": "LinkedField",
        "name": "projectVersionStatusUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "version",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectVersionEvaluation",
                    "kind": "LinkedField",
                    "name": "evaluation",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "score",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "error",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "finalizedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "max",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectVersionApproval",
                    "kind": "LinkedField",
                    "name": "approval",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9af42bcaafa5fffe7f99cb44a3418a2",
    "id": null,
    "metadata": {},
    "name": "CompetitionApprovalPageSubscription",
    "operationKind": "subscription",
    "text": "subscription CompetitionApprovalPageSubscription(\n  $competitionId: ID!\n) {\n  projectVersionStatusUpdate(competitionId: $competitionId) {\n    project {\n      __typename\n      latest {\n        ...SubmissionStatusBadgeFragment\n        ...ApproveProjectVersionButtonFragment\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ApproveProjectVersionButtonFragment on ProjectVersion {\n  id\n  approval {\n    id\n  }\n  ...SubmissionStatusBadgeFragment\n}\n\nfragment SubmissionStatusBadgeFragment on ProjectVersion {\n  id\n  status\n  version\n  evaluation {\n    score\n    error\n    finalizedAt\n    max\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e6821ec4887bdbafca1c30a8cd21b4ec";

export default node;
