/**
 * @generated SignedSource<<93b0c9a768670ae62874d5086ee96ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompetitionAcceptRulesButtonMutation$variables = {
  asEntity?: string | null | undefined;
  competition: string;
};
export type CompetitionAcceptRulesButtonMutation$data = {
  readonly agreeToCompetitionRule: {
    readonly competitionRule: {
      readonly competition: {
        readonly id: string;
        readonly latestRule: {
          readonly entityAgreement: {
            readonly createdAt: string;
            readonly id: string;
          } | null | undefined;
          readonly id: string;
        };
      };
    };
    readonly createdAt: string;
    readonly id: string;
  };
};
export type CompetitionAcceptRulesButtonMutation = {
  response: CompetitionAcceptRulesButtonMutation$data;
  variables: CompetitionAcceptRulesButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "asEntity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "competition"
},
v2 = [
  {
    "kind": "Variable",
    "name": "asEntity",
    "variableName": "asEntity"
  },
  {
    "kind": "Variable",
    "name": "competition",
    "variableName": "competition"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Competition",
  "kind": "LinkedField",
  "name": "competition",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CompetitionRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetitionRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionAcceptRulesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CompetitionRuleAgreement",
        "kind": "LinkedField",
        "name": "agreeToCompetitionRule",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "competitionRule",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionAcceptRulesButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CompetitionRuleAgreement",
        "kind": "LinkedField",
        "name": "agreeToCompetitionRule",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "competitionRule",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c208c7c9bf7ae501d44a9315a4e817fc",
    "id": null,
    "metadata": {},
    "name": "CompetitionAcceptRulesButtonMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionAcceptRulesButtonMutation(\n  $competition: ID!\n  $asEntity: UsernameOrID\n) {\n  agreeToCompetitionRule(competition: $competition, asEntity: $asEntity) {\n    id\n    createdAt\n    competitionRule {\n      competition {\n        id\n        latestRule {\n          id\n          entityAgreement {\n            id\n            createdAt\n          }\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55ebb18cb1bff06d81072086079dd2d3";

export default node;
