export default function Card({
  className,
  onClick,
  ...props
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={`rounded-md outline outline-1 outline-gray-300 shadow-md ${
        onClick ? "hover:cursor-pointer" : ""
      } ${className}`}
      onClick={onClick}
      {...props}
    />
  );
}
