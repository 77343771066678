import { useState } from "react";
import { useForm } from "react-hook-form";
import { graphql, useMutation } from "react-relay";
import { Link, useSearchParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { useLocation } from "../utils/location";

import { SignupPageUserMutation as SignupPageUserMutationType } from "./__generated__/SignupPageUserMutation.graphql";
import { useOnAuthCallback } from "../utils/auth";
import { isNotBannedUsername } from "../utils/validation";

import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import { logger } from "../common/logger";

const SignupPageUserMutation = graphql`
  mutation SignupPageUserMutation($input: SignupUserInput!) {
    signupUser(input: $input) {
      node {
        id
      }
    }
  }
`;

type FormData = {
  displayName: string;
  username: string;
  email: string;
  password: string;
};

export default function Signup() {
  const location = useLocation();
  const intl = useIntl();
  const [searchParams, _setSearchParams] = useSearchParams();
  const onAuthenticated = useOnAuthCallback();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<SignupPageUserMutationType>(SignupPageUserMutation);
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: data,
      },
      onError(error) {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not create account. Username or email may already exist. Check your credentials and try again.",
          }),
        );
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  });
  const errorMessages = {
    displayName: {
      required: intl.formatMessage({ defaultMessage: "Name is required" }),
      maxLength: intl.formatMessage({
        defaultMessage: "Name must be at most 50 characters",
      }),
    },
    username: {
      required: intl.formatMessage({ defaultMessage: "Username is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Username must be at least 3 characters",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Username must be at most 20 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage:
          "Username can only contain letters, numbers, and underscores",
      }),
      isNotBannedUsername: intl.formatMessage({
        defaultMessage: "Username not allowed",
      }),
    },
    email: {
      required: intl.formatMessage({ defaultMessage: "Email is required" }),
    },
    password: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
      minLength: intl.formatMessage({
        defaultMessage: "Password must be at least 6 characters",
      }),
    },
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col space-y-3">
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Name" })}
          error={
            typeof errors.displayName?.type === "string" &&
            errorMessages.displayName[
              errors.displayName.type as keyof typeof errorMessages.displayName
            ]
          }
        >
          <TextInput
            aria-invalid={errors.displayName ? "true" : "false"}
            {...register("displayName", {
              setValueAs: (value) => value.trim(),
              required: true,
              maxLength: 50,
            })}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Username" })}
          error={
            typeof errors.username?.type === "string" &&
            errorMessages.username[
              errors.username.type as keyof typeof errorMessages.username
            ]
          }
        >
          <TextInput
            prefix={location.host + "/"}
            aria-invalid={errors.username ? "true" : "false"}
            {...register("username", {
              required: true,
              setValueAs: (value) => value.trim(),
              minLength: 3,
              maxLength: 20,
              pattern: /^[a-zA-Z0-9_]*$/,
              validate: { isNotBannedUsername: isNotBannedUsername([]) },
            })}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Email" })}
          error={
            typeof errors.email?.type === "string" &&
            errorMessages.email[
              errors.email.type as keyof typeof errorMessages.email
            ]
          }
        >
          <TextInput
            type="email"
            aria-invalid={errors.email ? "true" : "false"}
            {...register("email", {
              required: true,
              setValueAs: (value) => value.trim(),
            })}
          />
        </FormGroup>
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Password" })}
          error={
            typeof errors.password?.type === "string" &&
            errorMessages.password[
              errors.password.type as keyof typeof errorMessages.password
            ]
          }
        >
          <TextInput
            type="password"
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", {
              required: true,
              minLength: 6,
            })}
          />
        </FormGroup>
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <p className="pt-3 text-sm text-gray-600">
          <FormattedMessage
            defaultMessage="By clicking Signup below you agree to the <terms>Terms & Conditions</terms> and the <privacy>Privacy Policy</privacy>"
            values={{
              terms: (chunks) => (
                <a href="http://aqora.io/terms" className="text-blue-400">
                  {chunks}
                </a>
              ),
              privacy: (chunks) => (
                <a
                  href="http://aqora.io/privacy-policy"
                  className="text-blue-400"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
        <div className="py-3 flex justify-between items-center">
          <p className="text-gray-600 pr-2">
            <FormattedMessage defaultMessage="Already have an account?" />
            {"  "}
            <Link
              to={{ pathname: "/login", search: searchParams.toString() }}
              className="text-blue-400"
            >
              <FormattedMessage defaultMessage="Login here" />
            </Link>
          </p>
          <Button type="submit" disabled={isMutationInFlight}>
            <FormattedMessage defaultMessage="Signup" />
          </Button>
        </div>
      </div>
    </form>
  );
}
