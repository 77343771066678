import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventCompetitionsPageQuery as EventCompetitionsPageQueryType } from "./__generated__/EventCompetitionsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventCompetitionsPageQuery as EventCompetitionsPageQueryType } from "./__generated__/EventCompetitionsPageQuery.graphql";

export const EventCompetitionsPageQuery = graphql`
  query EventCompetitionsPageQuery($slug: String!) {
    ...CompetitionAutocompleteFragment
    eventBySlug(slug: $slug) {
      id
      title
      viewerCanEditCompetitions: viewerCan(action: ADD_EVENT_COMPETITION)
      ...EventCompetitionsFragment
    }
  }
`;

export const eventCompetitionsPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventCompetitionsPageQueryType>(
      environment,
      EventCompetitionsPageQuery,
      { slug },
    );
  };
};

export const useEventCompetitionsPageQuery = () =>
  usePreloaded<EventCompetitionsPageQueryType>();
