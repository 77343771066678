/**
 * @generated SignedSource<<55a35e24a9beab3cdd194571b83bb83c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateAgendaInput = {
  agenda?: any | null | undefined;
};
export type EventTimetableEditPageMutation$variables = {
  eventId: string;
  input: UpdateAgendaInput;
};
export type EventTimetableEditPageMutation$data = {
  readonly updateEventAgenda: {
    readonly node: {
      readonly agenda: any | null | undefined;
      readonly id: string;
    };
  };
};
export type EventTimetableEditPageMutation = {
  response: EventTimetableEditPageMutation$data;
  variables: EventTimetableEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "eventId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "eventId",
        "variableName": "eventId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EventEdge",
    "kind": "LinkedField",
    "name": "updateEventAgenda",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "agenda",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTimetableEditPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventTimetableEditPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "886f65ec2564278b3bf00c116fa9edf7",
    "id": null,
    "metadata": {},
    "name": "EventTimetableEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation EventTimetableEditPageMutation(\n  $eventId: ID!\n  $input: UpdateAgendaInput!\n) {\n  updateEventAgenda(eventId: $eventId, input: $input) {\n    node {\n      id\n      agenda\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5c6f1e064103ec3209dbacbdbaf7bd50";

export default node;
