/**
 * @generated SignedSource<<8b61d8502d0fba42d6e8f82d65f32f5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateTopicInput = {
  description?: string | null | undefined;
  title?: string | null | undefined;
};
export type TopicEditPageMutation$variables = {
  id: string;
  input: UpdateTopicInput;
};
export type TopicEditPageMutation$data = {
  readonly updateTopic: {
    readonly competition: {
      readonly slug: string;
    } | null | undefined;
    readonly description: string | null | undefined;
    readonly forum: {
      readonly slug: string;
    } | null | undefined;
    readonly id: string;
    readonly title: string;
  };
};
export type TopicEditPageMutation = {
  response: TopicEditPageMutation$data;
  variables: TopicEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopicEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "updateTopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Competition",
            "kind": "LinkedField",
            "name": "competition",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopicEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Topic",
        "kind": "LinkedField",
        "name": "updateTopic",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Competition",
            "kind": "LinkedField",
            "name": "competition",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Forum",
            "kind": "LinkedField",
            "name": "forum",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c2be8949a460f4cca2162bf1b13134a5",
    "id": null,
    "metadata": {},
    "name": "TopicEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation TopicEditPageMutation(\n  $id: ID!\n  $input: UpdateTopicInput!\n) {\n  updateTopic(id: $id, input: $input) {\n    id\n    title\n    description\n    competition {\n      slug\n      id\n    }\n    forum {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33f4b84e17d561531784ea7c08e26fdb";

export default node;
