import { Helmet } from "react-helmet-async";
import { graphql, useFragment } from "react-relay";
import {
  TopicHelmetFragment$data,
  TopicHelmetFragment$key,
} from "./__generated__/TopicHelmetFragment.graphql";
import { LocationContextValue, useLocation } from "../utils/location";
import { getTopicParent, getTopicPath } from "../utils/routing";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

const Fragment = graphql`
  fragment TopicHelmetFragment on Topic {
    id
    title
    description
    createdAt
    votes
    author {
      username
      displayName
    }
    competition {
      slug
    }
    forum {
      slug
    }
    lastComments: comments(last: 10) {
      nodes {
        content
        createdAt
        votes
        author {
          username
          displayName
        }
      }
    }
  }
`;

export interface TopicHelmetProps {
  topic: TopicHelmetFragment$key;
}

export default function TopicHelmet(props: TopicHelmetProps) {
  const intl = useIntl();
  const topic = useFragment(Fragment, props.topic);
  const location = useLocation();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "Topic: {topicTitle}" },
        { topicTitle: topic.title },
      )}
      metaDescription={topic.description}
    >
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHtml: JSON.stringify(
              formatDiscussionForumPosting(topic, location),
            ),
          },
        ]}
      />
    </MetaLayout>
  );
}

function getEntityProfilePath(username: string) {
  return `/${username}`;
}

function formatDiscussionForumPosting(
  topic: TopicHelmetFragment$data,
  location: LocationContextValue,
) {
  const topicUrl = new URL(
    getTopicPath({
      topic: topic.id,
      title: topic.title,
      parent: getTopicParent(topic.competition?.slug, topic.forum?.slug),
    }),
    location.origin,
  ).toString();

  return {
    "@context": "https://schema.org",
    "@type": "DiscussionForumPosting",
    url: topicUrl,
    mainEntityOfPage: topicUrl,
    headline: topic.title,
    text: topic.description,
    author: {
      "@type": "Person",
      name: topic.author.displayName,
      url: new URL(
        getEntityProfilePath(topic.author.username),
        location.origin,
      ).toString(),
    },
    datePublished: topic.createdAt,
    interactionStatistic: {
      "@type": "InteractionCounter",
      interactionType: "https://schema.org/LikeAction",
      userInteractionCount: topic.votes,
    },
    comment: topic.lastComments.nodes.map((comment) => ({
      "@type": "Comment",
      text: comment.content,
      author: {
        "@type": "Person",
        name: comment.author.displayName,
        url: new URL(
          getEntityProfilePath(topic.author.username),
          location.origin,
        ).toString(),
      },
      datePublished: comment.createdAt,
      interactionStatistic: {
        "@type": "InteractionCounter",
        interactionType: "https://schema.org/LikeAction",
        userInteractionCount: comment.votes,
      },
    })),
  };
}
