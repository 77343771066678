import { FormattedMessage } from "react-intl";
import FileBrowser from "../components/FileBrowser";
import FileViewer from "../components/FileBrowser/FileViewer";
import { useEntitySubmissionCodeReadmePageQuery } from "./loaders/EntitySubmissionCodeReadmePage";

export default function EntitySubmissionCodeReadmePage() {
  const {
    query,
    variables: { slug, version = "latest", fileKind },
  } = useEntitySubmissionCodeReadmePageQuery();
  const submission = query.competitionBySlug?.submission;
  const username = submission?.entity.username;
  const file = submission?.version?.fileByKind;
  const readme = file?.browse.readMeta;
  return (
    <div className="space-y-6">
      {file && (
        <FileBrowser
          root={`/${username}/submissions/${slug}/${version}/code/${fileKind.toLowerCase()}`}
          file={file}
        />
      )}
      {readme?.kind == "FILE" ? (
        <FileViewer entry={readme} disableScroll={true} />
      ) : (
        <div className="text-center text-gray-500">
          <FormattedMessage defaultMessage="No README.md found" />
        </div>
      )}
    </div>
  );
}
