import { useState } from "react";
import {
  graphql,
  useFragment,
  useMutation,
  useLazyLoadQuery,
} from "react-relay";
import { useForm } from "react-hook-form";
import { CompetitionAcceptRulesButtonQuery as CompetitionAcceptRulesButtonQueryType } from "./__generated__/CompetitionAcceptRulesButtonQuery.graphql";
import { CompetitionAcceptRulesButtonCompetitionFragment$key } from "./__generated__/CompetitionAcceptRulesButtonCompetitionFragment.graphql";
import { CompetitionAcceptRulesButtonMutation as CompetitionAcceptRulesButtonMutationType } from "./__generated__/CompetitionAcceptRulesButtonMutation.graphql";
import FormGroup from "./FormGroup";
import { FormattedMessage, useIntl } from "react-intl";
import UserEntitiesAutocomplete from "./UserEntitiesAutocomplete";
import { toast } from "sonner";
import { MdCheckCircleOutline } from "react-icons/md";
import { ConfirmDialog } from "./ConfirmDialog";

const CompetitionAcceptRulesButtonQuery = graphql`
  query CompetitionAcceptRulesButtonQuery($competition: ID!) {
    viewer {
      id
      username
      entitiesHead: entities(
        first: 2
        permission: { to: CREATE_COMPETITION_RULE_AGREEMENT, on: $competition }
      ) {
        edges {
          node {
            id
          }
        }
      }
      ...UserEntitiesAutocompleteFragment
        @arguments(
          permission: {
            to: CREATE_COMPETITION_RULE_AGREEMENT
            on: $competition
          }
        )
    }
  }
`;

const CompetitionAcceptRulesButtonCompetitionFragment = graphql`
  fragment CompetitionAcceptRulesButtonCompetitionFragment on Competition {
    id
    latestRule {
      id
      entityAgreement @ifAllowed {
        id
        createdAt
      }
    }
  }
`;

const CompetitionAcceptRulesButtonMutation = graphql`
  mutation CompetitionAcceptRulesButtonMutation(
    $competition: ID!
    $asEntity: UsernameOrID
  ) {
    agreeToCompetitionRule(competition: $competition, asEntity: $asEntity) {
      id
      createdAt
      competitionRule {
        competition {
          id
          latestRule {
            id
            entityAgreement {
              id
              createdAt
            }
          }
        }
      }
    }
  }
`;

interface Props {
  competition: CompetitionAcceptRulesButtonCompetitionFragment$key;
}

export default function CompetitionAcceptRulesButton({
  competition: competitionFragment,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const competition = useFragment(
    CompetitionAcceptRulesButtonCompetitionFragment,
    competitionFragment,
  );
  const { viewer: user } =
    useLazyLoadQuery<CompetitionAcceptRulesButtonQueryType>(
      CompetitionAcceptRulesButtonQuery,
      {
        competition: competition.id,
      },
    );
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionAcceptRulesButtonMutationType>(
      CompetitionAcceptRulesButtonMutation,
    );

  const onSubmit = handleSubmit((data) => {
    if (isMutationInFlight) {
      return;
    }
    setFormError(undefined);
    commitMutation({
      variables: {
        competition: competition.id,
        asEntity: data.asEntity,
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage: "Could not accept the rules. Please try again.",
          }),
        );
      },
      onCompleted() {
        setDialogOpen((prev) => !prev);
        toast.success(
          intl.formatMessage({
            defaultMessage: "Competition rules accepted!",
          }),
        );
      },
    });
  });

  const showUserSelect =
    user.entitiesHead.edges.length > 1 ||
    user.entitiesHead.edges[0].node.id !== user.id;
  const errorMessages = {
    required: intl.formatMessage({
      defaultMessage: "Please enter a valid user or organization",
    }),
  };
  return (
    <ConfirmDialog
      open={dialogOpen}
      onOpenChange={setDialogOpen}
      onConfirm={onSubmit}
      title={<FormattedMessage defaultMessage="Accept the Rules" />}
      buttonMessage={<FormattedMessage defaultMessage="I Accept" />}
      ButtonIcon={
        competition.latestRule.entityAgreement && MdCheckCircleOutline
      }
      message={
        <FormattedMessage defaultMessage="By clicking on the button below, I accept the rules of the Competition" />
      }
    >
      {showUserSelect && (
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Accept for" })}
          error={
            typeof errors.asEntity?.type === "string" &&
            errorMessages[errors.asEntity.type as keyof typeof errorMessages]
          }
        >
          <UserEntitiesAutocomplete
            query={user}
            {...register("asEntity", { required: true })}
          />
        </FormGroup>
      )}
      {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
    </ConfirmDialog>
  );
}
