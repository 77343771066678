/**
 * @generated SignedSource<<7083bc744b93a42f852c559539bce6a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApproveProjectVersionButtonFragment$data = {
  readonly approval: {
    readonly id: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SubmissionStatusBadgeFragment">;
  readonly " $fragmentType": "ApproveProjectVersionButtonFragment";
};
export type ApproveProjectVersionButtonFragment$key = {
  readonly " $data"?: ApproveProjectVersionButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApproveProjectVersionButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApproveProjectVersionButtonFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProjectVersionApproval",
      "kind": "LinkedField",
      "name": "approval",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmissionStatusBadgeFragment"
    }
  ],
  "type": "ProjectVersion",
  "abstractKey": null
};
})();

(node as any).hash = "1da177316f59b94b02743b85b4787259";

export default node;
