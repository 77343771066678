/**
 * @generated SignedSource<<09af1ab6e236951643425323a37b06fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
import { FragmentRefs } from "relay-runtime";
export type CompetitionSetupButtonFragment$data = {
  readonly slug: string;
  readonly useCase: {
    readonly latest: {
      readonly fileByKind: {
        readonly kind: ProjectVersionFileKind;
        readonly " $fragmentSpreads": FragmentRefs<"ProjectDownloadButtonFragment">;
      } | null | undefined;
    } | null | undefined;
  };
  readonly " $fragmentType": "CompetitionSetupButtonFragment";
};
export type CompetitionSetupButtonFragment$key = {
  readonly " $data"?: CompetitionSetupButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionSetupButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": "TEMPLATE",
      "kind": "LocalArgument",
      "name": "kind"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetitionSetupButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UseCase",
      "kind": "LinkedField",
      "name": "useCase",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectVersion",
          "kind": "LinkedField",
          "name": "latest",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "kind",
                  "variableName": "kind"
                }
              ],
              "concreteType": "ProjectVersionFile",
              "kind": "LinkedField",
              "name": "fileByKind",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ProjectDownloadButtonFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};

(node as any).hash = "9c57d1734cade376bfc4a1d2b31c94dd";

export default node;
