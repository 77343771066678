import { graphql, useRefetchableFragment } from "react-relay";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Comment from "./Comment";
import { FocusedCommentFragment$key } from "./__generated__/FocusedCommentFragment.graphql";
import { FocusedCommentFragmentQuery } from "./__generated__/FocusedCommentFragmentQuery.graphql";
import { VotableOrder } from "../utils/votableOrder";
import { useOnChangeEffect } from "../utils/hooks";
import CommentDisplay from "./CommentDisplay";

const FocusedCommentFragment = graphql`
  fragment FocusedCommentFragment on Comment
  @refetchable(queryName: "FocusedCommentFragmentQuery")
  @argumentDefinitions(order: { type: "VotableOrder", defaultValue: HOT }) {
    ...CommentFragment @arguments(topLevel: true, order: $order)
    parent {
      id
      parent {
        id
      }
      ...CommentDisplayFragment
    }
  }
`;

interface Props {
  comment: FocusedCommentFragment$key;
  order: VotableOrder;
}

export default function FocusedComment({
  comment: commentFragment,
  order,
}: Props) {
  const [comment, refetch] = useRefetchableFragment<
    FocusedCommentFragmentQuery,
    FocusedCommentFragment$key
  >(FocusedCommentFragment, commentFragment);
  useOnChangeEffect(order, (order) =>
    refetch({ order }, { fetchPolicy: "store-and-network" }),
  );
  return (
    <>
      {comment.parent?.parent?.id && (
        <div className="pb-5 text-gray-400 underline">
          <Link to={`/comments/${comment.parent.id}`}>
            <FormattedMessage defaultMessage="View previous" />
          </Link>
        </div>
      )}

      {comment.parent ? (
        <CommentDisplay comment={comment.parent}>
          <Comment order={order} comment={comment} highlighted />
        </CommentDisplay>
      ) : (
        <Comment order={order} comment={comment} highlighted />
      )}
    </>
  );
}
