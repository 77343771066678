import React from "react";
import Loading from "../components/Loading";

export default function Suspense({ children }: React.PropsWithChildren) {
  return (
    <React.Suspense
      fallback={
        <div
          className="w-full h-full flex items-center justify-center"
          style={
            {
              "--from-opacity": 0,
              "--to-opacity": 1,
              animation: "fade 0.2s ease-in",
            } as React.CSSProperties
          }
        >
          <div className="w-12">
            <Loading />
          </div>
        </div>
      }
    >
      {children}
    </React.Suspense>
  );
}
