import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EventScaffoldQuery as EventScaffoldQueryType } from "./__generated__/EventScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventScaffoldQuery as EventScaffoldQueryType } from "./__generated__/EventScaffoldQuery.graphql";

export const EventScaffoldQuery = graphql`
  query EventScaffoldQuery($slug: String!) {
    viewer @ifAllowed {
      id
    }
    eventBySlug(slug: $slug) {
      id
      slug
      title
      shortDescription
      description
      thumbnail
      createdAt
      banner
      viewerCanEdit: viewerCan(action: UPDATE_EVENT)
      viewerCanAddMember: viewerCan(action: ADD_EVENT_MEMBER)
      viewerCanAddCompetition: viewerCan(action: ADD_EVENT_COMPETITION)
      isPrivate
      host {
        id
        displayName
        username
        ...EntityProfilePicFragment @arguments(thumbnail: true)
      }
    }
  }
`;

export const eventScaffoldQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventScaffoldQueryType>(environment, EventScaffoldQuery, {
      slug,
    });
  };
};

export const useEventScaffoldQuery = () =>
  usePreloaded<EventScaffoldQueryType>();
