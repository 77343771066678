/**
 * @generated SignedSource<<9413d99bfd8c17541584b6a48b0514a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ApproveProjectVersionButtonDeleteMutation$variables = {
  projectVersionApprovalId: string;
};
export type ApproveProjectVersionButtonDeleteMutation$data = {
  readonly deleteProjectVersionApproval: string;
};
export type ApproveProjectVersionButtonDeleteMutation = {
  response: ApproveProjectVersionButtonDeleteMutation$data;
  variables: ApproveProjectVersionButtonDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectVersionApprovalId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "projectVersionApprovalId",
    "variableName": "projectVersionApprovalId"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "deleteProjectVersionApproval",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApproveProjectVersionButtonDeleteMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApproveProjectVersionButtonDeleteMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "deleteRecord",
        "key": "",
        "kind": "ScalarHandle",
        "name": "deleteProjectVersionApproval"
      }
    ]
  },
  "params": {
    "cacheID": "58e422634c539712814623e37bf2ff16",
    "id": null,
    "metadata": {},
    "name": "ApproveProjectVersionButtonDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ApproveProjectVersionButtonDeleteMutation(\n  $projectVersionApprovalId: ID!\n) {\n  deleteProjectVersionApproval(projectVersionApprovalId: $projectVersionApprovalId)\n}\n"
  }
};
})();

(node as any).hash = "ade1a4e110e8afd3f6dec13c9b39adf3";

export default node;
