import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useTopicPageQuery } from "./loaders/TopicPage";
import ErrorPage from "./ErrorPage";
import Button from "../components/Button";
import Markdown from "../components/Markdown";
import TopicComments from "../components/TopicComments";
import TopicCommentsForm from "../components/TopicCommentsForm";
import VoteDisplay from "../components/VoteDisplay";
import SubjectSubscriptionButton from "../components/SubjectSubscriptionButton";
import Suspense from "../common/Suspense";
import VotableOrderSelect from "../components/VotableOrderSelect";
import { VotableOrder } from "../utils/votableOrder";
import TopicHelmet from "../components/TopicHelmet";
import EntityProfilePic from "../components/EntityProfilePic";
import SignupCallout from "../components/SignupCallout";
import { getTopicParent, getTopicPath } from "../utils/routing";
import Note from "../components/Note";
import { TimeAgo } from "../components/TimeAgo";
import { SocialSharingDropdown } from "../components/SocialSharingDropdown";
import { MdOpenInNew } from "react-icons/md";
import { useExpectedLocationPath } from "../utils/hooks";

export default function TopicPage() {
  const intl = useIntl();
  const {
    query: { node: topic, viewer },
    variables: { order: initialOrder },
  } = useTopicPageQuery();
  const [order, setOrder] = useState<VotableOrder>(initialOrder);

  const topicPath = getTopicPath({
    topic: topic.id!,
    title: topic.title!,
    parent: getTopicParent(topic.competition?.slug, topic.forum?.slug),
  });
  useExpectedLocationPath(topicPath);

  if (!topic || !topic.author) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Data set not found",
        })}
      />
    );
  }

  const editLink = `${topicPath}/edit`;
  return (
    <>
      <TopicHelmet topic={topic} />
      <div className="pt-2 pb-8">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-2 items-center">
            <EntityProfilePic entity={topic.author} size="10" />
            <Link
              to={`/${topic.author.username}`}
              className="underline text-gray-400"
            >
              {topic.author.username}
            </Link>
            <VoteDisplay subject={topic} kind="vertical" />
          </div>

          <div className="flex flex-col gap-4 flex-1">
            {topic.createdAt && (
              <h3 className="text-sm">
                <FormattedMessage
                  defaultMessage="Posted {createdAt}"
                  values={{
                    createdAt: (
                      <TimeAgo createdAt={new Date(topic.createdAt)} />
                    ),
                  }}
                />
              </h3>
            )}

            <div className="flex flex-row gap-4 items-center justify-between flex-wrap">
              {topic.url ? (
                <Link
                  to={topic.url}
                  target="_blank"
                  className="flex flex-wrap items-center gap-1"
                >
                  <h1 className="text-2xl font-bold font-poppins">
                    {topic.title}
                  </h1>
                  {topic.url && (
                    <Note icon={<MdOpenInNew />} className="text-xs">
                      <FormattedMessage defaultMessage="External link" />
                    </Note>
                  )}
                </Link>
              ) : (
                <h1 className="text-2xl font-bold font-poppins">
                  {topic.title}
                </h1>
              )}

              <div className="flex flex-row items-center gap-6">
                <SubjectSubscriptionButton subject={topic} />

                <SocialSharingDropdown
                  title={intl.formatMessage(
                    { defaultMessage: "Sharing {topicTitle}" },
                    { topicTitle: topic.title },
                  )}
                  injectedText={intl.formatMessage(
                    {
                      defaultMessage:
                        "Let's take look to: `{topicTitle}` on Aqora",
                    },
                    { topicTitle: topic.title },
                  )}
                />

                {topic.viewerCanEdit && (
                  <Button kind="text" to={editLink}>
                    <FormattedMessage defaultMessage="Edit" />
                  </Button>
                )}
              </div>
            </div>

            <div className="pt-5 pb-10">
              {topic.description ? (
                <Markdown>{topic.description}</Markdown>
              ) : (
                <p className="text-gray-400">
                  <FormattedMessage defaultMessage="No description" />
                </p>
              )}
            </div>
          </div>
        </div>

        <hr />

        <div className="py-5">
          <TopicCommentsForm topic={topic} viewer={viewer} order={order} />
          <div>
            <FormattedMessage defaultMessage="Order by:" />{" "}
            <VotableOrderSelect value={order} onChange={setOrder} />
          </div>
          <Suspense>
            <TopicComments topic={topic} order={order} />
          </Suspense>
        </div>

        <SignupCallout />
      </div>
    </>
  );
}
