import { useIntl } from "react-intl";
import { graphql, usePaginationFragment } from "react-relay";
import CommentDisplay from "../components/CommentDisplay";

import LoadMore from "../components/LoadMore";
import TopicListItem from "../components/TopicListItem";
import { serialGroupBy } from "../utils/helpers";
import ErrorPage from "./ErrorPage";
import { useUserCommentsPageQuery } from "./loaders/UserCommentsPage";
import { UserCommentsPageFragment$key } from "./__generated__/UserCommentsPageFragment.graphql";

const UserCommentsPageFragment = graphql`
  fragment UserCommentsPageFragment on User
  @refetchable(queryName: "UserCommentsPageFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    comments(first: $count, after: $cursor, order: NEWEST)
      @connection(key: "UserCommentsPageFragment_comments") {
      edges {
        node {
          id
          topic @ifAllowed {
            id
            ...TopicListItemFragment
          }
          ...CommentDisplayFragment
        }
      }
    }
  }
`;

export default function UserCommentsPage() {
  const intl = useIntl();
  const {
    variables,
    query: { entityByUsername: user },
  } = useUserCommentsPageQuery();

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment(
    UserCommentsPageFragment,
    user as UserCommentsPageFragment$key,
  );

  if (!data) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }

  const comments = data.comments.edges.map((edge) => edge.node);

  const commentsByTopic = serialGroupBy(
    comments ?? [],
    (comment) => comment.topic,
    (a, b) => a?.id === b?.id,
  );

  return (
    <div className="flex flex-col gap-4">
      {commentsByTopic.map(([topic, comments]) =>
        topic ? (
          <div key={topic.id} className="flex flex-col gap-2">
            <TopicListItem topic={topic} />
            <div className="flex flex-col gap-1 ms-8">
              {comments.map((comment) => (
                <div key={comment.id} className="p-2 bg-gray-50 rounded">
                  <CommentDisplay comment={comment} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div key="unknown" className="flex flex-col gap-2">
            <p className="text-gray-400">
              {intl.formatMessage({
                defaultMessage: "[Private Discussions]",
              })}
            </p>
            <div className="flex flex-col gap-1 ms-8">
              {comments.map((comment) => (
                <div key={comment.id} className="p-2 bg-gray-50 rounded">
                  <CommentDisplay comment={comment} />
                </div>
              ))}
            </div>
          </div>
        ),
      )}
      <LoadMore
        hasMore={hasNext}
        loadMore={loadNext}
        isLoading={isLoadingNext}
      />
      {commentsByTopic.length === 0 && (
        <p className="text-gray-400">No comments</p>
      )}
    </div>
  );
}
