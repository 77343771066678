import { PropsWithChildren as Props } from "react";
import { useReducer, useEffect, useMemo } from "react";
import {
  AuthContext,
  AuthContextValue,
  jwtClaims,
  TokenClaims,
  ACCESS_TOKEN_COOKIE_NAME,
} from "../../utils/auth";

const STORAGE_KEYS = {
  access: "aqora_access_token",
  refresh: "aqora_refresh_token",
};

interface Token {
  token: string;
  source: "localStorage" | "cookie";
  claims: TokenClaims;
}

interface AuthState {
  access?: Token;
  refresh?: Token;
  tick: number;
}

enum AuthActionKind {
  SetTokens,
  RevokeAllTokens,
  RevokeToken,
  Tick,
}

interface AuthAction {
  kind: AuthActionKind;
}

interface SetTokensAction extends AuthAction {
  kind: AuthActionKind.SetTokens;
  access?: string;
  refresh?: string;
}

interface RevokeAllTokensAction extends AuthAction {
  kind: AuthActionKind.RevokeAllTokens;
}

interface RevokeTokenAction extends AuthAction {
  kind: AuthActionKind.RevokeToken;
  token_type: "access" | "refresh";
}

interface TickAuthAction extends AuthAction {
  kind: AuthActionKind.Tick;
}

const decodeToken = (
  token: string,
  source: "localStorage" | "cookie",
): Token => {
  return { token, source, claims: jwtClaims(token) };
};

const isValidToken = (token: Token): boolean => {
  if (Date.now() >= token.claims.exp * 1000) {
    return false;
  }
  return true;
};

const setToken = (token: Token) => {
  localStorage.setItem(STORAGE_KEYS[token.claims.typ], token.token);
};

const removeToken = (token_type: "refresh" | "access"): void => {
  localStorage.removeItem(STORAGE_KEYS[token_type]);
  if (token_type === "access") {
    deleteCookie(ACCESS_TOKEN_COOKIE_NAME);
  }
};

const getToken = (token_type: "refresh" | "access"): Token | undefined => {
  let rawToken = localStorage.getItem(STORAGE_KEYS[token_type]);
  let source: "localStorage" | "cookie" = "localStorage";
  if (!rawToken) {
    if (token_type === "access") {
      const cookie = getCookie(ACCESS_TOKEN_COOKIE_NAME);
      if (cookie) {
        rawToken = cookie;
        source = "cookie";
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
  const token = decodeToken(rawToken, source);
  if (!isValidToken(token)) {
    removeToken(token_type);
    return undefined;
  }
  return token;
};

const initAuthState = (): AuthState => {
  return {
    access: getToken("access"),
    refresh: getToken("refresh"),
    tick: 0,
  };
};

const topLevelDomain = () =>
  window.location.hostname.split(".").slice(-2).join(".");

const getCookie = (name: string): string | undefined =>
  document.cookie
    .split(";")
    .find((c) => c.trim().startsWith(name + "="))
    ?.split("=")[1];

const deleteCookie = (name: string) => {
  if (getCookie(name)) {
    document.cookie = `${name}=; domain=${topLevelDomain()}; path=/; secure; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
};

const authReducer = (
  { access, refresh, tick }: AuthState,
  action: AuthAction,
): AuthState => {
  if (action.kind == AuthActionKind.SetTokens) {
    const setTokensAction = action as SetTokensAction;
    if (setTokensAction.access) {
      const decodedAccess = decodeToken(setTokensAction.access, "localStorage");
      if (isValidToken(decodedAccess)) {
        access = decodedAccess;
        setToken(access);
      }
    }
    if (setTokensAction.refresh) {
      const decodedRefresh = decodeToken(
        setTokensAction.refresh,
        "localStorage",
      );
      if (isValidToken(decodedRefresh)) {
        refresh = decodedRefresh;
        setToken(refresh);
      }
    }
    return { access, refresh, tick };
  } else if (action.kind == AuthActionKind.RevokeAllTokens) {
    removeToken("access");
    removeToken("refresh");
    return { tick };
  } else if (action.kind == AuthActionKind.RevokeToken) {
    const { token_type } = action as RevokeTokenAction;
    if (token_type == "access") {
      removeToken("access");
      access = undefined;
    }
    if (token_type == "refresh") {
      removeToken("refresh");
      refresh = undefined;
    }
    return { access, refresh, tick };
  } else if (action.kind == AuthActionKind.Tick) {
    return { access, refresh, tick: tick + 1 };
  } else {
    return { access, refresh, tick };
  }
};

const useExpiration = (
  token: Token | undefined,
  tick: number,
  dispatch: React.Dispatch<AuthAction>,
) => {
  useEffect(() => {
    if (token) {
      const timeout = setTimeout(
        () => {
          if (isValidToken(token)) {
            dispatch({
              kind: AuthActionKind.Tick,
            } as TickAuthAction);
          } else {
            dispatch({
              kind: AuthActionKind.RevokeToken,
              token_type: token.claims.typ,
            } as RevokeTokenAction);
          }
        },
        Math.min(token.claims.exp * 1000 - Date.now(), 1000 * 60 * 60 * 24),
      );
      return () => clearTimeout(timeout);
    }
  }, [token, dispatch, tick]);
};

export default function AuthProvider({ children }: Props) {
  const [{ access, refresh, tick }, dispatch] = useReducer(
    authReducer,
    initAuthState(),
  );
  useExpiration(access, tick, dispatch);
  useExpiration(refresh, tick, dispatch);

  const token = useMemo(() => {
    if (access) {
      if (access.source === "cookie" && refresh) {
        return refresh;
      } else {
        return access;
      }
    } else {
      return refresh;
    }
  }, [access, refresh]);

  const value: AuthContextValue = {
    getToken: () => token?.token,
    setTokens: ({ access, refresh }) =>
      dispatch({
        kind: AuthActionKind.SetTokens,
        access,
        refresh,
      } as SetTokensAction),
    revokeToken: (token_type: "access" | "refresh") => {
      dispatch({
        kind: AuthActionKind.RevokeToken,
        token_type,
      } as RevokeTokenAction);
    },
    revokeTokens: () =>
      dispatch({
        kind: AuthActionKind.RevokeAllTokens,
      } as RevokeAllTokensAction),
    isAuthenticated: !!token,
    userId: token?.claims.sub,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
