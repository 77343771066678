import {
  ConnectionHandler,
  graphql,
  useFragment,
  useMutation,
} from "react-relay";
import {
  EntityBadgeAwardFormMutation,
  Badge,
} from "./__generated__/EntityBadgeAwardFormMutation.graphql";
import { Command } from "cmdk";
import { badges } from "../assets/badges";
import { FormattedMessage, useIntl } from "react-intl";
import { EntityBadgeListFragment$key } from "./__generated__/EntityBadgeListFragment.graphql";
import { EntityBadgeListFragment } from "./EntityBadgeList";
import { toast } from "sonner";
import { logger } from "../common/logger";

const Mutation = graphql`
  mutation EntityBadgeAwardFormMutation(
    $entityId: ID!
    $badge: Badge!
    $connections: [ID!]!
  ) {
    awardBadge(entityId: $entityId, badge: $badge)
      @prependEdge(connections: $connections) {
      node {
        id
        ...EntityBadgeFragment
      }
    }
  }
`;

export interface EntityBadgeAwardFormProps {
  entity: EntityBadgeListFragment$key;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export function EntityBadgeAwardForm(props: EntityBadgeAwardFormProps) {
  const intl = useIntl();
  const entity = useFragment(EntityBadgeListFragment, props.entity);
  const [mutate, mutating] =
    useMutation<EntityBadgeAwardFormMutation>(Mutation);
  const entityBadges = new Set(
    entity.badges.edges.map(({ node }) => node.badge),
  );

  const onAward = (badge: Badge) =>
    mutate({
      variables: {
        badge,
        entityId: entity.id,
        connections: [
          entity.badges.__id,
          ConnectionHandler.getConnectionID(
            entity.id,
            "EntityBadgeList_entity_badges",
          ),
        ],
      },
      onCompleted: () => {
        props.onSubmit?.();
        toast.success(
          intl.formatMessage({
            defaultMessage: "Badge was successfully awarded!",
          }),
        );
      },
      onError: (error) => {
        logger.error(error);
        toast.error(
          intl.formatMessage({
            defaultMessage:
              "There was an error while awarding this badge: please try again later...",
          }),
        );
      },
    });

  return (
    <div className="flex flex-col gap-2">
      <Command
        defaultValue={intl.formatMessage(Object.values(badges)[0]?.name)}
        loop
        className="flex flex-col gap-2 outline-none"
      >
        <Command.Input
          autoFocus
          disabled={mutating}
          className="flex flex-row items-center border border-gray-300 rounded px-4 py-2 focus:ring-2 focus:ring-grey"
          placeholder={intl.formatMessage({
            defaultMessage: "Enter badge name here and press Enter",
          })}
        />

        <Command.List className="max-h-96 overflow-scroll">
          <Command.Empty>
            <p className="italic text-gray-600">
              <FormattedMessage defaultMessage="No results..." />
            </p>
          </Command.Empty>

          {Object.values(badges)
            .filter((badge) => !entityBadges.has(badge.id))
            .map((badge) => (
              <Command.Item
                key={badge.id}
                className="flex flex-row gap-2 items-center justify-start p-2 rounded hover:bg-slate-50 hover:text-slate-900 data-[selected=true]:bg-slate-50 data-[selected=true]:text-slate-900 cursor-pointer"
                value={intl.formatMessage(badge.name)}
                onSelect={onAward.bind(null, badge.id)}
                data-disabled={mutating}
              >
                <img
                  src={badge.image}
                  alt={intl.formatMessage({ defaultMessage: "Badge Image" })}
                  className="rounded-full w-16 border border-gray-600 bg-white"
                />

                <div className="flex flex-col gap-1">
                  <strong>
                    <FormattedMessage {...badge.name} />
                  </strong>

                  <p>
                    <FormattedMessage {...badge.description} />
                  </p>
                </div>
              </Command.Item>
            ))}
        </Command.List>
      </Command>
    </div>
  );
}
