import { FieldValues } from "react-hook-form";
import { MarkdownEditorContext, MarkdownEditorContextProps } from "./context";
import { PropsWithChildren } from "react";

interface MarkdownEditorProviderProps<TFieldValues extends FieldValues>
  extends PropsWithChildren {
  value: MarkdownEditorContextProps<TFieldValues>;
}

export const MarkdownEditorProvider = <TFieldValues extends FieldValues>({
  children,
  value,
}: MarkdownEditorProviderProps<TFieldValues>) => {
  return (
    <MarkdownEditorContext.Provider value={value}>
      {children}
    </MarkdownEditorContext.Provider>
  );
};
