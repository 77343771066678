/**
 * @generated SignedSource<<baa0593eae47a01591b9d87b748f175e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationKind = "AWARD_BADGE" | "CONTENT_MENTIONED" | "CREATE_SUBMISSION" | "CREATE_TOPIC" | "REPLY_TOPIC" | "SYSTEM";
export type UnsubscribePageMutation$variables = {
  token: string;
};
export type UnsubscribePageMutation$data = {
  readonly notification: NotificationKind | null | undefined;
};
export type UnsubscribePageMutation = {
  response: UnsubscribePageMutation$data;
  variables: UnsubscribePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": "notification",
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "kind": "ScalarField",
    "name": "unsubscribeNotificationForToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnsubscribePageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnsubscribePageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "55d4c9d4759a4dc27378b36d1b11e73f",
    "id": null,
    "metadata": {},
    "name": "UnsubscribePageMutation",
    "operationKind": "mutation",
    "text": "mutation UnsubscribePageMutation(\n  $token: String!\n) {\n  notification: unsubscribeNotificationForToken(token: $token)\n}\n"
  }
};
})();

(node as any).hash = "9379b9ac8356026497654b71f18712ac";

export default node;
