export const isEmptyObject = (obj: object) => {
  for (const value of Object.values(obj)) {
    if (value !== undefined) {
      return false;
    }
  }
  return true;
};

export function serialGroupBy<A, B>(
  values: readonly A[],
  keyOf: (a: A) => B,
  sameKey: (one: B, two: B) => boolean,
): [B, A[]][] {
  const groups: [B, A[]][] = [];
  let last: [B, A[]] | undefined;
  for (const value of values) {
    const key = keyOf(value);
    if (!last || !sameKey(last[0], key)) {
      last = [key, []];
      groups.push(last);
    }
    last[1].push(value);
  }
  return groups;
}

export function truthOrFail<A>(
  value: A | null | undefined,
  message = "Unexpected value",
): A {
  if (value === null || typeof value === "undefined") {
    throw new Error(message);
  }
  return value;
}

export function assertNever(x: never): never {
  throw new Error(`Unexpected value: ${x}`);
}

export function intersperse<A>(a: (index: number) => A, xs: A[]): A[] {
  const result = [];
  for (const x of xs) {
    if (result.length > 0) {
      result.push(a(result.length));
    }
    result.push(x);
  }
  return result;
}
