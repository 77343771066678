import React, { type HTMLAttributeAnchorTarget } from "react";
import clsx, { ClassValue } from "clsx";
import { Link, To, LinkProps } from "react-router-dom";

export type ButtonSize = "sm" | "md" | "lg";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  kind?: "primary" | "secondary" | "text" | "danger" | "success";
  size?: ButtonSize;
  to?: To;
  state?: LinkProps["state"];
  target?: HTMLAttributeAnchorTarget;
}

const DEFAULT_DISABLED_STYLE = "bg-gray-200 text-gray-400";

const styles = {
  primary: "bg-indigo hover:bg-indigo-500 text-white",
  primary_disabled: DEFAULT_DISABLED_STYLE,
  danger: "bg-red-600 hover:bg-red-500 text-white",
  danger_disabled: DEFAULT_DISABLED_STYLE,
  success: "bg-green-600 hover:bg-green-500 text-white",
  success_disabled: DEFAULT_DISABLED_STYLE,
  secondary:
    "bg-gray-100 hover:bg-gray-50 text-gray-950 hover:text-gray-800 outline outline-1 outline-gray-400",
  secondary_disabled: DEFAULT_DISABLED_STYLE,
  text: "bg-transparent text-gray-950 hover:text-gray-800",
  text_disabled: "text-gray-400",
};

const sizes = {
  sm: "px-2 py-1 text-sm rounded",
  md: "px-4 py-2 text-md rounded",
  lg: "px-6 py-3 text-lg rounded-md",
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    disabled = false,
    kind = "primary",
    size = "md",
    type = "button",
    className,
    to,
    state,
    target,
    onClick,
    ...rest
  },
  ref,
) {
  const buttonStyle = disabled ? styles[`${kind}_disabled`] : styles[kind];
  const button = (
    <button
      ref={ref}
      className={`${buttonStyle} ${sizes[size]} transition-colors whitespace-nowrap ${className}`}
      disabled={disabled}
      type={type}
      onClick={onClick}
      {...rest}
    />
  );

  if (to) {
    return (
      <Link to={to} target={target} state={state}>
        {button}
      </Link>
    );
  }

  return button;
});
export default Button;

export interface ToolbarButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: ButtonSize | undefined;
}

export const ToolbarButton = React.forwardRef<
  HTMLButtonElement,
  ToolbarButtonProps
>(({ className, size = "lg", disabled, ...props }, ref) => (
  <button
    ref={ref}
    disabled={disabled}
    {...props}
    className={clsx(
      className,
      toolbarButtonSizes[size],
      disabled
        ? "cursor-not-allowed"
        : "border bg-gray-50 hover:bg-slate-100 hover:border-gray-300",
      "rounded-full p-2",
    )}
  />
));

const toolbarButtonSizes: { [K in ButtonSize]: ClassValue } = {
  sm: [],
  md: ["text-lg"],
  lg: ["text-2xl"],
};
