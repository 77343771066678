import { graphql, useFragment } from "react-relay";
import { CompetitionCardFragment$key } from "./__generated__/CompetitionCardFragment.graphql";
import ActivityCard from "./ActivityCard";

const CompetitionCardFragment = graphql`
  fragment CompetitionCardFragment on Competition {
    id
    slug
    title
    shortDescription
    thumbnail
    host {
      ...ActivityCardHostFragment
    }
    tags {
      ...ActivityCardTagsFragment
    }
  }
`;

interface Props {
  competition: CompetitionCardFragment$key;
}

export default function CompetitionCard({
  competition: competitionFragment,
}: Props) {
  const { title, shortDescription, thumbnail, slug, host, tags } = useFragment(
    CompetitionCardFragment,
    competitionFragment,
  );
  return (
    <ActivityCard
      title={title}
      shortDescription={shortDescription}
      thumbnail={thumbnail}
      url={`/competitions/${slug}`}
      host={host}
      tags={tags}
    />
  );
}
