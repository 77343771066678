import pino from "pino";

const level = import.meta.env.PINO_LOG_LEVEL ?? "info";

export const logger = pino({
  level,
  browser: {
    asObject: import.meta.env.SSR,
  },
});
