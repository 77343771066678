import { graphql, usePaginationFragment } from "react-relay";
import { ForumsFragment$key } from "./__generated__/ForumsFragment.graphql";
import { FormattedMessage } from "react-intl";
import Button from "./Button";
import Forum from "./Forum";

const ForumsFragment = graphql`
  fragment ForumsFragment on Query
  @refetchable(queryName: "ForumsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
  ) {
    viewer @ifAllowed {
      canCreateForum: can(action: CREATE_FORUM)
    }
    forums(after: $cursor, first: $count)
      @connection(key: "ForumsFragment_forums") {
      edges {
        node {
          id
          ...ForumFragment
        }
      }
    }
  }
`;

interface Props {
  query: ForumsFragment$key;
}

export default function Forums({ query }: Props) {
  const { data /* loadNext, hasNext */ } = usePaginationFragment(
    ForumsFragment,
    query,
  );
  if (data.forums.edges.length === 0 && !data.viewer?.canCreateForum) {
    return null;
  }
  return (
    <div className="space-y-6">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold font-poppins">
          <FormattedMessage defaultMessage="Discussions" />
        </h1>
        <div className="w-full" />
        {data.viewer?.canCreateForum && (
          <Button kind="primary" to="/discussions/new">
            <FormattedMessage defaultMessage="Create Forum" />
          </Button>
        )}
      </div>
      {data.forums.edges.length > 0 ? (
        <div className="flex flex-col space-y-8">
          {data.forums.edges.map(({ node: forum }) => {
            return <Forum key={forum.id} forum={forum} />;
          })}
        </div>
      ) : (
        <div className="text-gray-400">
          <FormattedMessage defaultMessage="No discussions yet" />
        </div>
      )}
    </div>
  );
}
