import { LoadMoreFn } from "react-relay";
import { OperationType } from "relay-runtime";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

interface Props<T extends OperationType> {
  loadMore: LoadMoreFn<T>;
  count?: number;
  onComplete?: ((arg: Error | null) => void) | undefined;
  hasMore: boolean;
  isLoading: boolean;
  children?: React.ReactNode;
}

export default function LoadMore<T extends OperationType>({
  loadMore,
  count = 10,
  onComplete,
  hasMore,
  isLoading,
  children,
}: Props<T>) {
  if (!hasMore) {
    return null;
  }
  return (
    <Button
      kind="text"
      disabled={isLoading}
      onClick={() => loadMore(count, { onComplete })}
    >
      {children || <FormattedMessage defaultMessage="Load More" />}
    </Button>
  );
}
