import { graphql, useFragment } from "react-relay";
import { EntityBadgeFragment$key } from "./__generated__/EntityBadgeFragment.graphql";
import React from "react";
import { badges } from "../assets/badges";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const EntityBadgeFragment = graphql`
  fragment EntityBadgeFragment on EntityBadge {
    badge
  }
`;

export interface EntityBadgeProps extends React.PropsWithChildren {
  badge: EntityBadgeFragment$key;
}

export function EntityBadge(props: EntityBadgeProps) {
  const { badge: badgeId } = useFragment(EntityBadgeFragment, props.badge);
  const badge = badges[badgeId];
  if (!badge) return null;

  return (
    <Link to={`?badge=${badge.id}`}>
      <div className="cursor-pointer w-36 flex flex-col gap-1 items-center justify-center bg-gray-100 text-gray-800 hover:bg-slate-100 hover:text-slate-500 rounded px-4 py-2">
        <BadgeImage src={badge.image} />
        <strong>
          <FormattedMessage {...badge.name} />
        </strong>
      </div>
    </Link>
  );
}

export interface BadgeImageProps {
  src: string;
}

export const BadgeImage = ({ src }: BadgeImageProps) => (
  <img
    src={src}
    alt="Badge image"
    className="rounded-full border border-gray-300 bg-white"
  />
);
