import React, { useRef, useState } from "react";
import TextInput from "./TextInput";
import { Spread } from "../utils/types";
import { debounce } from "lodash";
import DropdownList from "./DropdownList";
import DropdownItem from "./DropdownItem";
import { nativeInputValueSet } from "../utils/html";

export interface Node {
  id: string;
  value: string;
}

export interface RefetchArgs {
  search: string;
}

export interface PrefixProps {
  isValid: boolean;
}

export interface DisplayProps<T> {
  node: T;
}

export interface PropsExtra<T extends Node> {
  nodes: T[];
  refetch: (args: RefetchArgs) => void;
  display: (props: DisplayProps<T>) => React.ReactNode;
  defaultValue?: string;
  defaultId?: string;
  prefix?: (props: PrefixProps) => React.ReactNode;
}

export interface Props<T extends Node>
  extends Spread<React.InputHTMLAttributes<HTMLInputElement>, PropsExtra<T>> {}

declare module "react" {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

const NodeAutocomplete = React.forwardRef(
  <T extends Node>(
    {
      nodes,
      refetch,
      display,
      onChange,
      onFocus,
      onBlur,
      className,
      placeholder,
      defaultValue,
      defaultId,
      prefix,
      ...rest
    }: Props<T>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const idInputRef = useRef<HTMLInputElement | null>(null);
    const valueInputRef = useRef<HTMLInputElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isValid, setIsValid] = useState(!!defaultId);
    const debouncedRefetch = debounce(refetch, 300);
    return (
      <div ref={wrapperRef}>
        <input
          type="text"
          className="hidden"
          ref={(node) => {
            idInputRef.current = node;
            if (typeof ref === "function") {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          defaultValue={defaultId}
          onChange={(event) => {
            setIsValid(!!event.target.value);
            onChange?.(event);
          }}
          {...rest}
        />
        <TextInput
          className={className}
          onChange={(event) => {
            const value = event.target.value.trim();
            debouncedRefetch({ search: value });
            if (idInputRef.current && event.nativeEvent instanceof InputEvent) {
              const selectedEntity = nodes.find(
                (entity) => entity.value === value,
              );
              if (selectedEntity) {
                nativeInputValueSet(idInputRef.current, selectedEntity.id);
              } else {
                nativeInputValueSet(idInputRef.current, "");
              }
            }
          }}
          defaultValue={defaultValue}
          ref={valueInputRef}
          placeholder={placeholder}
          prefix={prefix && prefix({ isValid })}
          onFocus={(event) => {
            setIsOpen(true);
            event.target.select();
            onFocus?.(event);
          }}
          onBlur={(event) => {
            setTimeout(() => {
              // Timeout to allow the dropdown to be clicked
              setIsOpen(false);
            }, 200);
            onBlur?.(event);
          }}
        />
        {isOpen && wrapperRef.current && nodes.length > 0 && (
          <DropdownList parentRef={wrapperRef}>
            {nodes.map((node) => (
              <DropdownItem
                key={node.id}
                onClick={() => {
                  if (valueInputRef.current) {
                    nativeInputValueSet(valueInputRef.current, node.value);
                  }
                  if (idInputRef.current) {
                    nativeInputValueSet(idInputRef.current, node.id);
                  }
                }}
              >
                {display({ node })}
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </div>
    );
  },
);

export default NodeAutocomplete;
