import { useEntitySubmissionCodeBlobPageQuery } from "./loaders/EntitySubmissionCodeBlobPage";
import { FormattedMessage, useIntl } from "react-intl";
import FileBrowser from "../components/FileBrowser";
import FileViewer from "../components/FileBrowser/FileViewer";
import { MetaLayout } from "../common/MetaLayout";

export default function EntitySubmissionCodeBlobPage() {
  const intl = useIntl();
  const {
    query,
    variables: { slug, version = "latest", fileKind, parentPath },
  } = useEntitySubmissionCodeBlobPageQuery();
  const competition = query.competitionBySlug;
  const submission = competition?.submission;
  const username = submission?.entity.username;
  const file = submission?.version?.fileByKind;
  const entry = file?.browse.readMeta;
  return (
    <div className="space-y-6">
      {file && (
        <MetaLayout
          metaTitle={intl.formatMessage(
            {
              defaultMessage:
                "{username} Submission for {competitionTitle} | Code | {fileKind}",
            },
            {
              username,
              competitionTitle: competition?.title,
              fileKind,
            },
          )}
        >
          <FileBrowser
            root={`/${username}/submissions/${slug}/${version}/code/${fileKind.toLowerCase()}`}
            file={file}
            path={parentPath || ""}
          />
        </MetaLayout>
      )}
      {entry?.kind == "FILE" ? (
        <FileViewer entry={entry} />
      ) : (
        <div className="text-center text-gray-500">
          <FormattedMessage defaultMessage="File not found" />
        </div>
      )}
    </div>
  );
}
