import { cn } from "../utils/tailwind";
import { ActivityIndicator } from "./ActivityIndicator";

interface Props {
  label: React.ReactNode;
  error?: React.ReactNode;
  inline?: boolean;
  children: React.ReactNode;
  isLoading?: boolean;
  hidden?: boolean;
}

export default function FormGroup({
  label,
  inline,
  error,
  children,
  isLoading,
  hidden,
}: Props) {
  return (
    <div className={cn("flex flex-col", hidden && "hidden")}>
      <div
        className={`flex ${inline ? "flex-row space-x-2" : "flex-col space-y-2"}`}
      >
        <span
          className={cn(
            "flex flex-row gap-1 items-center",
            isLoading && "text-gray-500",
          )}
        >
          <label>{label}</label>
          {isLoading && <ActivityIndicator />}
        </span>
        <div className="flex flex-col">{children}</div>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export const ErrorMessage = ({
  error,
}: Readonly<{ error: React.ReactNode }>) => (
  <p className="pt-1 text-sm font-medium text-red-500">{error}</p>
);
