import {
  useEffect,
  useState,
  useRef,
  type PropsWithChildren,
  useCallback,
} from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useMediaQuery } from "../utils/hooks";
import { DESKTOP_MEDIA_QUERY } from "../common/constants";

export interface ScrollableScaffoldNavProps extends PropsWithChildren {}

export function ScrollableScaffoldNav({
  children,
}: ScrollableScaffoldNavProps) {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const navContainerRef = useRef<HTMLDivElement | null>(null);
  const [canScroll, setCanScroll] = useState({
    right: false,
    left: false,
  });

  const scrollNav = (direction: "left" | "right") => {
    if (!navContainerRef.current) {
      return;
    }
    const containerWidth = navContainerRef.current.clientWidth;
    const scrollOptions: ScrollToOptions = {
      left: direction === "left" ? -containerWidth : containerWidth,
      behavior: "smooth",
    };

    const scrollable =
      (direction === "left" && canScroll.left) ||
      (direction === "right" && canScroll.right);

    if (scrollable) {
      navContainerRef.current?.scrollBy(scrollOptions);
    }
  };

  const checkScroll = useCallback(() => {
    if (navContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = navContainerRef.current;
      setCanScroll({
        left: scrollLeft > 0,
        right: scrollLeft + clientWidth < scrollWidth,
      });
    }
  }, []);

  useEffect(() => {
    checkScroll();
    const currentRef = navContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", checkScroll);
    }
    window.addEventListener("resize", checkScroll);
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", checkScroll);
      }
      window.removeEventListener("resize", checkScroll);
    };
  }, [checkScroll]);

  useEffect(() => {
    checkScroll();
  }, [isDesktop, checkScroll]);

  return (
    <div className="relative">
      <div
        className="overflow-x-auto scrollbar-hiden py-6 flex flex-row space-x-2"
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
        ref={navContainerRef}
      >
        {children}
      </div>

      {canScroll.left && (
        <div className="absolute left-0 top-1/2 transform -translate-y-1/2">
          <button
            onClick={() => scrollNav("left")}
            className="bg-white px-4 py-4 rounded"
          >
            <MdOutlineKeyboardArrowLeft />
          </button>
        </div>
      )}

      {canScroll.right && (
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2">
          <button
            onClick={() => scrollNav("right")}
            className="bg-white px-4 py-4 rounded"
          >
            <MdOutlineKeyboardArrowRight />
          </button>
        </div>
      )}
    </div>
  );
}
