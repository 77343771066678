import { useForm } from "react-hook-form";
import FormGroup, { ErrorMessage } from "./FormGroup";
import { FormattedMessage, useIntl } from "react-intl";
import TextInput from "./TextArea";
import { useState } from "react";
import Button from "./Button";

type FormData = {
  content: string;
};

interface DefaultValues {
  content?: string;
}

interface SubmitData {
  data: FormData;
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface Props {
  onSubmit: (data: SubmitData) => void;
  defaultValues?: DefaultValues;
  isDisabled?: boolean;
}

export default function EventTimetableEditForm({
  onSubmit,
  isDisabled,
  defaultValues,
}: Props) {
  const intl = useIntl();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const doSubmit = handleSubmit((data) => {
    setFormError(undefined);
    onSubmit({
      data,
      setFormError,
    });
  });

  return (
    <form onSubmit={doSubmit}>
      <div className="space-y-6">
        {formError && <ErrorMessage error={formError} />}
      </div>
      <div className="flex flex-col space-y-3">
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Json timetable" })}
        >
          <TextInput
            aria-invalid={errors.content ? "true" : "false"}
            defaultValue={JSON.stringify(defaultValues?.content)}
            disabled={isDisabled}
            {...register("content")}
          />
        </FormGroup>
      </div>
      <div className="pt-2 pb-8">
        <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </div>
    </form>
  );
}
