import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { OrganizationUsersPageQuery as OrganizationUsersPageQueryType } from "./__generated__/OrganizationUsersPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { OrganizationUsersPageQuery as OrganizationUsersPageQueryType } from "./__generated__/OrganizationUsersPageQuery.graphql";

export const OrganizationUsersPageQuery = graphql`
  query OrganizationUsersPageQuery($username: String!) {
    ...UserAutocompleteFragment @arguments(kinds: [USER])
    entityByUsername(username: $username) {
      id
      ... on Organization {
        viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)
        ...OrganizationUsersFragment
      }
    }
  }
`;

export const organizationUsersPageQueryLoader = (environment: Environment) => {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<OrganizationUsersPageQueryType>(
      environment,
      OrganizationUsersPageQuery,
      { username },
    );
  };
};

export const useOrganizationUsersPageQuery = () =>
  usePreloaded<OrganizationUsersPageQueryType>();
