import { BANNED_USERNAMES } from "./banned-values";

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_VARCHAR_LENGTH = 255;

export const isNotBannedUsername = (allowed: string[]) => (value: string) =>
  allowed.includes(value) || !BANNED_USERNAMES.includes(value);

export const isUrl = (value: string) => {
  if (!value) return true;
  try {
    new URL(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const maxFileSize = (size: number) => {
  return (files: FileList | undefined) => {
    if (files && files.length > 0) {
      return files[0].size < size;
    }
    return true;
  };
};
