/**
 * @generated SignedSource<<7e3102042d373d71030d4ab62199628b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCompetitionInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  isPrivate: boolean;
  requiresApproval?: boolean | null | undefined;
  shortDescription: string;
  slug: string;
  tagIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  thumbnail?: any | null | undefined;
  title: string;
};
export type CompetitionCreatePageMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateCompetitionInput;
};
export type CompetitionCreatePageMutation$data = {
  readonly createCompetition: {
    readonly node: {
      readonly banner: any | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly isPrivate: boolean;
      readonly requiresApproval: boolean;
      readonly slug: string;
      readonly thumbnail: any | null | undefined;
      readonly title: string;
    };
  };
};
export type CompetitionCreatePageMutation = {
  response: CompetitionCreatePageMutation$data;
  variables: CompetitionCreatePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "CompetitionEdge",
  "kind": "LinkedField",
  "name": "createCompetition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Competition",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "banner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPrivate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresApproval",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionCreatePageMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionCreatePageMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createCompetition",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "fc664e45aa1a774bc598b4e0797445d4",
    "id": null,
    "metadata": {},
    "name": "CompetitionCreatePageMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionCreatePageMutation(\n  $input: CreateCompetitionInput!\n) {\n  createCompetition(input: $input) {\n    node {\n      id\n      title\n      description\n      slug\n      banner\n      thumbnail\n      isPrivate\n      requiresApproval\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2f03e77f874685502b9a23f024635ac1";

export default node;
