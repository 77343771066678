import React, { useCallback, useState } from "react";
import { Spread } from "../utils/types";
import { cn } from "../utils/tailwind";

interface PropsExtra {
  minHeight?: number;
  maxHeight?: number;
}

export interface Props
  extends Spread<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    PropsExtra
  > {}

const TextAreaInput = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLTextAreaElement>) => {
    const {
      className,
      style,
      maxHeight,
      minHeight,
      onChange: wrappedOnChange,
      ...rest
    } = props;
    const [height, setHeight] = useState<number | undefined>(undefined);

    const setHeightFromElement = useCallback(
      (element: HTMLTextAreaElement) => {
        setHeight(
          Math.max(
            minHeight ?? element.offsetHeight,
            Math.min(element.scrollHeight + 1, maxHeight ?? Infinity),
          ),
        );
      },
      [maxHeight, minHeight, setHeight],
    );

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setHeightFromElement(event.target);
      if (wrappedOnChange) {
        wrappedOnChange(event);
      }
    };
    return (
      <textarea
        className={cn(
          "border border-gray-300 rounded px-4 py-2 flex-grow focus:outline-none focus:ring-2 focus:ring-gray w-full",
          className,
        )}
        onChange={handleChange}
        ref={ref}
        style={{ height: height && `${height}px`, ...style }}
        {...rest}
      />
    );
  },
);
export default TextAreaInput;
