import { Outlet as BaseOutlet } from "react-router-dom";
import Suspense from "./Suspense";
import ErrorBoundary from "./ErrorBoundary";

export default function Outlet() {
  return (
    <ErrorBoundary>
      <Suspense>
        <BaseOutlet />
      </Suspense>
    </ErrorBoundary>
  );
}
