import { useController } from "react-hook-form";
import { useMarkdownEditor } from "./context";
import TextAreaInput from "../TextArea";
import { TextAreaDragNDropUploadZone } from "../UploadImage/TextAreaDragNDropUploadZone";
import { TextareaHTMLAttributes, useCallback, useEffect, useRef } from "react";
import { useWrite } from "./useWrite";
import { FaMarkdown } from "react-icons/fa";
import CommandMembersSelect from "./MemberSelect";
import { Spread } from "../../utils/types";

export type ResetTextarea = (resetFunc: () => void) => void;

export interface PropsExtra {
  resetTextarea?: ResetTextarea;
}

interface Props
  extends Spread<PropsExtra, TextareaHTMLAttributes<HTMLTextAreaElement>> {}

export const Write = ({ resetTextarea, ...rest }: Readonly<Props>) => {
  const {
    textareaRef,
    insertTextAtLine,
    onChange,
    dropdownRef,
    inputRef,
    onCommandSelect,
    commandValue,
  } = useWrite();
  const { name, control, defaultValue, members, canUploadFiles } =
    useMarkdownEditor();

  const {
    field: { value, onChange: onFormChange },
  } = useController({ name, control, defaultValue });

  const debouncedRefetch = useRef<(search: string) => void | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event, (search) => {
      if (members?.kind === "all" && debouncedRefetch.current) {
        debouncedRefetch.current(search);
      }
    });
    onFormChange(event);
  };

  const commonTextAreaProps: TextareaHTMLAttributes<HTMLTextAreaElement> = {
    defaultValue: value,
    onChange: handleOnChange,
    rows: 5,
    ...rest,
  };

  const memoizedResetTextarea = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.value = "";
    }
  }, [textareaRef]);

  useEffect(() => {
    if (resetTextarea) {
      resetTextarea(memoizedResetTextarea);
    }
  }, [resetTextarea, memoizedResetTextarea]);

  return (
    <div className="relative w-full">
      {canUploadFiles ? (
        <TextAreaDragNDropUploadZone
          ref={textareaRef}
          onFilesSent={insertTextAtLine}
          {...commonTextAreaProps}
        />
      ) : (
        <TextAreaInput ref={textareaRef} {...commonTextAreaProps} />
      )}
      <div className="flex gap-2 items-center mt-1">
        <FaMarkdown />
        <p className="prose-none text-sm text-gray-500">Supports markdown</p>
      </div>
      <CommandMembersSelect
        debouncedRefetch={debouncedRefetch}
        dropdownRef={dropdownRef}
        inputRef={inputRef}
        onCommandSelect={onCommandSelect}
        commandValue={commandValue}
      />
    </div>
  );
};
