/**
 * @generated SignedSource<<508a80f8d9040b8f7cf05cd665c30ea3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventMembershipKind = "HOST" | "PARTICIPANT";
export type EventMembersPageCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  entityId: string;
  eventId: string;
};
export type EventMembersPageCreateMutation$data = {
  readonly addEventMember: {
    readonly node: {
      readonly entity: {
        readonly displayName: string;
        readonly id: string;
        readonly username: string;
        readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
      };
      readonly event: {
        readonly id: string;
        readonly viewerCanEdit: boolean;
        readonly viewerCanEditMembers: boolean;
      };
      readonly id: string;
      readonly kind: EventMembershipKind;
      readonly viewerCanDelete: boolean;
    };
  };
};
export type EventMembersPageCreateMutation = {
  response: EventMembersPageCreateMutation$data;
  variables: EventMembersPageCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "eventId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  },
  {
    "kind": "Variable",
    "name": "eventId",
    "variableName": "eventId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": "viewerCanDelete",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "REMOVE_EVENT_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"REMOVE_EVENT_MEMBER\")"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "event",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": "viewerCanEditMembers",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "ADD_EVENT_MEMBER"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"ADD_EVENT_MEMBER\")"
    },
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_EVENT"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_EVENT\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EventMembersPageCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventMembershipEdge",
        "kind": "LinkedField",
        "name": "addEventMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "thumbnail",
                        "value": true
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "EntityProfilePicFragment"
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EventMembersPageCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EventMembershipEdge",
        "kind": "LinkedField",
        "name": "addEventMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isEntity"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageThumbnail",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "addEventMember",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1e616e7c3ce6e88f509a3ffbe58b39fb",
    "id": null,
    "metadata": {},
    "name": "EventMembersPageCreateMutation",
    "operationKind": "mutation",
    "text": "mutation EventMembersPageCreateMutation(\n  $eventId: ID!\n  $entityId: ID!\n) {\n  addEventMember(eventId: $eventId, entityId: $entityId) {\n    node {\n      id\n      kind\n      viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)\n      entity {\n        __typename\n        id\n        username\n        displayName\n        ...EntityProfilePicFragment_49kWkb\n      }\n      event {\n        id\n        viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)\n        viewerCanEdit: viewerCan(action: UPDATE_EVENT)\n      }\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "81bc93b460f8c895e3797055a61779ad";

export default node;
