import { intersperse } from "../../utils/helpers";
import { useIntl } from "react-intl";
import { LinkProps, Link } from "react-router-dom";

interface Props {
  root: string;
  path: string;
  delimiter?: string;
}

export default function PathBreadcrumbs({ path, root }: Props) {
  const intl = useIntl();
  const parts = path.split("/").map((part, i, arr) => ({
    name: part,
    path: "/tree/" + arr.slice(0, i + 1).join("/"),
  }));
  parts.unshift({
    name: intl.formatMessage({ defaultMessage: "root" }),
    path: "",
  });
  return (
    <div className="flex flex-row space-x-1">
      {intersperse<React.ReactNode>(
        (index) => (
          <span className="text-slate-400" key={`delimiter${index}`}>
            /
          </span>
        ),
        parts.map(({ name, path }, index) => (
          <PathComponent
            key={index}
            to={`${root}${path}`}
            disabled={index === parts.length - 1}
          >
            {name}
          </PathComponent>
        )),
      )}
    </div>
  );
}

interface PathComponentProps extends LinkProps {
  disabled: boolean;
}

const PathComponent = ({
  children,
  disabled,
  ...props
}: PathComponentProps) => {
  const inner = <strong>{children}</strong>;
  return disabled ? (
    inner
  ) : (
    <Link
      {...props}
      className="text-blue-600 hover:text-blue-400 cursor-pointer"
    >
      {inner}
    </Link>
  );
};
