import { useIntl } from "react-intl";
import { graphql, usePaginationFragment } from "react-relay";
import LoadMore from "../components/LoadMore";
import TopicListItem from "../components/TopicListItem";
import ErrorPage from "./ErrorPage";
import { useUserTopicsPageQuery } from "./loaders/UserTopicsPage";
import { UserTopicsPageFragment$key } from "./__generated__/UserTopicsPageFragment.graphql";

const UserTopicsPageFragment = graphql`
  fragment UserTopicsPageFragment on User
  @refetchable(queryName: "UserTopicsPageFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    topics(first: $count, after: $cursor, order: NEWEST)
      @connection(key: "UserTopicsPageFragment_topics") {
      edges {
        node {
          id
          ...TopicListItemFragment
        }
      }
    }
  }
`;

export default function UserTopicsPage() {
  const intl = useIntl();
  const {
    variables,
    query: { entityByUsername: user },
  } = useUserTopicsPageQuery();

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment(
    UserTopicsPageFragment,
    user as UserTopicsPageFragment$key,
  );

  if (!data) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {data.topics.edges.map((topic) => (
        <TopicListItem key={topic.node.id} topic={topic.node} />
      ))}
      <LoadMore
        hasMore={hasNext}
        loadMore={loadNext}
        isLoading={isLoadingNext}
      />
      {data.topics.edges.length === 0 && (
        <p className="text-gray-400">No topics</p>
      )}
    </div>
  );
}
