import Outlet from "../common/Outlet";
import { useForumScaffoldQuery } from "./loaders/ForumScaffold";
import ErrorPage from "./ErrorPage";
import { FormattedMessage } from "react-intl";
import Forum from "../components/Forum";

export default function ForumScaffold() {
  const {
    query: { forumBySlug: forum },
  } = useForumScaffoldQuery();
  if (!forum) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Discussion not found." />
      </ErrorPage>
    );
  }
  return (
    <div>
      <div className="pb-6">
        <Forum forum={forum} showSubscribeButton />
      </div>
      <Outlet />
    </div>
  );
}
