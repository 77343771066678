import { useLazyLoadQuery, graphql } from "react-relay";
import { CompetitionDataPageQuery as CompetitionDataPageQueryType } from "./__generated__/CompetitionDataPageQuery.graphql";
import { useParams } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import Markdown from "../components/Markdown";
import { MetaLayout } from "../common/MetaLayout";

const CompetitionDataPageQuery = graphql`
  query CompetitionDataPageQuery($slug: String!) {
    competitionBySlug(slug: $slug) {
      slug
      title
      useCase {
        latest {
          readme
        }
      }
    }
  }
`;

export default function CompetitionDataPage() {
  const intl = useIntl();
  const { slug } = useParams();
  const query = useLazyLoadQuery<CompetitionDataPageQueryType>(
    CompetitionDataPageQuery,
    {
      slug: slug ?? "",
    },
  );
  const competition = query.competitionBySlug;
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Data set not found",
        })}
      />
    );
  }
  const readme = competition.useCase.latest?.readme;
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        {
          defaultMessage: "{competitionTitle} | documentation",
        },
        {
          competitionTitle: query.competitionBySlug.title,
        },
      )}
      metaDescription={readme ?? ""}
    >
      <div className="pb-16">
        {readme ? (
          <Markdown>{readme}</Markdown>
        ) : (
          <p className="text-gray-400">
            <FormattedMessage defaultMessage="No description" />
          </p>
        )}
      </div>
    </MetaLayout>
  );
}
