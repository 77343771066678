/**
 * @generated SignedSource<<ca1b7adf2b9e345862f4d5831c8320c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentReplyFragment$data = {
  readonly id: string;
  readonly viewerCanReply: boolean;
  readonly " $fragmentType": "CommentReplyFragment";
};
export type CommentReplyFragment$key = {
  readonly " $data"?: CommentReplyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentReplyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentReplyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "viewerCanReply",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_COMMENT"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"CREATE_COMMENT\")"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "acfb3b82614630a7ea58ddc046eee687";

export default node;
