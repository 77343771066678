/**
 * @generated SignedSource<<c83f78b8ffe8129cdeb2326a2e390a51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionAcceptRulesButtonCompetitionFragment$data = {
  readonly id: string;
  readonly latestRule: {
    readonly entityAgreement: {
      readonly createdAt: string;
      readonly id: string;
    } | null | undefined;
    readonly id: string;
  };
  readonly " $fragmentType": "CompetitionAcceptRulesButtonCompetitionFragment";
};
export type CompetitionAcceptRulesButtonCompetitionFragment$key = {
  readonly " $data"?: CompetitionAcceptRulesButtonCompetitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionAcceptRulesButtonCompetitionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetitionAcceptRulesButtonCompetitionFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "CompetitionRule",
      "kind": "LinkedField",
      "name": "latestRule",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetitionRuleAgreement",
          "kind": "LinkedField",
          "name": "entityAgreement",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};
})();

(node as any).hash = "7bf1f41cb3283f2505807de16b7f866b";

export default node;
