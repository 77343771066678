import { useForumsPageQuery } from "./loaders/ForumsPage";
import Forums from "../components/Forums";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function ForumsPage() {
  const intl = useIntl();
  const { query } = useForumsPageQuery();
  return (
    <MetaLayout
      metaTitle={intl.formatMessage({ defaultMessage: "Discussions" })}
    >
      <div className="pb-10">
        {" "}
        <Forums query={query} />{" "}
      </div>
    </MetaLayout>
  );
}
