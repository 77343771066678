import { FaFile, FaFolder } from "react-icons/fa6";
import { assertNever } from "../../utils/helpers";
import { FileBrowserEntryKind } from "./__generated__/FileBrowserFragment.graphql";

export interface FileTypeIconProps {
  fileType: FileBrowserEntryKind;
}

export default function FileTypeIcon({ fileType }: FileTypeIconProps) {
  switch (fileType) {
    case "FILE":
      return <FaFile />;
    case "DIRECTORY":
      return <FaFolder />;
    default:
      assertNever(fileType);
  }
}
