import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { ForumTopicsPageQuery as ForumTopicsPageQueryType } from "./__generated__/ForumTopicsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";

export type { ForumTopicsPageQuery as ForumTopicsPageQueryType } from "./__generated__/ForumTopicsPageQuery.graphql";

export const ForumTopicsPageQuery = graphql`
  query ForumTopicsPageQuery($slug: String!, $order: VotableOrder!) {
    forumBySlug(slug: $slug) {
      id
      viewerCanCreateTopic: viewerCan(action: CREATE_TOPIC)
      ...ForumTopicsFragment @arguments(order: $order)
    }
  }
`;

export const forumTopicsPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<ForumTopicsPageQueryType>(
      environment,
      ForumTopicsPageQuery,
      { slug, order },
    );
  };
};

export const useForumTopicsPageQuery = () =>
  usePreloaded<ForumTopicsPageQueryType>();
