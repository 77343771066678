/**
 * @generated SignedSource<<58073cb57f09c5937b1587ee617cf1ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCompetitionInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  isPrivate?: boolean | null | undefined;
  requiresApproval?: boolean | null | undefined;
  rules?: string | null | undefined;
  shortDescription?: string | null | undefined;
  slug?: string | null | undefined;
  tagIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  thumbnail?: any | null | undefined;
  title?: string | null | undefined;
};
export type CompetitionEditPageMutation$variables = {
  id: string;
  input: UpdateCompetitionInput;
};
export type CompetitionEditPageMutation$data = {
  readonly updateCompetition: {
    readonly node: {
      readonly banner: any | null | undefined;
      readonly description: string | null | undefined;
      readonly id: string;
      readonly isPrivate: boolean;
      readonly requiresApproval: boolean;
      readonly slug: string;
      readonly thumbnail: any | null | undefined;
      readonly title: string;
    };
  };
};
export type CompetitionEditPageMutation = {
  response: CompetitionEditPageMutation$data;
  variables: CompetitionEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CompetitionEdge",
    "kind": "LinkedField",
    "name": "updateCompetition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "thumbnail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPrivate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requiresApproval",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionEditPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionEditPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "635ec413a715137c151920a0b392ab85",
    "id": null,
    "metadata": {},
    "name": "CompetitionEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionEditPageMutation(\n  $id: ID!\n  $input: UpdateCompetitionInput!\n) {\n  updateCompetition(id: $id, input: $input) {\n    node {\n      id\n      title\n      description\n      slug\n      banner\n      thumbnail\n      isPrivate\n      requiresApproval\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c32da8891ca20db0caf963b0931bf171";

export default node;
