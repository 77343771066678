import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useNavigate } from "react-router-dom";
import { EventDeleteButtonMutation as EventDeleteButtonMutationType } from "./__generated__/EventDeleteButtonMutation.graphql";
import Button from "./Button";
import { FormattedMessage } from "react-intl";

const EventDeleteButtonMutation = graphql`
  mutation EventDeleteButtonMutation($id: ID!, $connections: [ID!]!) {
    deleteEvent(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  id: string;
}

export default function EventDeleteButton({ id }: Props) {
  const navigate = useNavigate();
  const [commitMutation, isMutationInFlight] =
    useMutation<EventDeleteButtonMutationType>(EventDeleteButtonMutation);

  const onDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (isMutationInFlight) {
      return;
    }
    commitMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID("root", "EventsFragment_events"),
        ],
      },
      onCompleted: () => {
        navigate("/");
      },
    });
  };
  return (
    <Button kind="danger" onClick={onDelete}>
      <FormattedMessage defaultMessage="Delete" />
    </Button>
  );
}
