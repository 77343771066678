import { Options } from "react-markdown";
import { useMarkdownEditor } from "./context";
import { FormattedMessage } from "react-intl";
import Markdown from "../Markdown";

export interface Props {
  markdown?: (options: Options) => JSX.Element;
}

export function Preview({ markdown = Markdown }: Props) {
  const { markdownValue } = useMarkdownEditor();
  return (
    <div className="w-full overflow-auto prose dark:prose-invert prose-sm px-1 border border-transparent prose-headings:font-cal">
      {!markdownValue?.trimStart() || markdownValue === undefined ? (
        <p className="text-gray-400 italic">
          <FormattedMessage defaultMessage="Nothing to preview" />
        </p>
      ) : (
        markdown({ children: markdownValue })
      )}
    </div>
  );
}
