/**
 * @generated SignedSource<<885a4712d0cc5104cc6c1447b3c12a6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionOverviewPageQuery$variables = {
  slug: string;
};
export type CompetitionOverviewPageQuery$data = {
  readonly competitionBySlug: {
    readonly description: string | null | undefined;
    readonly id: string;
    readonly viewerCanUpdate: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"MemberSelectCompetitionFragment">;
  } | null | undefined;
};
export type CompetitionOverviewPageQuery = {
  response: CompetitionOverviewPageQuery$data;
  variables: CompetitionOverviewPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanUpdate",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MemberSelectCompetitionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionOverviewPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": "textareaMembers",
            "args": null,
            "concreteType": "CompetitionMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "061cd900374ee52f6e58fde0fc5af7c7",
    "id": null,
    "metadata": {},
    "name": "CompetitionOverviewPageQuery",
    "operationKind": "query",
    "text": "query CompetitionOverviewPageQuery(\n  $slug: String!\n) {\n  competitionBySlug(slug: $slug) {\n    id\n    description\n    viewerCanUpdate: viewerCan(action: UPDATE_COMPETITION)\n    ...MemberSelectCompetitionFragment\n  }\n}\n\nfragment MemberSelectCompetitionFragment on Competition {\n  textareaMembers: members {\n    edges {\n      node {\n        entity {\n          __typename\n          id\n          ...MemberSelectEntityFragment\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment MemberSelectEntityFragment on Entity {\n  __isEntity: __typename\n  id\n  username\n  displayName\n}\n"
  }
};
})();

(node as any).hash = "1bfe898e48bf55deb0541557205f6bc1";

export default node;
