import { graphql, useFragment } from "react-relay";
import { TopicLinkFragment$key } from "./__generated__/TopicLinkFragment.graphql";
import { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

const TopicLinkFragment = graphql`
  fragment TopicLinkFragment on Topic {
    id
    competition {
      slug
    }
    forum {
      slug
    }
  }
`;

export interface TopicLinkProps extends Omit<LinkProps, "to"> {
  topic: TopicLinkFragment$key;
  subpath?: string | undefined;
}

export default function TopicLink({
  topic: topicFragment,
  subpath = "",
  ...props
}: TopicLinkProps) {
  const topic = useFragment(TopicLinkFragment, topicFragment);
  const to = topic.competition?.slug
    ? `/competitions/${topic.competition.slug}/discussions/${topic.id}${subpath}`
    : topic.forum?.slug
      ? `/forums/${topic.forum.slug}/discussions/${topic.id}${subpath}`
      : `/discussions/${topic.id}${subpath}`;
  return <Link {...props} to={to} />;
}
