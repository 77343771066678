import { FormattedMessage, useIntl } from "react-intl";
import { graphql } from "relay-runtime";
import { useEventTimetableEditPageQuery } from "./loaders/EventTimetableEditPage";
import { useMutation } from "react-relay";
import { EventTimetableEditPageMutation as EventTimetableEditPageMutationType } from "./__generated__/EventTimetableEditPageMutation.graphql";
import ErrorPage from "./ErrorPage";
import EventTimetableEditForm from "../components/EventTimetableEditForm";
import { logger } from "../common/logger";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const EventTimetableEditPageMutation = graphql`
  mutation EventTimetableEditPageMutation(
    $eventId: ID!
    $input: UpdateAgendaInput!
  ) {
    updateEventAgenda(eventId: $eventId, input: $input) {
      node {
        id
        agenda
      }
    }
  }
`;

export default function EventTimetableEditPage() {
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    query: { eventBySlug },
  } = useEventTimetableEditPageQuery();

  const [commitMutation, isMutationInFlight] =
    useMutation<EventTimetableEditPageMutationType>(
      EventTimetableEditPageMutation,
    );

  if (!eventBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"Edit agenda"} />
      </h1>
      <EventTimetableEditForm
        isDisabled={isMutationInFlight}
        defaultValues={{ content: eventBySlug.agenda }}
        onSubmit={(data) => {
          const {
            data: { content },
            setFormError,
          } = data;
          commitMutation({
            variables: {
              eventId: eventBySlug.id,
              input: {
                agenda: JSON.parse(content),
              },
            },
            onError: (error: Error) => {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage:
                    "An error occurred while saving the agenda. Please, try again later.",
                }),
              );
            },
            onCompleted: () => {
              toast.success(
                intl.formatMessage({
                  defaultMessage: "Agenda has been updated",
                }),
              );
              navigate(-1);
            },
          });
        }}
      />
    </div>
  );
}
