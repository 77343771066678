/**
 * @generated SignedSource<<4ef72e2a49f606bf98a1b7d79fc6ef04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicLinkFragment$data = {
  readonly competition: {
    readonly slug: string;
  } | null | undefined;
  readonly forum: {
    readonly slug: string;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "TopicLinkFragment";
};
export type TopicLinkFragment$key = {
  readonly " $data"?: TopicLinkFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicLinkFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "slug",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicLinkFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Competition",
      "kind": "LinkedField",
      "name": "competition",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Forum",
      "kind": "LinkedField",
      "name": "forum",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Topic",
  "abstractKey": null
};
})();

(node as any).hash = "6dccc12c123e32f34386dc8f9dfaf5d6";

export default node;
