import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionCodeReadmePageQuery as CompetitionCodeReadmePageQueryType } from "./__generated__/CompetitionCodeReadmePageQuery.graphql";
import { fileKind } from "./CompetitionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionCodeReadmePageQuery as CompetitionCodeReadmePageQueryType } from "./__generated__/CompetitionCodeReadmePageQuery.graphql";

export const CompetitionCodeReadmePageQuery = graphql`
  query CompetitionCodeReadmePageQuery(
    $slug: String!
    $fileKind: ProjectVersionFileKind!
  ) {
    competitionBySlug(slug: $slug) {
      id
      useCase {
        latest {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment
            browse {
              readMeta(path: "README.md") {
                kind
                ... on FileBrowserFileEntry {
                  ...FileViewerFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const competitionCodeReadmePageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug, kind } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionCodeReadmePageQueryType>(
      environment,
      CompetitionCodeReadmePageQuery,
      {
        slug,
        fileKind: fileKind(kind),
      },
    );
  };
};

export const useCompetitionCodeReadmePageQuery = () =>
  usePreloaded<CompetitionCodeReadmePageQueryType>();
