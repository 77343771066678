/**
 * @generated SignedSource<<940ecaa36cffda1356b5a264cb5e5854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type CompetitionMembershipKind = "HOST" | "PARTICIPANT";
import { FragmentRefs } from "relay-runtime";
export type CompetitionMembersFragment$data = {
  readonly id: string;
  readonly members: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entity: {
          readonly displayName: string;
          readonly id: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
        };
        readonly id: string;
        readonly kind: CompetitionMembershipKind;
        readonly viewerCanDelete: boolean;
      };
    }>;
  };
  readonly viewerCanEdit: boolean;
  readonly viewerCanEditMembers: boolean;
  readonly viewerCanTransferOwnership: boolean;
  readonly " $fragmentType": "CompetitionMembersFragment";
};
export type CompetitionMembersFragment$key = {
  readonly " $data"?: CompetitionMembersFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionMembersFragment">;
};

import CompetitionMembersFragmentPaginationQuery_graphql from './CompetitionMembersFragmentPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "members"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": CompetitionMembersFragmentPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CompetitionMembersFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": "viewerCanEdit",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_COMPETITION"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
    },
    {
      "alias": "viewerCanEditMembers",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "ADD_COMPETITION_MEMBER"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"ADD_COMPETITION_MEMBER\")"
    },
    {
      "alias": "viewerCanTransferOwnership",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "TRANSFER_COMPETITION_OWNERSHIP"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"TRANSFER_COMPETITION_OWNERSHIP\")"
    },
    {
      "alias": "members",
      "args": null,
      "concreteType": "CompetitionMembershipConnection",
      "kind": "LinkedField",
      "name": "__CompetitionMembersFragment_members_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CompetitionMembershipEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CompetitionMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": "viewerCanDelete",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "action",
                      "value": "REMOVE_COMPETITION_MEMBER"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "viewerCan",
                  "storageKey": "viewerCan(action:\"REMOVE_COMPETITION_MEMBER\")"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "entity",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayName",
                      "storageKey": null
                    },
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "thumbnail",
                          "value": true
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "EntityProfilePicFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};
})();

(node as any).hash = "34d3fb5bef1732a17c768632fefd2ac4";

export default node;
