const fileNames: {
  readonly [fileName: string]: string;
} = Object.freeze({
  ".gitignore": "properties",
});

const extensions: {
  readonly [extension: string]: string;
} = Object.freeze({
  py: "python",
  toml: "ini",
  cfg: "properties",
  js: "javascript",
  ts: "typescript",
  rs: "rust",
  go: "go",
  java: "java",
  kt: "kotlin",
  scala: "scala",
  c: "c",
  cpp: "cpp",
  cs: "csharp",
  m: "matlab",
  h: "c",
  hpp: "cpp",
  hs: "haskell",
  sh: "bash",
  r: "r",
  json: "json",
  yaml: "yaml",
});

export function guessCodeLanguage(filename: string): string | null {
  if (filename in fileNames) {
    return fileNames[filename];
  }

  const extIndex = filename.lastIndexOf(".");
  if (extIndex < 0) return null;

  const extension = filename.slice(extIndex + 1);
  if (extension in extensions) {
    return extensions[extension];
  }

  return null;
}
