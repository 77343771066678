import { useLazyLoadQuery, useQueryLoader } from "react-relay";
import { graphql } from "relay-runtime";
import { MentionQuery as MentionQueryType } from "./__generated__/MentionQuery.graphql";
import { cn } from "../utils/tailwind";
import { useAuth } from "../utils/auth";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./HoverCard";
import EntityProfilePic from "./EntityProfilePic";
import { FormattedMessage } from "react-intl";
import { Suspense } from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "./Skeleton";

const MentionQuery = graphql`
  query MentionQuery($username: String!) {
    entityByUsername(username: $username) {
      id
      username
      displayName
      createdAt
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
  }
`;

export interface MentionProps {
  username: string;
}

function MentionContent({ username }: Readonly<MentionProps>) {
  const data = useLazyLoadQuery<MentionQueryType>(MentionQuery, {
    username: username.slice(1),
  });

  const mentionedUser = data.entityByUsername;
  if (!mentionedUser) {
    return <p>{username}</p>;
  }

  return (
    <div className="flex items-center gap-4 w-full ">
      <EntityProfilePic
        entity={mentionedUser}
        size="16"
        className="flex-shrink-0"
      />
      <div className="flex flex-col justify-center">
        <div className="flex items-baseline gap-3">
          <p className="text-lg font-semibold text-gray-900">
            {mentionedUser.username}
          </p>
          <p className="text-sm text-gray-600">{mentionedUser.displayName}</p>
        </div>
        <p className="text-sm text-gray-500">
          <FormattedMessage
            defaultMessage="Joined {joinedAt, date, ::yyyyMMdd}"
            values={{ joinedAt: new Date(mentionedUser.createdAt) }}
          />
        </p>
      </div>
    </div>
  );
}

export function Mention({ username }: Readonly<MentionProps>) {
  const { userId: viewerId } = useAuth();
  const [queryReference, loadQuery] =
    useQueryLoader<MentionQueryType>(MentionQuery);

  const handleOpenChange = (open: boolean) => {
    if (open && !queryReference) {
      loadQuery({ username: username.slice(1) });
    }
  };

  return (
    <HoverCard onOpenChange={handleOpenChange}>
      <HoverCardTrigger
        asChild
        className={cn(
          "font-bold underline-offset-2 underline py-2 px-1 cursor-pointer",
          queryReference?.id === viewerId && "bg-grey/25",
        )}
      >
        <Link to={`/${username.slice(1)}`}>{username}</Link>
      </HoverCardTrigger>
      <HoverCardContent>
        {queryReference && (
          <Suspense fallback={<MentionContentSkeleton />}>
            <MentionContent username={username} />
          </Suspense>
        )}
      </HoverCardContent>
    </HoverCard>
  );
}

function MentionContentSkeleton() {
  return (
    <div className="flex items-center gap-4 w-full">
      <Skeleton className="h-12 w-12 rounded-full flex-shrink-0" />
      <div className="flex flex-col justify-center space-y-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-24" />
        <Skeleton className="h-3 w-40" />
      </div>
    </div>
  );
}
