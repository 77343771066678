import { logger } from "../common/logger";

export default function download(
  fileURL: string,
  fileName?: string | undefined,
) {
  if (typeof fileName === "string") {
    fetch(fileURL)
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((url) => clickDownloadLink(url, fileName), logger.error);
  } else {
    clickDownloadLink(fileURL, "");
  }
}

function clickDownloadLink(href: string, fileName: string) {
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", fileName ?? "");

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
}
