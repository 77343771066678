import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { CompetitionSetupButtonFragment$key } from "./__generated__/CompetitionSetupButtonFragment.graphql";
import { FormattedMessage } from "react-intl";
import ProjectDownloadButton from "./ProjectDownloadButton";

const CompetitionSetupButtonFragment = graphql`
  fragment CompetitionSetupButtonFragment on Competition
  @argumentDefinitions(
    kind: { type: "ProjectVersionFileKind", defaultValue: TEMPLATE }
  ) {
    slug
    useCase {
      latest {
        fileByKind(kind: $kind) {
          kind
          ...ProjectDownloadButtonFragment
        }
      }
    }
  }
`;

export interface CompetitionSetupButtonProps {
  competition: CompetitionSetupButtonFragment$key;
}

export function CompetitionSetupButton({
  competition: competitionFragment,
}: Readonly<CompetitionSetupButtonProps>) {
  const competition = useFragment(
    CompetitionSetupButtonFragment,
    competitionFragment,
  );
  const selected = competition.useCase.latest?.fileByKind;
  if (!selected) return;
  return (
    <ProjectDownloadButton fragment={selected}>
      <FormattedMessage defaultMessage="Download" />
    </ProjectDownloadButton>
  );
}
