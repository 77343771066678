/**
 * @generated SignedSource<<d7762ddae9ba312a09122e900e889c86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionMembershipKind = "HOST" | "PARTICIPANT";
export type CompetitionMembersPageTransferOwnershipMutation$variables = {
  competitionId: string;
  connections: ReadonlyArray<string>;
  toUserId: string;
};
export type CompetitionMembersPageTransferOwnershipMutation$data = {
  readonly transferCompetitionOwnership: ReadonlyArray<{
    readonly node: {
      readonly competition: {
        readonly host: {
          readonly displayName: string;
          readonly id: string;
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
        };
        readonly id: string;
        readonly viewerCanEdit: boolean;
        readonly viewerCanEditMembers: boolean;
        readonly viewerCanTransferOwnership: boolean;
      };
      readonly entity: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
      };
      readonly id: string;
      readonly kind: CompetitionMembershipKind;
      readonly viewerCanDelete: boolean;
    };
  }>;
};
export type CompetitionMembersPageTransferOwnershipMutation = {
  response: CompetitionMembersPageTransferOwnershipMutation$data;
  variables: CompetitionMembersPageTransferOwnershipMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "competitionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toUserId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "competitionId",
    "variableName": "competitionId"
  },
  {
    "kind": "Variable",
    "name": "toEntityId",
    "variableName": "toUserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": "viewerCanDelete",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "REMOVE_COMPETITION_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"REMOVE_COMPETITION_MEMBER\")"
},
v7 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
},
v8 = {
  "alias": "viewerCanEditMembers",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "ADD_COMPETITION_MEMBER"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"ADD_COMPETITION_MEMBER\")"
},
v9 = {
  "alias": "viewerCanTransferOwnership",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "TRANSFER_COMPETITION_OWNERSHIP"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"TRANSFER_COMPETITION_OWNERSHIP\")"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v12 = {
  "args": [
    {
      "kind": "Literal",
      "name": "thumbnail",
      "value": true
    }
  ],
  "kind": "FragmentSpread",
  "name": "EntityProfilePicFragment"
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v14 = {
  "kind": "TypeDiscriminator",
  "abstractKey": "__isEntity"
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageThumbnail",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionMembersPageTransferOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CompetitionMembershipEdge",
        "kind": "LinkedField",
        "name": "transferCompetitionOwnership",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "host",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionMembersPageTransferOwnershipMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CompetitionMembershipEdge",
        "kind": "LinkedField",
        "name": "transferCompetitionOwnership",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionMembership",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Competition",
                "kind": "LinkedField",
                "name": "competition",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "host",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v4/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "transferCompetitionOwnership",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5d383184146daa1d9bd9558ac7e92e0f",
    "id": null,
    "metadata": {},
    "name": "CompetitionMembersPageTransferOwnershipMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionMembersPageTransferOwnershipMutation(\n  $competitionId: ID!\n  $toUserId: ID!\n) {\n  transferCompetitionOwnership(competitionId: $competitionId, toEntityId: $toUserId) {\n    node {\n      id\n      kind\n      viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)\n      competition {\n        id\n        viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)\n        viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)\n        viewerCanTransferOwnership: viewerCan(action: TRANSFER_COMPETITION_OWNERSHIP)\n        host {\n          __typename\n          id\n          displayName\n          username\n          ...EntityProfilePicFragment_49kWkb\n        }\n      }\n      entity {\n        __typename\n        id\n        ...EntityProfilePicFragment_49kWkb\n      }\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "18abcdeebcdc99fa03c091496b9d36b8";

export default node;
