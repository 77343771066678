/**
 * @generated SignedSource<<d2d38584becb415586bebcadee759706>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopicCommentsFormViewerFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  readonly " $fragmentType": "TopicCommentsFormViewerFragment";
};
export type TopicCommentsFormViewerFragment$key = {
  readonly " $data"?: TopicCommentsFormViewerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopicCommentsFormViewerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopicCommentsFormViewerFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6abfaab20dc6e38196b401beb92d3bef";

export default node;
