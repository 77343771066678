import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TopicHelmet from "../components/TopicHelmet";
import ErrorPage from "./ErrorPage";
import { useCommentPageQuery } from "./loaders/CommentPage";
import Suspense from "../common/Suspense";
import EntityProfilePic from "../components/EntityProfilePic";
import { Link } from "react-router-dom";
import VoteDisplay from "../components/VoteDisplay";
import SubjectSubscriptionButton from "../components/SubjectSubscriptionButton";
import Markdown from "../components/Markdown";
import Button from "../components/Button";
import { MdArrowBack } from "react-icons/md";
import TopicLink from "../components/TopicLink";
import FocusedComment from "../components/FocusedComment";
import VotableOrderSelect from "../components/VotableOrderSelect";
import { VotableOrder } from "../utils/votableOrder";

export default function CommentPage() {
  const intl = useIntl();
  const {
    query: { node: comment },
    variables: { order: initialOrder },
  } = useCommentPageQuery();
  const [order, setOrder] = useState<VotableOrder>(initialOrder);
  const { topic } = comment;

  if (!comment || !topic || !topic.author) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Data set not found",
        })}
      />
    );
  }

  return (
    <>
      <TopicHelmet topic={topic} />
      <div className="pt-2 pb-8">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col gap-2 items-center">
            <EntityProfilePic entity={topic.author} size="10" />
            <Link
              to={`/${topic.author.username}`}
              className="underline text-gray-400"
            >
              {topic.author.username}
            </Link>
            <VoteDisplay subject={topic} kind="vertical" />
          </div>

          <div className="flex flex-col gap-4 flex-1">
            <div className="flex flex-row space-x-4 items-center justify-between">
              <h1 className="text-2xl font-bold font-poppins">{topic.title}</h1>

              <div className="flex gap-6">
                <TopicLink topic={topic}>
                  <Button
                    kind="secondary"
                    className="flex flex-row gap-1 items-center"
                  >
                    <MdArrowBack />
                    Go back to topic
                  </Button>
                </TopicLink>

                <SubjectSubscriptionButton subject={topic} />
              </div>
            </div>

            <div className="pt-5 pb-10">
              {topic.description ? (
                <Markdown>{topic.description}</Markdown>
              ) : (
                <p className="text-gray-400">
                  <FormattedMessage defaultMessage="No description" />
                </p>
              )}
            </div>
          </div>
        </div>

        <hr />

        <div className="py-5">
          <FormattedMessage defaultMessage="Order by:" />{" "}
          <VotableOrderSelect value={order} onChange={setOrder} />
        </div>

        <Suspense>
          <FocusedComment comment={comment} order={order} />
        </Suspense>
      </div>
    </>
  );
}
