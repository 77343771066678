import FileBrowser from "../components/FileBrowser";
import { useEntitySubmissionCodeTreePageQuery } from "./loaders/EntitySubmissionCodeTreePage";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function CompetitionCodeTreePage() {
  const intl = useIntl();
  const {
    query,
    variables: { slug, version = "latest", fileKind, path },
  } = useEntitySubmissionCodeTreePageQuery();
  const submission = query.competitionBySlug?.submission;
  const username = submission?.entity.username;
  const file = submission?.version?.fileByKind;
  return (
    <div className="space-y-6">
      {file && (
        <MetaLayout
          metaTitle={intl.formatMessage(
            { defaultMessage: "{username} | submissions code tree" },
            { username },
          )}
        >
          <FileBrowser
            root={`/${username}/submissions/${slug}/${version}/code/${fileKind.toLowerCase()}`}
            file={file}
            path={path || ""}
          />
        </MetaLayout>
      )}
    </div>
  );
}
