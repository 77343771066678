/**
 * @generated SignedSource<<f7c844d13f0dcec3e8dd10f40ee28aca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionCardFragment$data = {
  readonly host: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCardHostFragment">;
  };
  readonly id: string;
  readonly shortDescription: string;
  readonly slug: string;
  readonly tags: {
    readonly " $fragmentSpreads": FragmentRefs<"ActivityCardTagsFragment">;
  };
  readonly thumbnail: any | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "CompetitionCardFragment";
};
export type CompetitionCardFragment$key = {
  readonly " $data"?: CompetitionCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompetitionCardFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "host",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityCardHostFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TagConnection",
      "kind": "LinkedField",
      "name": "tags",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ActivityCardTagsFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Competition",
  "abstractKey": null
};

(node as any).hash = "b1e0de478bfeee44c13484b6dfec507e";

export default node;
