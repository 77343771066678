import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { LoginPageUserMutation as LoginPageUserMutationType } from "./__generated__/LoginPageUserMutation.graphql";
import { useOnAuthCallback } from "../utils/auth";

import Button from "../components/Button";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";

const LoginPageUserMutation = graphql`
  mutation LoginPageUserMutation($input: LoginUserInput!) {
    loginUser(input: $input) {
      node {
        id
      }
    }
  }
`;

type FormData = {
  usernameOrEmail: string;
  password: string;
};

export default function Login() {
  const intl = useIntl();
  const [searchParams, _setSearchParams] = useSearchParams();
  const onAuthenticated = useOnAuthCallback();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [commitMutation, isMutationInFlight] =
    useMutation<LoginPageUserMutationType>(LoginPageUserMutation);
  const onSubmit = handleSubmit((data) => {
    setFormError(undefined);
    commitMutation({
      variables: {
        input: data,
      },
      onError() {
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not login. Check your credentials and try again.",
          }),
        );
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  });
  const errorMessages = {
    usernameOrEmail: {
      required: intl.formatMessage({
        defaultMessage: "Username or email is required",
      }),
    },
    password: {
      required: intl.formatMessage({ defaultMessage: "Password is required" }),
    },
  };
  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col space-y-3">
        <FormGroup
          label={intl.formatMessage({ defaultMessage: "Username or Email" })}
          error={
            typeof errors.usernameOrEmail?.type === "string" &&
            errorMessages.usernameOrEmail[
              errors.usernameOrEmail
                .type as keyof typeof errorMessages.usernameOrEmail
            ]
          }
        >
          <TextInput
            aria-invalid={errors.usernameOrEmail ? "true" : "false"}
            {...register("usernameOrEmail", {
              required: true,
            })}
          />
        </FormGroup>
        <FormGroup
          label={
            <div className="flex flex-row justify-between">
              <FormattedMessage defaultMessage="Password" />
              <Link
                className="text-gray-600 hover:text-gray-700 text-sm"
                tabIndex={-1}
                to="/send-password-reset"
              >
                <FormattedMessage defaultMessage="Forgot Password?" />
              </Link>
            </div>
          }
          error={
            typeof errors.password?.type === "string" &&
            errorMessages.password[
              errors.password.type as keyof typeof errorMessages.password
            ]
          }
        >
          <TextInput
            type="password"
            aria-invalid={errors.password ? "true" : "false"}
            {...register("password", {
              required: true,
            })}
          />
        </FormGroup>
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="py-3 flex justify-between items-center">
          <p className="text-gray-600 pr-2">
            <FormattedMessage defaultMessage="Don't have an account?" />
            {"  "}
            <Link
              to={{ pathname: "/signup", search: searchParams.toString() }}
              className="text-blue-400"
            >
              <FormattedMessage defaultMessage="Signup for aqora" />
            </Link>
          </p>
          <Button type="submit" disabled={isMutationInFlight}>
            <FormattedMessage defaultMessage="Login" />
          </Button>
        </div>
      </div>
    </form>
  );
}
