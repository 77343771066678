import React from "react";
import { Spread } from "../utils/types";
import NodeAutocomplete, {
  RefetchArgs,
  PrefixProps,
  DisplayProps,
} from "./NodeAutocomplete";

interface EntityItem {
  id: string;
  username: string;
  displayName: string;
}

function Prefix({ isValid }: PrefixProps) {
  return isValid ? (
    <span className="text-indigo">@</span>
  ) : (
    <span className="text-gray-400">@</span>
  );
}

function EntityDisplay({ node }: DisplayProps<EntityItem>) {
  return (
    <span>
      {node.displayName} @{node.username}
    </span>
  );
}

interface PropsExtra {
  entities: EntityItem[];
  refetch: (args: RefetchArgs) => void;
  defaultUsername?: string;
  defaultId?: string;
}

export interface Props
  extends Spread<React.InputHTMLAttributes<HTMLInputElement>, PropsExtra> {}

const EntityAutoComplete = React.forwardRef(
  (
    {
      entities,
      defaultId,
      refetch,
      defaultUsername,
      defaultValue: _defaultValue,
      prefix: _prefix,
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <NodeAutocomplete
        ref={ref}
        nodes={entities.map((entity) => ({
          value: entity.username,
          ...entity,
        }))}
        refetch={refetch}
        display={EntityDisplay}
        defaultValue={defaultUsername}
        defaultId={defaultId}
        prefix={Prefix}
        {...rest}
      />
    );
  },
);

export default EntityAutoComplete;
