import { ClipboardEventHandler, forwardRef, useState } from "react";
import { graphql } from "relay-runtime";
import TextAreaDropZone, { TextAreaDropZoneProps } from "./TextAreaDropZone";
import { useMutation } from "react-relay";
import { TextAreaDragNDropUploadZoneInitMutation as TextAreaDragNDropUploadZoneInitMutationType } from "./__generated__/TextAreaDragNDropUploadZoneInitMutation.graphql";
import { TextAreaDragNDropUploadZoneFinishMutation as TextAreaDragNDropUploadZoneFinishMutationType } from "./__generated__/TextAreaDragNDropUploadZoneFinishMutation.graphql";
import { toast } from "sonner";
import { useIntl } from "react-intl";
import { commitMutationPromise } from "../../utils/relay";
import { logger } from "../../common/logger";

const TextAreaDragNDropUploadZoneInitMutation = graphql`
  mutation TextAreaDragNDropUploadZoneInitMutation(
    $input: InitUploadFileInput!
  ) {
    initUploadFile(input: $input) {
      key
      uploadUrl
    }
  }
`;

const TextAreaDragNDropUploadZoneFinishMutation = graphql`
  mutation TextAreaDragNDropUploadZoneFinishMutation(
    $input: FinishUploadFileInput!
  ) {
    finishUploadFile(input: $input) {
      downloadUrl
    }
  }
`;

type TextAreaDragNDropUploadZoneProps = {
  onFilesSent: (markdownImagesWidgets: string, line?: number) => void;
} & Omit<TextAreaDropZoneProps, "onDragAndDrop">;

export const TextAreaDragNDropUploadZone = forwardRef<
  HTMLTextAreaElement,
  Readonly<TextAreaDragNDropUploadZoneProps>
>(({ onFilesSent, ...rest }, ref) => {
  const intl = useIntl();
  const [isUploading, setIsUploading] = useState(false);
  const [commitInit, _commitInitInFlight] =
    useMutation<TextAreaDragNDropUploadZoneInitMutationType>(
      TextAreaDragNDropUploadZoneInitMutation,
    );
  const [commitFinish, _commitFinishInFlight] =
    useMutation<TextAreaDragNDropUploadZoneFinishMutationType>(
      TextAreaDragNDropUploadZoneFinishMutation,
    );

  const doUpload = async (file: File) => {
    const {
      initUploadFile: { key, uploadUrl },
    } = await commitMutationPromise(commitInit)({
      variables: {
        input: {
          filename: file.name,
          contentType: file.type,
          contentLength: file.size,
        },
      },
    });

    const res = await fetch(uploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Disposition": `attachment; filename="${file.name}"`,
      },
    });

    if (res.status !== 200) {
      let errorMessage;
      try {
        errorMessage = `[${res.statusText}]: ${await res.text()}`;
      } catch (e) {
        errorMessage = `[${res.statusText}]`;
      }
      throw errorMessage;
    }

    const {
      finishUploadFile: { downloadUrl },
    } = await commitMutationPromise(commitFinish)({
      variables: {
        input: {
          key,
        },
      },
    });

    return { file, downloadUrl };
  };

  const commitFiles = (images: FileList, lineNumber?: number) => {
    if (!images.length) {
      return;
    }

    setIsUploading(true);

    Promise.all(Array.from(images).map(doUpload))
      .then((results) => {
        const links = results
          .map(({ file, downloadUrl }) => {
            const prefix = file.type.startsWith("image/") ? "!" : "";
            return `${prefix}[${file.name}](${downloadUrl})`;
          })
          .join(" ");
        onFilesSent(links, lineNumber);
      })
      .catch((error) => {
        logger.error(error);
        toast.error(
          `${intl.formatMessage({
            defaultMessage:
              "There was a error on file upload. Please try again",
          })}: ${error}`,
        );
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleOnPaste: ClipboardEventHandler<HTMLTextAreaElement> = (event) => {
    const items = event.clipboardData?.items;
    if (!items) return;

    const clipboardimages = Array.from(items)
      .slice()
      .filter((item) => item.kind === "file")
      .map((item) => item.getAsFile())
      .filter((file) => file !== null);

    const dataTransfer = new DataTransfer();
    clipboardimages.forEach((file) => {
      if (!file) return;
      dataTransfer.items.add(file);
    });

    const imagesFromClipboard = dataTransfer.files;

    commitFiles(imagesFromClipboard);
  };

  return (
    <>
      <TextAreaDropZone
        ref={ref}
        onDragAndDrop={commitFiles}
        disabled={isUploading}
        aria-disabled={isUploading}
        onPaste={handleOnPaste}
        {...rest}
      />
      <input
        className="mt-4 w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] focus:border-primary focus:text-midnight focus:shadow-inset focus:outline-none"
        type="file"
        disabled={isUploading}
        aria-disabled={isUploading}
        multiple
        onChange={(event) =>
          event.target.files && commitFiles(event.target.files)
        }
      />
    </>
  );
});
