import { graphql, usePaginationFragment } from "react-relay";
import { EventsFragment$key } from "./__generated__/EventsFragment.graphql";
import EventCard from "./EventCard";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

const EventsFragment = graphql`
  fragment EventsFragment on Query
  @refetchable(queryName: "EventsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    viewer @ifAllowed {
      canCreateEvent: can(action: CREATE_EVENT)
    }
    events(after: $cursor, first: $count)
      @connection(key: "EventsFragment_events") {
      edges {
        node {
          id
          ...EventCardFragment
        }
      }
    }
  }
`;

interface Props {
  query: EventsFragment$key;
}

export default function Events({ query }: Props) {
  const { data /* loadNext, hasNext */ } = usePaginationFragment(
    EventsFragment,
    query,
  );
  if (data.events.edges.length === 0 && !data.viewer?.canCreateEvent) {
    return null;
  }
  return (
    <div className="space-y-6">
      <div className="flex flex-row">
        <h1 className="text-2xl font-bold font-poppins">
          <FormattedMessage defaultMessage="Events" />
        </h1>
        <div className="w-full" />
        {data.viewer?.canCreateEvent && (
          <Button kind="primary" to="/events/new">
            <FormattedMessage defaultMessage="Create Event" />
          </Button>
        )}
      </div>
      {data.events.edges.length > 0 ? (
        <div className="grid grid-cols-2 gap-4 max-sm:grid-cols-1">
          {data.events.edges.map(({ node }) => (
            <EventCard key={node.id} event={node} />
          ))}
        </div>
      ) : (
        <div className="text-gray-400">
          <FormattedMessage defaultMessage="No events yet" />
        </div>
      )}
    </div>
  );
}
