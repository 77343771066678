/**
 * @generated SignedSource<<6ab48b93db05e2856f7e59aa9ccd2bd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTagInput = {
  name: string;
};
export type CompetitionTagInputMutation$variables = {
  connections: ReadonlyArray<string>;
  entityId: string;
  input: CreateTagInput;
};
export type CompetitionTagInputMutation$data = {
  readonly createTag: {
    readonly node: {
      readonly id: string;
      readonly name: string;
    };
  };
};
export type CompetitionTagInputMutation = {
  response: CompetitionTagInputMutation$data;
  variables: CompetitionTagInputMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "concreteType": "TagEdge",
  "kind": "LinkedField",
  "name": "createTag",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionTagInputMutation",
    "selections": [
      (v4/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionTagInputMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "appendEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "createTag",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "934bf81fc02d2ae84ef277ad387f3cfe",
    "id": null,
    "metadata": {},
    "name": "CompetitionTagInputMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionTagInputMutation(\n  $input: CreateTagInput!\n  $entityId: ID!\n) {\n  createTag(input: $input, entityId: $entityId) {\n    node {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7e515554c5d73b30b3ce2385bb1582fb";

export default node;
