import { FormattedNumber, defineMessage, useIntl } from "react-intl";
import { cn } from "../utils/tailwind";
import {
  MdArrowDownward,
  MdArrowUpward,
  MdKeyboardDoubleArrowUp,
  MdKeyboardDoubleArrowDown,
} from "react-icons/md";
import { graphql, useFragment, useMutation } from "react-relay";
import {
  VoteDisplayFragment$data,
  VoteDisplayFragment$key,
} from "./__generated__/VoteDisplayFragment.graphql";
import { useAuth } from "../utils/auth";
import { VoteKind } from "./__generated__/VoteDisplayPublishMutation.graphql";

const Fragment = graphql`
  fragment VoteDisplayFragment on Votable {
    id
    votes
    voted @ifAllowed {
      score
    }
    viewerCanVote: viewerCan(action: PUBLISH_VOTE)
  }
`;

const PublishMutation = graphql`
  mutation VoteDisplayPublishMutation($id: ID!, $kind: VoteKind!) {
    publishVote(id: $id, kind: $kind) {
      node {
        id
        ...VoteDisplayFragment
      }
    }
  }
`;

const ResetMutation = graphql`
  mutation VoteDisplayResetMutation($id: ID!) {
    resetVote(id: $id) {
      node {
        id
        ...VoteDisplayFragment
      }
    }
  }
`;

type VoteDisplayKind = "readOnly" | "vertical" | "horizontal";

export interface VoteDisplayProps {
  subject: VoteDisplayFragment$key;
  kind?: VoteDisplayKind;
}

export default function VoteDisplay({
  subject: subjectFragment,
  kind,
}: VoteDisplayProps) {
  const { isAuthenticated } = useAuth();
  const subject = useFragment(Fragment, subjectFragment);

  if (kind === "readOnly" || !isAuthenticated || !subject.viewerCanVote) {
    if (!subject.votes) {
      return null;
    }
    return <ReadonlyVoteDisplay {...subject} />;
  }

  switch (kind) {
    case "vertical":
      return <VerticalVoteDisplay {...subject} />;
    case "horizontal":
      return <HorizontalVoteDisplay {...subject} />;
    default:
      return <ReadonlyVoteDisplay {...subject} />;
  }
}

const ReadonlyVoteDisplay = ({ votes }: VoteDisplayFragment$data) => {
  const intl = useIntl();
  return (
    <div className="flex items-center border rounded-lg overflow-hidden">
      <span
        className={cn(
          "flex items-center border-gray-300",
          votes === 0 ? "border-0" : "border-r",
        )}
        title={intl.formatMessage({ defaultMessage: "votes" })}
      >
        <VoteAmount votes={votes} />
      </span>
      {votes > 0 ? (
        <MdArrowUpward className="px-2" size={READONLY_ARROW_ICON_SIZE} />
      ) : votes < 0 ? (
        <MdArrowDownward className="px-2" size={READONLY_ARROW_ICON_SIZE} />
      ) : null}
    </div>
  );
};

const HorizontalVoteDisplay = ({
  votes,
  id,
  voted,
}: VoteDisplayFragment$data) => {
  const intl = useIntl();
  const { score } = voted ?? { score: 0 };

  return (
    <div className="flex flex-row items-center border rounded-lg">
      <VoteButton
        id={id}
        score={score}
        kind="UPVOTE"
        className="rounded-l-lg"
      />
      <span
        className="border-l border-r border-gray-300"
        title={intl.formatMessage({ defaultMessage: "votes" })}
      >
        <VoteAmount votes={votes} />
      </span>
      <VoteButton
        id={id}
        score={score}
        kind="DOWNVOTE"
        className="rounded-r-lg"
      />
    </div>
  );
};

const VerticalVoteDisplay = ({
  votes,
  id,
  voted,
}: VoteDisplayFragment$data) => {
  const intl = useIntl();
  const { score } = voted ?? { score: 0 };

  return (
    <div className="flex flex-col items-center  border rounded-lg">
      <VoteButton
        id={id}
        score={score}
        kind="UPVOTE"
        className="rounded-t-lg"
      />
      <span
        className="border-t border-b border-gray-300"
        title={intl.formatMessage({ defaultMessage: "votes" })}
      >
        <VoteAmount votes={votes} />
      </span>
      <VoteButton
        id={id}
        score={score}
        kind="DOWNVOTE"
        className="rounded-b-lg"
      />
    </div>
  );
};

const VoteAmount = ({
  votes,
  className,
}: {
  votes: number;
  className?: string;
}) => (
  <p
    className={cn(
      "font-mono py-2 hover:bg-gray-100 text-center cursor-default",
      className,
    )}
    style={{ width: "5ch" }}
    onClick={(event) => event.stopPropagation()}
  >
    <FormattedNumber value={votes} notation="compact" />
  </p>
);

const UpvoteIcon = MdKeyboardDoubleArrowUp;
const DownvoteIcon = MdKeyboardDoubleArrowDown;

interface VoteButtonProps {
  id: ID;
  score: number;
  kind: VoteKind;
  className?: HTMLButtonElement["className"];
}

const VoteButton = (props: VoteButtonProps) => {
  const { kind, id } = props;
  const intl = useIntl();
  const [publishMutation, isPublishing] = useMutation(PublishMutation);
  const [resetMutation, isResetting] = useMutation(ResetMutation);
  const isCommiting = isPublishing || isResetting;
  const isActive = voteButtonIsActive(props);

  return (
    <button
      className={cn(
        "flex justify-center items-center w-full focus:outline-none focus:outline-offset-1 focus:border h-full px-3 py-2",
        voteButtonClassNames(props),
        props.className,
      )}
      title={intl.formatMessage(voteButtonTitle(kind))}
      disabled={isCommiting}
      onClick={() => {
        if (isActive) {
          resetMutation({
            variables: { id },
          });
        } else {
          publishMutation({
            variables: { id, kind },
          });
        }
      }}
    >
      <VoteIcon kind={kind} isActive={isActive} />
    </button>
  );
};

function voteButtonIsActive({ kind, score }: VoteButtonProps) {
  switch (kind) {
    case "UPVOTE":
      return score > 0;
    case "DOWNVOTE":
      return score < 0;
  }
}

function voteButtonClassNames(props: VoteButtonProps) {
  const isActive = voteButtonIsActive(props);
  return isActive
    ? "text-white bg-primary_hover hover:bg-primary focus:outline-primary_hover hover:border-primary focus:border-primary"
    : "hover:bg-gray-100 focus:outline-primary hover:text-primary focus:border-primary";
}

function voteButtonTitle(kind: VoteKind) {
  switch (kind) {
    case "UPVOTE":
      return defineMessage({ defaultMessage: "upvote" });
    case "DOWNVOTE":
      return defineMessage({ defaultMessage: "downvote" });
  }
}

const VoteIcon = ({
  kind,
  isActive,
}: {
  kind: VoteKind;
  isActive: boolean;
}) => {
  const className = cn(isActive ? "text-primary_text_active" : "text-gray-800");
  switch (kind) {
    case "UPVOTE":
      return <UpvoteIcon className={className} size={VOTE_ICON_SIZE} />;
    case "DOWNVOTE":
      return <DownvoteIcon className={className} size={VOTE_ICON_SIZE} />;
  }
};

const VOTE_ICON_SIZE = 24;
const READONLY_ARROW_ICON_SIZE = 34;
