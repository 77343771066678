/**
 * @generated SignedSource<<2eb62e46d6b01f0c8fc5e4cb0dd5e890>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Oauth2AuthorizeInput = {
  clientId: string;
  redirectUri?: any | null | undefined;
  state?: string | null | undefined;
};
export type OAuth2AuthorizePageMutation$variables = {
  input: Oauth2AuthorizeInput;
};
export type OAuth2AuthorizePageMutation$data = {
  readonly oauth2Authorize: {
    readonly clientError: boolean;
    readonly redirectUri: any | null | undefined;
    readonly unauthorized: boolean;
  };
};
export type OAuth2AuthorizePageMutation = {
  response: OAuth2AuthorizePageMutation$data;
  variables: OAuth2AuthorizePageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Oauth2AuthorizeOutput",
    "kind": "LinkedField",
    "name": "oauth2Authorize",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unauthorized",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientError",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "redirectUri",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OAuth2AuthorizePageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OAuth2AuthorizePageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8f9798e15463e6c8e58a66f0fecca3f",
    "id": null,
    "metadata": {},
    "name": "OAuth2AuthorizePageMutation",
    "operationKind": "mutation",
    "text": "mutation OAuth2AuthorizePageMutation(\n  $input: Oauth2AuthorizeInput!\n) {\n  oauth2Authorize(input: $input) {\n    unauthorized\n    clientError\n    redirectUri\n  }\n}\n"
  }
};
})();

(node as any).hash = "816033b886614cef2dee74b8a5792aa1";

export default node;
