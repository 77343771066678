/**
 * @generated SignedSource<<b10651df009c7f6db1ab143ac7fc800a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ForumDeleteButtonMutation$variables = {
  connections: ReadonlyArray<string>;
  id: string;
};
export type ForumDeleteButtonMutation$data = {
  readonly deleteForum: string;
};
export type ForumDeleteButtonMutation = {
  response: ForumDeleteButtonMutation$data;
  variables: ForumDeleteButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "deleteForum",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumDeleteButtonMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ForumDeleteButtonMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "deleteEdge",
        "key": "",
        "kind": "ScalarHandle",
        "name": "deleteForum",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ae453ca9e9741ef732d07c24f738827a",
    "id": null,
    "metadata": {},
    "name": "ForumDeleteButtonMutation",
    "operationKind": "mutation",
    "text": "mutation ForumDeleteButtonMutation(\n  $id: ID!\n) {\n  deleteForum(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "e6d21dbc91dbc857383590c2810cdf1d";

export default node;
