import { Environment, graphql } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { Params } from "react-router-dom";
import {
  EntitySubmissionCodePageQuery as EntitySubmissionCodePageQueryType,
  ProjectVersionFileKind,
} from "./__generated__/EntitySubmissionCodePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

const EntitySubmissionCodePageQuery = graphql`
  query EntitySubmissionCodePageQuery(
    $entity: UsernameOrID
    $slug: String!
    $version: Semver
    $fileKind: ProjectVersionFileKind!
  ) {
    competitionBySlug(slug: $slug) {
      slug
      title
      host {
        username
      }
      requiresApproval
      submission(entity: $entity) {
        entity {
          username
        }
        ...ProjectFileBrowserFragment
          @arguments(kind: $fileKind, version: $version)
        version(version: $version) {
          viewerCanApprove: viewerCan(action: CREATE_PROJECT_VERSION_APPROVAL)
          ...ApproveProjectVersionButtonFragment
          ...SubmissionStatusBadgeFragment
        }
      }
    }
  }
`;

export const parseParams = ({
  username,
  slug,
  version,
  kind,
}: Params<string>) => {
  if (!slug) {
    throw new Response("No slug", { status: 500 });
  }
  const fileKind = kind
    ? (kind.toUpperCase() as ProjectVersionFileKind)
    : "PACKAGE";
  return {
    entity: username,
    slug,
    version: version == "latest" ? undefined : version,
    fileKind,
  };
};

export function entitySubmissionCodePageQueryLoader(environment: Environment) {
  return ({ params }: LoaderArgs) => {
    return preload<EntitySubmissionCodePageQueryType>(
      environment,
      EntitySubmissionCodePageQuery,
      parseParams(params),
    );
  };
}

export const useEntitySubmissionCodePageQuery = () =>
  usePreloaded<EntitySubmissionCodePageQueryType>();
