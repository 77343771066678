import { formatSlug } from "./format";
import { assertNever } from "./helpers";

export type TopicParent = { competition: string } | { forum: string };

export function getTopicPath({
  topic,
  title,
  parent,
}: {
  topic: string;
  title: string;
  parent: TopicParent;
}) {
  if ("competition" in parent) {
    return `/competitions/${parent.competition}/discussions/${topic}/${formatSlug(title)}`;
  } else if ("forum" in parent) {
    return `/discussions/${parent.forum}/${topic}/${formatSlug(title)}`;
  } else {
    assertNever(parent);
  }
}

export function getTopicParent(
  competition: string | null | undefined,
  forum: string | null | undefined,
): TopicParent {
  if (competition) {
    return { competition };
  } else if (forum) {
    return { forum };
  }
  throw new Error("no topic parent found");
}
