import { useState } from "react";
import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import EventCompetitions from "../components/EventCompetitions";
import { useEventCompetitionsPageQuery } from "./loaders/EventCompetitionsPage";
import { EventCompetitionsPageCreateMutation as EventCompetitionsPageCreateMutationType } from "./__generated__/EventCompetitionsPageCreateMutation.graphql";
import Button from "../components/Button";
import ErrorPage from "./ErrorPage";
import CompetitionAutocomplete from "../components/CompetitionAutocomplete";
import { logger } from "../common/logger";
import { MetaLayout } from "../common/MetaLayout";

const EventCompetitionsPageCreateMutation = graphql`
  mutation EventCompetitionsPageCreateMutation(
    $eventId: ID!
    $competitionId: ID!
    $connections: [ID!]!
  ) {
    addEventCompetition(eventId: $eventId, competitionId: $competitionId)
      @prependEdge(connections: $connections) {
      node {
        id
        viewerCanDelete: viewerCan(action: REMOVE_EVENT_COMPETITION)
        competition {
          id
          slug
          title
          ...CompetitionCardFragment
        }
      }
    }
  }
`;

type FormData = {
  competitionId: string;
};

export default function EventCompetitionsPage() {
  const intl = useIntl();
  const { query } = useEventCompetitionsPageQuery();
  const event = query.eventBySlug;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [commitAddMutation, isAddMutationInFlight] =
    useMutation<EventCompetitionsPageCreateMutationType>(
      EventCompetitionsPageCreateMutation,
    );
  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }
  const errorMessages = {
    competitionId: {
      required: intl.formatMessage({
        defaultMessage: "Please select the username from the list",
      }),
    },
  };
  const onAddSubmit = handleSubmit(({ competitionId }) => {
    setFormError(null);
    commitAddMutation({
      variables: {
        eventId: event.id,
        competitionId,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventCompetitionsFragment_competitions",
          ),
          ConnectionHandler.getConnectionID(
            event.id,
            "EventCompetitionsCarouselFragment_competitions",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage: "Something went wrong",
          }),
        );
      },
    });
  });
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{eventTitle} | competitions" },
        { eventTitle: event.title },
      )}
    >
      {event.viewerCanEditCompetitions && (
        <div className="flex flex-col items-end pb-8">
          <form>
            <div className="flex flex-row space-x-2">
              <div>
                <CompetitionAutocomplete
                  query={query}
                  placeholder={intl.formatMessage({
                    defaultMessage: "competition-slug",
                  })}
                  {...register("competitionId", { required: true })}
                />
              </div>
              <div>
                <Button
                  kind="primary"
                  onClick={() => {
                    onAddSubmit();
                  }}
                  disabled={isAddMutationInFlight}
                >
                  <span className="sm:hidden">
                    <FormattedMessage defaultMessage="Add" />
                  </span>
                  <span className="hidden sm:inline">
                    <FormattedMessage defaultMessage="Add Competition" />
                  </span>
                </Button>
              </div>
            </div>
          </form>
          <div>
            {typeof errors.competitionId?.type === "string" && (
              <p className="text-red-500 text-sm">
                {
                  errorMessages.competitionId[
                    errors.competitionId
                      .type as keyof typeof errorMessages.competitionId
                  ]
                }
              </p>
            )}
            {formError && <p className="text-red-500 text-sm">{formError}</p>}
          </div>
        </div>
      )}
      <div>
        <EventCompetitions event={event} />
      </div>
    </MetaLayout>
  );
}
