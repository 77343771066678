import { graphql, Environment } from "react-relay";
import { preload, usePreloaded } from "../../utils/relay";
import { EventTimetableEditPageQuery as EventTimetableEditPageQueryType } from "./__generated__/EventTimetableEditPageQuery.graphql";
import { Params } from "react-router-dom";

export const EventTimetableEditPageQuery = graphql`
  query EventTimetableEditPageQuery($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      agenda
    }
  }
`;

export const eventTimetableEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: { params: Params<string> }) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventTimetableEditPageQueryType>(
      environment,
      EventTimetableEditPageQuery,
      { slug },
    );
  };
};

export const useEventTimetableEditPageQuery = () =>
  usePreloaded<EventTimetableEditPageQueryType>();
