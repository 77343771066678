/**
 * @generated SignedSource<<4c631e38212a2e1a8de939f4744ac68b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ForumEditPageQuery$variables = {
  slug: string;
};
export type ForumEditPageQuery$data = {
  readonly forumBySlug: {
    readonly guidelines: string | null | undefined;
    readonly icon: any | null | undefined;
    readonly id: string;
    readonly orderingPriority: number;
    readonly shortDescription: string;
    readonly slug: string;
    readonly title: string;
  } | null | undefined;
};
export type ForumEditPageQuery = {
  response: ForumEditPageQuery$data;
  variables: ForumEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Forum",
    "kind": "LinkedField",
    "name": "forumBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortDescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "guidelines",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "icon",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "orderingPriority",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForumEditPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForumEditPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ffe63745cc91577b32e7039eddb7bdd8",
    "id": null,
    "metadata": {},
    "name": "ForumEditPageQuery",
    "operationKind": "query",
    "text": "query ForumEditPageQuery(\n  $slug: String!\n) {\n  forumBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    guidelines\n    icon\n    orderingPriority\n  }\n}\n"
  }
};
})();

(node as any).hash = "19ea8520c2d4ea43289f907d1fb03164";

export default node;
