import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForumTopicsPageQuery } from "./loaders/ForumTopicsPage";
import ForumTopics from "../components/ForumTopics";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import Button from "../components/Button";
import VotableOrderSelect from "../components/VotableOrderSelect";
import { VotableOrder } from "../utils/votableOrder";

export default function ForumTopicsPage() {
  const { slug } = useParams();
  const intl = useIntl();
  const {
    query: { forumBySlug: forum },
    variables: { order: initialOrder },
  } = useForumTopicsPageQuery();
  const [order, setOrder] = useState<VotableOrder>(initialOrder);
  if (!forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Discussion not found",
        })}
      />
    );
  }
  return (
    <div>
      <div className="flex flex-row">
        <div className="flex flex-row w-full items-baseline space-x-4">
          <h1 className="text-2xl font-bold font-poppins">
            <FormattedMessage defaultMessage="Topics" />
          </h1>
          <VotableOrderSelect value={order} onChange={setOrder} />
        </div>
        {forum.viewerCanCreateTopic && (
          <div className="flex-grow-0">
            <Button to={`/discussions/${slug}/new`} state={{ order }}>
              <FormattedMessage defaultMessage="New Topic" />
            </Button>
          </div>
        )}
      </div>
      <div className="pt-4 pb-8">
        <ForumTopics forum={forum} order={order} />
      </div>
    </div>
  );
}
