/**
 * @generated SignedSource<<993499de8a2389c26cecdfe56cfda9ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberSelectEntityFragment$data = {
  readonly displayName: string;
  readonly id: string;
  readonly username: string;
  readonly " $fragmentType": "MemberSelectEntityFragment";
};
export type MemberSelectEntityFragment$key = {
  readonly " $data"?: MemberSelectEntityFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberSelectEntityFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberSelectEntityFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};

(node as any).hash = "08f166390d9dff3ae2c7c4c822afc1ce";

export default node;
