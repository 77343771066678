/**
 * @generated SignedSource<<5371575eb229c160b23b14e276c093de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScaffoldQuery$variables = Record<PropertyKey, never>;
export type ScaffoldQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderFragment">;
};
export type ScaffoldQuery = {
  response: ScaffoldQuery$data;
  variables: ScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScaffoldQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "HeaderFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ScaffoldQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": "canCreateEvent",
            "args": [
              {
                "kind": "Literal",
                "name": "action",
                "value": "CREATE_EVENT"
              }
            ],
            "kind": "ScalarField",
            "name": "can",
            "storageKey": "can(action:\"CREATE_EVENT\")"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1
          }
        ],
        "concreteType": "EventConnection",
        "kind": "LinkedField",
        "name": "events",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EventEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Event",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "events(first:1)"
      }
    ]
  },
  "params": {
    "cacheID": "2eb3e6c5bbb1fe0e579c4f8dec1a678b",
    "id": null,
    "metadata": {},
    "name": "ScaffoldQuery",
    "operationKind": "query",
    "text": "query ScaffoldQuery {\n  ...HeaderFragment\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  imageThumbnail\n}\n\nfragment HeaderFragment on Query {\n  viewer @ifAllowed {\n    id\n    displayName\n    username\n    canCreateEvent: can(action: CREATE_EVENT)\n    ...EntityProfilePicFragment_49kWkb\n  }\n  events(first: 1) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c2dedfa017522f06ed37508906b19c0";

export default node;
