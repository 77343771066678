import { PropsWithChildren as Props } from "react";
import { LocationContext } from "../../utils/location";

export default function LocationProvider({ children }: Props) {
  return (
    <LocationContext.Provider value={window.location}>
      {children}
    </LocationContext.Provider>
  );
}
