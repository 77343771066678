import {
  graphql,
  useMutation,
  useFragment,
  ConnectionHandler,
} from "react-relay";
import { TopicCommentsFormViewerFragment$key } from "./__generated__/TopicCommentsFormViewerFragment.graphql";
import { TopicCommentsFormTopicFragment$key } from "./__generated__/TopicCommentsFormTopicFragment.graphql";
import { TopicCommentsFormCreateCommentMutation as TopicCommentsFormCreateCommentMutationType } from "./__generated__/TopicCommentsFormCreateCommentMutation.graphql";
import CommentEditForm from "./CommentEditForm";
import EntityProfilePic from "./EntityProfilePic";
import { VotableOrder } from "../utils/votableOrder";

const TopicCommentsFormViewerFragment = graphql`
  fragment TopicCommentsFormViewerFragment on User {
    ...EntityProfilePicFragment @arguments(thumbnail: true)
  }
`;

const TopicCommentsFormTopicFragment = graphql`
  fragment TopicCommentsFormTopicFragment on Topic {
    id
  }
`;

const TopicCommentsFormCreateCommentMutation = graphql`
  mutation TopicCommentsFormCreateCommentMutation(
    $topicId: ID!
    $input: CreateCommentInput!
    $connections: [ID!]!
  ) {
    createCommentForTopic(topicId: $topicId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
        ...CommentFragment @arguments(bottomLevel: true)
      }
    }
  }
`;

interface Props {
  topic: TopicCommentsFormTopicFragment$key;
  viewer: TopicCommentsFormViewerFragment$key | null;
  order: VotableOrder;
}

export default function TopicCommentsForm({
  topic: topicFragment,
  viewer: viewerFragment,
  order,
}: Props) {
  const viewer = useFragment(TopicCommentsFormViewerFragment, viewerFragment);
  const { id } = useFragment(TopicCommentsFormTopicFragment, topicFragment);
  const [commitMutation, isMutationInFlight] =
    useMutation<TopicCommentsFormCreateCommentMutationType>(
      TopicCommentsFormCreateCommentMutation,
    );

  if (!viewer) {
    return null;
  }

  return (
    <div className="flex flex-row space-x-4 items-start">
      <EntityProfilePic entity={viewer} size="16" />
      <div className="flex-grow">
        <CommentEditForm
          isDisabled={isMutationInFlight}
          onSubmit={({ content, setFormError, resetForm }) => {
            commitMutation({
              variables: {
                topicId: id,
                input: {
                  content,
                },
                connections: [
                  ConnectionHandler.getConnectionID(
                    id,
                    "TopicCommentsFragment_comments",
                    { order },
                  ),
                ],
              },
              onError: (error) => {
                setFormError(error.message);
              },
              onCompleted: () => {
                resetForm();
              },
              updater: (store) => {
                const topicRecord = store.get(id);
                if (topicRecord) {
                  topicRecord.invalidateRecord();
                }
              },
            });
          }}
        />
      </div>
    </div>
  );
}
