/**
 * @generated SignedSource<<07d504e21e97971e37eca314417b95e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionRulesPageQuery$variables = {
  slug: string;
};
export type CompetitionRulesPageQuery$data = {
  readonly competitionBySlug: {
    readonly id: string;
    readonly latestRule: {
      readonly id: string;
      readonly text: string;
    };
    readonly title: string;
    readonly viewerCanAccept: boolean;
    readonly viewerCanUpdate: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CompetitionAcceptRulesButtonCompetitionFragment" | "MemberSelectCompetitionFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly id: string;
  };
};
export type CompetitionRulesPageQuery = {
  response: CompetitionRulesPageQuery$data;
  variables: CompetitionRulesPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v6 = {
  "alias": "viewerCanUpdate",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
},
v7 = {
  "alias": "viewerCanAccept",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "CREATE_COMPETITION_RULE_AGREEMENT"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"CREATE_COMPETITION_RULE_AGREEMENT\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionRulesPageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetitionAcceptRulesButtonCompetitionFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MemberSelectCompetitionFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionRulesPageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompetitionRule",
            "kind": "LinkedField",
            "name": "latestRule",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionRuleAgreement",
                "kind": "LinkedField",
                "name": "entityAgreement",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": "textareaMembers",
            "args": null,
            "concreteType": "CompetitionMembershipConnection",
            "kind": "LinkedField",
            "name": "members",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionMembershipEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CompetitionMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "entity",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v1/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e637c7dc3ef009c63de37fb6f8fb1f89",
    "id": null,
    "metadata": {},
    "name": "CompetitionRulesPageQuery",
    "operationKind": "query",
    "text": "query CompetitionRulesPageQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    id\n  }\n  competitionBySlug(slug: $slug) {\n    id\n    title\n    latestRule {\n      id\n      text\n    }\n    viewerCanUpdate: viewerCan(action: UPDATE_COMPETITION)\n    viewerCanAccept: viewerCan(action: CREATE_COMPETITION_RULE_AGREEMENT)\n    ...CompetitionAcceptRulesButtonCompetitionFragment\n    ...MemberSelectCompetitionFragment\n  }\n}\n\nfragment CompetitionAcceptRulesButtonCompetitionFragment on Competition {\n  id\n  latestRule {\n    id\n    entityAgreement @ifAllowed {\n      id\n      createdAt\n    }\n  }\n}\n\nfragment MemberSelectCompetitionFragment on Competition {\n  textareaMembers: members {\n    edges {\n      node {\n        entity {\n          __typename\n          id\n          ...MemberSelectEntityFragment\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment MemberSelectEntityFragment on Entity {\n  __isEntity: __typename\n  id\n  username\n  displayName\n}\n"
  }
};
})();

(node as any).hash = "f43715f175187bcb44d98e99bea490e1";

export default node;
