/**
 * @generated SignedSource<<5a10c7bd868125cf19b01cf35ca6aac2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileBrowserEntryKind = "DIRECTORY" | "FILE";
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type CompetitionCodeBlobPageQuery$variables = {
  fileKind: ProjectVersionFileKind;
  filePath?: string | null | undefined;
  parentPath?: string | null | undefined;
  slug: string;
};
export type CompetitionCodeBlobPageQuery$data = {
  readonly competitionBySlug: {
    readonly id: string;
    readonly title: string;
    readonly useCase: {
      readonly latest: {
        readonly fileByKind: {
          readonly browse: {
            readonly readMeta: {
              readonly kind: FileBrowserEntryKind;
              readonly " $fragmentSpreads": FragmentRefs<"FileViewerFragment">;
            } | null | undefined;
          };
          readonly " $fragmentSpreads": FragmentRefs<"FileBrowserFragment">;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type CompetitionCodeBlobPageQuery = {
  response: CompetitionCodeBlobPageQuery$data;
  variables: CompetitionCodeBlobPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filePath"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentPath"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "fileKind"
  }
],
v8 = {
  "kind": "Variable",
  "name": "path",
  "variableName": "parentPath"
},
v9 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "filePath"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v8/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentLength",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionCodeBlobPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "args": [
                          (v8/*: any*/)
                        ],
                        "kind": "FragmentSpread",
                        "name": "FileBrowserFragment"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileBrowser",
                        "kind": "LinkedField",
                        "name": "browse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v9/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "readMeta",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "FileViewerFragment"
                                  }
                                ],
                                "type": "FileBrowserFileEntry",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CompetitionCodeBlobPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v7/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileBrowser",
                        "kind": "LinkedField",
                        "name": "browse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v11/*: any*/),
                            "concreteType": "FileBrowserEntryConnection",
                            "kind": "LinkedField",
                            "name": "readDir",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FileBrowserEntryEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v12/*: any*/),
                                      (v13/*: any*/),
                                      (v10/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v14/*: any*/),
                                          (v15/*: any*/)
                                        ],
                                        "type": "FileBrowserFileEntry",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v11/*: any*/),
                            "filters": [
                              "path"
                            ],
                            "handle": "connection",
                            "key": "FileBrowser_readDir",
                            "kind": "LinkedHandle",
                            "name": "readDir"
                          },
                          {
                            "alias": null,
                            "args": (v9/*: any*/),
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "readMeta",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v10/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v13/*: any*/),
                                  (v14/*: any*/),
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "downloadUrl",
                                    "storageKey": null
                                  }
                                ],
                                "type": "FileBrowserFileEntry",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f634dce46d2ea0867ffc272063bc271",
    "id": null,
    "metadata": {},
    "name": "CompetitionCodeBlobPageQuery",
    "operationKind": "query",
    "text": "query CompetitionCodeBlobPageQuery(\n  $slug: String!\n  $fileKind: ProjectVersionFileKind!\n  $parentPath: String\n  $filePath: String\n) {\n  competitionBySlug(slug: $slug) {\n    id\n    title\n    useCase {\n      latest {\n        fileByKind(kind: $fileKind) {\n          ...FileBrowserFragment_1uSKKa\n          browse {\n            readMeta(path: $filePath) {\n              __typename\n              kind\n              ... on FileBrowserFileEntry {\n                ...FileViewerFragment\n              }\n            }\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FileBrowserFragment_1uSKKa on ProjectVersionFile {\n  browse {\n    readDir(path: $parentPath, first: 100) {\n      edges {\n        node {\n          __typename\n          name\n          kind\n          ... on FileBrowserFileEntry {\n            contentType\n            contentLength\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  id\n}\n\nfragment FileViewerFragment on FileBrowserFileEntry {\n  name\n  contentType\n  contentLength\n  downloadUrl\n}\n"
  }
};
})();

(node as any).hash = "9b22f801d48f95ba58da271e11c62563";

export default node;
