import { Environment, graphql } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { EntitySubmissionsPageQuery as EntitySubmissionsPageQueryType } from "./__generated__/EntitySubmissionsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntitySubmissionsPageQueryType };

export const EntitySubmissionsPageQuery = graphql`
  query EntitySubmissionsPageQuery($username: String!) {
    entityByUsername(username: $username) {
      submissions {
        nodes {
          id
          competition {
            slug
            title
            banner
          }
          latest {
            files {
              downloadUrl @ifAllowed
            }
            createdAt
            evaluation {
              score
              error
            }
          }
        }
      }
    }
  }
`;

export function entitySubmissionsPageQueryLoader(environment: Environment) {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<EntitySubmissionsPageQueryType>(
      environment,
      EntitySubmissionsPageQuery,
      { username },
    );
  };
}

export const useEntitySubmissionsPageQuery = () =>
  usePreloaded<EntitySubmissionsPageQueryType>();
