/**
 * @generated SignedSource<<47b73c15f5fb78bca591fe324c6ce462>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompetitionTopicCreatePageQuery$variables = {
  slug: string;
};
export type CompetitionTopicCreatePageQuery$data = {
  readonly competitionBySlug: {
    readonly id: string;
    readonly slug: string;
  } | null | undefined;
};
export type CompetitionTopicCreatePageQuery = {
  response: CompetitionTopicCreatePageQuery$data;
  variables: CompetitionTopicCreatePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Competition",
    "kind": "LinkedField",
    "name": "competitionBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionTopicCreatePageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionTopicCreatePageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f4c9482e88a8937242c24ccdc7a01f59",
    "id": null,
    "metadata": {},
    "name": "CompetitionTopicCreatePageQuery",
    "operationKind": "query",
    "text": "query CompetitionTopicCreatePageQuery(\n  $slug: String!\n) {\n  competitionBySlug(slug: $slug) {\n    id\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fea0423a9f2fdfd83e80681f9eb2297";

export default node;
