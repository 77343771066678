export interface Comparator<A> {
  (lhs: A, rhs: A): number;
}

const ComparatorFns = {
  descending<A>(cmp: Comparator<A>): Comparator<A> {
    return (l, r) => {
      const x = cmp(l, r);
      if (x > 0) return -1;
      if (x < 0) return 1;
      return 0;
    };
  },

  by<A, K>(ak: (a: A) => K, cmp: Comparator<K>): Comparator<A> {
    return (l, r) => cmp(ak(l), ak(r));
  },

  andThen<A>(...cmps: Comparator<A>[]): Comparator<A> {
    return (l, r) => {
      let x = 0;
      for (const cmp of cmps) {
        x = cmp(l, r);
        if (x !== 0) return x;
      }
      return x;
    };
  },

  minimum<A>(xs: Iterable<A>, cmp: Comparator<A>): A | undefined {
    let min: A | undefined;
    for (const x of xs) {
      if (typeof min === "undefined" || cmp(x, min) < 0) {
        min = x;
      }
    }
    return min;
  },

  maximum<A>(xs: Iterable<A>, cmp: Comparator<A>): A | undefined {
    return ComparatorFns.minimum(xs, ComparatorFns.descending(cmp));
  },
};

const NUMBER_COMPARATOR: Comparator<number> = (l, r) => l - r;
const STRING_COMPARATOR: Comparator<string> = (l, r) => l.localeCompare(r);
const DATE_COMPARATOR = ComparatorFns.by(
  (date: Date) => +date,
  NUMBER_COMPARATOR,
);

export const Comparator = Object.freeze({
  ...ComparatorFns,
  number: NUMBER_COMPARATOR,
  string: STRING_COMPARATOR,
  date: DATE_COMPARATOR,
});
