import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import Button from "../components/Button";
import TextInput from "../components/TextInput";

export interface FormData {
  username: string;
}

interface Props {
  username: string;
  disabled?: boolean;
  onSubmit: (
    setFormError: React.Dispatch<React.SetStateAction<string | undefined>>,
  ) => void;
}

export default function EntityDeleteForm({
  username,
  disabled = false,
  onSubmit,
}: Props) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const doSubmit = handleSubmit(() => {
    onSubmit(setFormError);
  });

  const errorMessages = {
    required: intl.formatMessage({ defaultMessage: "Username is required" }),
    matches: intl.formatMessage(
      { defaultMessage: "Username must match {username}" },
      { username },
    ),
  };

  const usernameError =
    typeof errors.username?.type === "string" &&
    errorMessages[errors.username.type as keyof typeof errorMessages];

  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <h3 className="text-lg font-medium leading-6 text-red-400">
          <FormattedMessage defaultMessage="Danger Zone" />
        </h3>
        <p>
          <FormattedMessage
            defaultMessage="Once you delete your account, there is no going back. Please be certain. To continue type <b>{username}</b> in the box below."
            values={{ username, b: (chunks) => <b>{chunks}</b> }}
          />
        </p>
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        {usernameError && (
          <p className="pt-1 text-sm text-red-500">{usernameError}</p>
        )}
      </div>
      <div>
        <TextInput
          aria-invalid={errors.username ? "true" : "false"}
          placeholder={username}
          {...register("username", {
            setValueAs: (value) => value.trim(),
            required: true,
            validate: {
              matches: (value) => value === username,
            },
          })}
        />
      </div>
      <div>
        <Button kind="danger" onClick={doSubmit} disabled={disabled}>
          <FormattedMessage defaultMessage="Delete Forever" />
        </Button>
      </div>
    </div>
  );
}
