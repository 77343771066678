import { graphql, useMutation } from "react-relay";
import { CompetitionOverviewPageUpdateCompetitionMutation as CompetitionOverviewPageUpdateCompetitionMutationType } from "./__generated__/CompetitionOverviewPageUpdateCompetitionMutation.graphql";
import { useCompetitionOverviewPageQuery } from "./loaders/CompetitionOverviewPage";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import EditMarkdownSection from "../components/EditMarkdownSection";
import { logger } from "../common/logger";

const CompetitionOverviewPageUpdateCompetitionMutation = graphql`
  mutation CompetitionOverviewPageUpdateCompetitionMutation(
    $id: ID!
    $input: UpdateCompetitionInput!
  ) {
    updateCompetition(id: $id, input: $input) {
      node {
        id
        description
      }
    }
  }
`;

export default function CompetitionOverviewPage() {
  const intl = useIntl();
  const { query } = useCompetitionOverviewPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionOverviewPageUpdateCompetitionMutationType>(
      CompetitionOverviewPageUpdateCompetitionMutation,
    );
  if (!query.competitionBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  const competition = query.competitionBySlug;
  return (
    <EditMarkdownSection
      canUploadFiles
      members={{
        kind: "competition",
        members: query.competitionBySlug,
      }}
      canEdit={competition.viewerCanUpdate}
      disabled={isMutationInFlight}
      defaultValue={competition.description || ""}
      fallback={
        <p className="text-gray-400">
          <FormattedMessage defaultMessage="No description" />
        </p>
      }
      onSubmit={(value, setError, onCompleted) => {
        commitMutation({
          variables: {
            id: competition.id,
            input: {
              description: value || null,
            },
          },
          onError: (error) => {
            logger.error(error);
            setError(
              intl.formatMessage({
                defaultMessage:
                  "An error occurred while saving the description. Please, try again later.",
              }),
            );
          },
          onCompleted,
        });
      }}
    />
  );
}
