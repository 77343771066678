import { useSearchParams } from "react-router-dom";
import { useJoinParisHackPageQuery } from "./loaders/JoinParisHackPage";
import { useAuth } from "../utils/auth";
import Button from "../components/Button";
import EntityProfilePic from "../components/EntityProfilePic";
import { FormattedMessage } from "react-intl";

const buttonClass =
  "bg-white !text-midnight font-poppins font-bold text-lg px-8 py-4 rounded-2xl hover:bg-purple duration-500";

function JoinParisHackLoggedIn() {
  const { query } = useJoinParisHackPageQuery();
  const profile_pic = (
    <div className="inline-block">
      <EntityProfilePic entity={query.viewer} size="6" />
    </div>
  );
  const email = encodeURIComponent(query.viewer.email);
  const name = encodeURIComponent(query.viewer.displayName);
  const user_id = encodeURIComponent(query.viewer.id);
  const username = encodeURIComponent(query.viewer.username);
  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="flex space-x-2 items-center">
        <div>
          <FormattedMessage defaultMessage="Logged in as" />
        </div>
        <div>{profile_pic}</div>
        <div>{query.viewer.username}</div>
      </div>
      <a
        target="_parent"
        href={`https://aqora.typeform.com/to/WJuTzAbj#email=${email}&name=${name}&user_id=${user_id}&username=${username}`}
      >
        <Button className={buttonClass}>
          <FormattedMessage defaultMessage="Join the Hackathon!" />
        </Button>
      </a>
    </div>
  );
}

function JoinParisHackNotLoggedIn() {
  const [searchParams, _setSearchParams] = useSearchParams();
  const params = searchParams.has("login_redirect")
    ? `?login_redirect=${encodeURIComponent(
        searchParams.get("login_redirect")!,
      )}`
    : "";
  return (
    <a target="_parent" href={`/login${params}`}>
      <Button className={buttonClass}>
        <FormattedMessage defaultMessage="Login/Signup to Join" />
      </Button>
    </a>
  );
}

export default function JoinParisHackPage() {
  const { isAuthenticated } = useAuth();
  return (
    <div className="flex w-full h-full justify-center items-center text-white">
      <div>
        {isAuthenticated ? (
          <JoinParisHackLoggedIn />
        ) : (
          <JoinParisHackNotLoggedIn />
        )}{" "}
      </div>
    </div>
  );
}
