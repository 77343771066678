import { graphql, Environment } from "react-relay";
import { preload, usePreloaded } from "../../utils/relay";
import { Params } from "react-router-dom";
import { EventTimetablePageQuery as EventTimetablePageQueryType } from "./__generated__/EventTimetablePageQuery.graphql";

export const EventTimetablePageQuery = graphql`
  query EventTimetablePageQuery($slug: String!) {
    eventBySlug(slug: $slug) {
      id
      title
      viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)
      agenda
      ...EventTimetableFragment
    }
  }
`;

export const eventTimetablePageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: { params: Params<string> }) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<EventTimetablePageQueryType>(
      environment,
      EventTimetablePageQuery,
      { slug },
    );
  };
};

export const useEventTimetablePageQuery = () =>
  usePreloaded<EventTimetablePageQueryType>();
