import { graphql, useMutation, useFragment } from "react-relay";
import { ConnectionHandler, UploadableMap } from "relay-runtime";
import { OrganizationEditFormFragment$key } from "./__generated__/OrganizationEditFormFragment.graphql";
import {
  OrganizationEditFormMutation$variables,
  OrganizationEditFormMutation as OrganizationEditFormMutationType,
} from "./__generated__/OrganizationEditFormMutation.graphql";
import { OrganizationEditFormDeleteMutation as OrganizationEditFormDeleteMutationType } from "./__generated__/OrganizationEditFormDeleteMutation.graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import EntityEditForm, {
  FormData,
  SubmitData,
} from "../components/EntityEditForm";
import EntityDeleteForm from "./EntityDeleteForm";
import { useAuth } from "../utils/auth";
import { logger } from "../common/logger";

const OrganizationEditFormFragment = graphql`
  fragment OrganizationEditFormFragment on Organization {
    ...EntityProfilePicFragment @arguments(thumbnail: false)
    id
    displayName
    username
    linkedin
    github
    location
    website
    bio
    image
    viewerCanDelete: viewerCan(action: DELETE_ORGANIZATION)
  }
`;

const OrganizationEditFormMutation = graphql`
  mutation OrganizationEditFormMutation(
    $id: ID!
    $input: UpdateOrganizationInput!
  ) {
    updateOrganization(id: $id, input: $input) {
      node {
        id
        displayName
        username
        linkedin
        github
        location
        website
        bio
        image
        imageThumbnail
        createdAt
      }
    }
  }
`;

const OrganizationEditFormDeleteMutation = graphql`
  mutation OrganizationEditFormDeleteMutation($id: ID!, $connections: [ID!]!) {
    deleteOrganization(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  organization: OrganizationEditFormFragment$key;
}

export default function OrganizationEditForm({
  organization: organizationFragment,
}: Props) {
  const { userId } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const organization = useFragment(
    OrganizationEditFormFragment,
    organizationFragment,
  );
  const form = useForm<FormData>();
  const dirtyFields = form.formState.dirtyFields;

  const [commitEditMutation, isEditMutationInFlight] =
    useMutation<OrganizationEditFormMutationType>(OrganizationEditFormMutation);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<OrganizationEditFormDeleteMutationType>(
      OrganizationEditFormDeleteMutation,
    );

  const onEditSubmit = ({
    data,
    image,
    setFormError,
  }: SubmitData<FormData>) => {
    setFormError(undefined);
    const variables: OrganizationEditFormMutation$variables = {
      id: organization.id,
      input: {},
    };
    for (const dirtyField in dirtyFields) {
      const field: keyof FormData = dirtyField as keyof FormData;
      if (dirtyFields[field]) {
        if (!data[field]) {
          variables.input[field] = null;
        } else {
          variables.input[field] = data[field];
        }
      }
    }
    variables.input.image = image.variable;

    const uploadables: UploadableMap = {};
    if (image.uploadable) {
      uploadables["variables.input.image"] = image.uploadable;
    }
    commitEditMutation({
      variables,
      uploadables,
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not update account. username or email may already exist. Check and try again.",
          }),
        );
      },
      onCompleted: (results) => {
        navigate(`/${results.updateOrganization.node.username}`);
      },
    });
  };

  const onDeleteSubmit = (setFormError: (error: string) => void) => {
    const connections = userId
      ? [
          ConnectionHandler.getConnectionID(
            userId,
            "UserOrganizationsFragment_organizations",
          ),
        ]
      : [];
    commitDeleteMutation({
      variables: { id: organization.id, connections },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not delete organization. Check and try again.",
          }),
        );
      },
      onCompleted: () => {
        navigate("/");
      },
    });
  };

  return (
    <div className="pb-10">
      <EntityEditForm
        form={form}
        onSubmit={onEditSubmit}
        isDisabled={isEditMutationInFlight}
        defaultValues={organization}
      />
      {organization.viewerCanDelete && (
        <>
          <div className="py-5">
            <hr />
          </div>
          <EntityDeleteForm
            disabled={isDeleteMutationInFlight}
            username={organization.username}
            onSubmit={onDeleteSubmit}
          />
        </>
      )}
    </div>
  );
}
