/**
 * @generated SignedSource<<fe219994f3d7f372b1d08b0b70532e12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingsNotificationsPageQuery$variables = Record<PropertyKey, never>;
export type UserSettingsNotificationsPageQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserSettingsNotificationsPageFragment">;
  };
};
export type UserSettingsNotificationsPageQuery = {
  response: UserSettingsNotificationsPageQuery$data;
  variables: UserSettingsNotificationsPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsNotificationsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserSettingsNotificationsPageFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsNotificationsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserNotifications",
            "kind": "LinkedField",
            "name": "notifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec1a289bb3953800c34638ff592dfdd6",
    "id": null,
    "metadata": {},
    "name": "UserSettingsNotificationsPageQuery",
    "operationKind": "query",
    "text": "query UserSettingsNotificationsPageQuery {\n  viewer {\n    id\n    ...UserSettingsNotificationsPageFragment\n  }\n}\n\nfragment UserSettingsNotificationsPageFragment on User {\n  id\n  notifications {\n    enabled\n    disabled\n  }\n}\n"
  }
};
})();

(node as any).hash = "fca2b91a7e7afa4122898990fc4cbd94";

export default node;
