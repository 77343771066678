import React, { useState } from "react";
import {
  graphql,
  useFragment,
  usePaginationFragment,
  useLazyLoadQuery,
} from "react-relay";
import { FormattedMessage } from "react-intl";
import { CommentCommentsLazyQuery as CommentCommentsLazyQueryType } from "./__generated__/CommentCommentsLazyQuery.graphql";
import { CommentFragment$key } from "./__generated__/CommentFragment.graphql";
import { CommentCommentsFragment$key } from "./__generated__/CommentCommentsFragment.graphql";
import CommentDisplay from "./CommentDisplay";
import Button from "./Button";
import Suspense from "../common/Suspense";
import CommentReply from "./CommentReply";
import LoadMore from "./LoadMore";
import { VotableOrder } from "../utils/votableOrder";

const CommentFragment = graphql`
  fragment CommentFragment on Comment
  @argumentDefinitions(
    topLevel: { type: "Boolean", defaultValue: false }
    level1: { type: "Boolean", defaultValue: false }
    level2: { type: "Boolean", defaultValue: false }
    level3: { type: "Boolean", defaultValue: false }
    bottomLevel: { type: "Boolean", defaultValue: false }
    leafLevel: { type: "Boolean", defaultValue: false }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    id
    ...CommentDisplayFragment
    ...CommentReplyFragment
    ...CommentCommentsFragment
      @include(if: $topLevel)
      @arguments(topLevel: true, order: $order)
    ...CommentCommentsFragment
      @include(if: $level1)
      @arguments(level1: true, order: $order)
    ...CommentCommentsFragment
      @include(if: $level2)
      @arguments(level2: true, order: $order)
    ...CommentCommentsFragment
      @include(if: $level3)
      @arguments(level3: true, order: $order)
    ...CommentCommentsFragment
      @include(if: $bottomLevel)
      @arguments(bottomLevel: true, order: $order)
    numChildren @include(if: $leafLevel)
  }
`;

const CommentCommentsFragment = graphql`
  fragment CommentCommentsFragment on Comment
  @refetchable(queryName: "CommentCommentsFragmentPaginationQuery")
  @argumentDefinitions(
    topLevel: { type: "Boolean", defaultValue: false }
    level1: { type: "Boolean", defaultValue: false }
    level2: { type: "Boolean", defaultValue: false }
    level3: { type: "Boolean", defaultValue: false }
    bottomLevel: { type: "Boolean", defaultValue: false }
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
    order: { type: "VotableOrder", defaultValue: HOT }
  ) {
    children(first: $count, after: $cursor, order: $order)
      @connection(key: "CommentCommentsFragment_children") {
      __id
      edges {
        node {
          id
          ...CommentFragment
            @include(if: $topLevel)
            @arguments(level1: true, order: $order)
          ...CommentFragment
            @include(if: $level1)
            @arguments(level2: true, order: $order)
          ...CommentFragment
            @include(if: $level2)
            @arguments(level3: true, order: $order)
          ...CommentFragment
            @include(if: $level3)
            @arguments(bottomLevel: true, order: $order)
          ...CommentFragment
            @include(if: $bottomLevel)
            @arguments(leafLevel: true, order: $order)
        }
      }
    }
  }
`;

const CommentCommentsLazyQuery = graphql`
  query CommentCommentsLazyQuery($commentId: ID!, $order: VotableOrder) {
    node(id: $commentId) {
      ... on Comment {
        ...CommentFragment @arguments(topLevel: true, order: $order)
      }
    }
  }
`;

function LazyCommentRepliesContent({
  commentId,
  order,
}: {
  commentId: string;
  order?: VotableOrder;
}) {
  const { node: comment } = useLazyLoadQuery<CommentCommentsLazyQueryType>(
    CommentCommentsLazyQuery,
    { commentId, order },
  );
  return <CommentReplies comment={comment} order={order} />;
}

interface Props {
  comment: CommentFragment$key;
  order?: VotableOrder;
  highlighted?: boolean;
}

function CommentReplies({
  comment: commentFragment,
  order,
  highlighted,
}: Props) {
  const comment = useFragment(CommentFragment, commentFragment);
  const childrenAreLazyLoaded = comment.numChildren != undefined;

  const {
    data: commentPagination,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    CommentCommentsFragment,
    childrenAreLazyLoaded ? null : (comment as CommentCommentsFragment$key),
  );
  const commentEdges =
    (commentPagination &&
      commentPagination.children &&
      commentPagination.children.edges) ||
    [];

  return (
    <>
      {commentEdges.length > 0 && (
        <div className="py-2">
          <hr />
        </div>
      )}
      {commentEdges.map(({ node: comment }, index) => (
        <React.Fragment key={comment.id}>
          <Comment
            comment={comment}
            key={comment.id}
            order={order}
            highlighted={highlighted}
          />
          {index < commentEdges.length - 1 && (
            <div className="py-2">
              <hr />
            </div>
          )}
        </React.Fragment>
      ))}
      <LoadMore hasMore={hasNext} isLoading={isLoadingNext} loadMore={loadNext}>
        <FormattedMessage defaultMessage="Load more replies" />
      </LoadMore>
    </>
  );
}

export default function Comment({
  comment: commentFragment,
  order,
  highlighted,
}: Props) {
  const comment = useFragment(CommentFragment, commentFragment);
  const childrenAreLazyLoaded = comment.numChildren != undefined;
  const [showReplies, setShowReplies] = useState(false);
  const loadReplies = () => {
    if (childrenAreLazyLoaded) {
      setShowReplies(true);
    }
  };
  const numChildren = comment.numChildren || 0;
  return (
    <CommentDisplay comment={comment} highlighted={highlighted}>
      <div className="flex gap-6 items-center">
        <CommentReply
          comment={comment}
          loadReplies={loadReplies}
          order={order}
        />
      </div>
      <CommentReplies comment={commentFragment} order={order} />
      {childrenAreLazyLoaded && !showReplies && numChildren > 0 && (
        <Button kind="text" onClick={loadReplies}>
          <FormattedMessage defaultMessage="Load replies" />
        </Button>
      )}
      {childrenAreLazyLoaded && showReplies && (
        <Suspense>
          <LazyCommentRepliesContent commentId={comment.id} order={order} />
        </Suspense>
      )}
    </CommentDisplay>
  );
}
