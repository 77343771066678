import { useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import FormGroup from "../components/FormGroup";
import TextInput from "../components/TextInput";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import defaultIcon from "../assets/default-forum-icon.svg";
import { useUpload, useImagePreview } from "../utils/hooks";
import { maxFileSize } from "../utils/validation";
import AspectDiv from "../components/AspectDiv";
import SlugInput from "./SlugInput";

type FormData = {
  title: string;
  shortDescription: string;
  guidelines: string | null;
  slug: string;
  icon: FileList;
  orderingPriority: number;
};

interface DefaultValues {
  title?: string;
  shortDescription?: string;
  guidelines?: string | null;
  slug?: string;
  icon?: string;
  orderingPriority?: number;
}

interface SubmitData {
  data: FormData;
  icon: { variable: null | undefined; uploadable: File | undefined };
  dirtyFields: Partial<Readonly<Record<keyof FormData, boolean | undefined>>>;
  setFormError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface Props {
  onSubmit: (data: SubmitData) => void;
  isDisabled?: boolean;
  autoSlug?: boolean;
  routePrefix: string;
  defaultValues?: DefaultValues;
}

export default function ForumEditForm({
  onSubmit,
  isDisabled = false,
  autoSlug = false,
  routePrefix,
  defaultValues,
}: Props) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    resetField,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const icon = useUpload(defaultValues?.icon, watch("icon"), () => {
    resetField("icon");
  });
  const iconPreview = useImagePreview(icon.value);
  const selectedIcon =
    (icon.isDirty ? iconPreview : defaultValues?.icon) || defaultIcon;
  const doSubmit = handleSubmit((data) => {
    setFormError(undefined);
    onSubmit({
      data,
      dirtyFields,
      icon: { variable: icon.variable, uploadable: icon.value },
      setFormError,
    });
  });
  const errorMessages = {
    title: {
      required: intl.formatMessage({
        defaultMessage: "Title is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Title cannot be longer than 50 characters",
      }),
    },
    shortDescription: {
      required: intl.formatMessage({
        defaultMessage: "Description is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "Description cannot be longer than 255 characters",
      }),
    },
    slug: {
      required: intl.formatMessage({
        defaultMessage: "URL is required",
      }),
      maxLength: intl.formatMessage({
        defaultMessage: "URL cannot be longer than 255 characters",
      }),
      pattern: intl.formatMessage({
        defaultMessage: "URL is invalid",
      }),
    },
    icon: {
      size: intl.formatMessage({
        defaultMessage: "Image must be at most 2 MB",
      }),
    },
    guidelines: {
      maxLength: intl.formatMessage({
        defaultMessage: "Guidelines cannot be longer than 1000 characters",
      }),
    },
  };
  return (
    <form onSubmit={doSubmit}>
      <div className="space-y-6">
        {formError && <p className="pt-1 text-sm text-red-500">{formError}</p>}
        <div className="flex flex-col space-y-3">
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Title" })}
            error={
              typeof errors.title?.type === "string" &&
              errorMessages.title[
                errors.title.type as keyof typeof errorMessages.title
              ]
            }
          >
            <TextInput
              aria-invalid={errors.title ? "true" : "false"}
              defaultValue={defaultValues?.title}
              {...register("title", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 50,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Short Description" })}
            error={
              typeof errors.shortDescription?.type === "string" &&
              errorMessages.shortDescription[
                errors.shortDescription
                  .type as keyof typeof errorMessages.shortDescription
              ]
            }
          >
            <TextArea
              aria-invalid={errors.shortDescription ? "true" : "false"}
              defaultValue={defaultValues?.shortDescription}
              {...register("shortDescription", {
                setValueAs: (value) => value.trim(),
                required: true,
                maxLength: 255,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Guidelines" })}
            error={
              typeof errors.guidelines?.type === "string" &&
              errorMessages.guidelines[
                errors.guidelines.type as keyof typeof errorMessages.guidelines
              ]
            }
          >
            <TextArea
              aria-invalid={errors.guidelines ? "true" : "false"}
              defaultValue={defaultValues?.guidelines ?? undefined}
              {...register("guidelines", {
                setValueAs: (value) => value.trim(),
                maxLength: 1000,
              })}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "URL" })}
            error={
              typeof errors.slug?.type === "string" &&
              errorMessages.slug[
                errors.slug.type as keyof typeof errorMessages.slug
              ]
            }
          >
            <SlugInput
              control={control}
              name={"slug"}
              defaultValue={defaultValues?.slug}
              watchName={"title"}
              watchDefaultValue={defaultValues?.title}
              prefix={`${window.location.host}/${routePrefix}/`}
              auto={autoSlug}
              rules={{ required: true }}
            />
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Icon" })}
            error={
              typeof errors.icon?.type === "string" &&
              errorMessages.icon[
                errors.icon.type as keyof typeof errorMessages.icon
              ]
            }
          >
            <div className="pb-4 w-64">
              <AspectDiv className="rounded-lg" ratio={1}>
                <img
                  src={selectedIcon}
                  className="h-full w-full rounded-lg object-cover object-center"
                />
              </AspectDiv>
            </div>
            <div>
              <input
                type="file"
                accept="image/png, image/jpeg"
                aria-invalid={errors.icon ? "true" : "false"}
                {...register("icon", {
                  validate: {
                    size: maxFileSize(1024 * 1024 * 2), // 2 MB
                  },
                })}
              />
              <div className="flex flex-row">
                {icon.isDirty && (
                  <Button kind="text" onClick={icon.reset}>
                    <FormattedMessage defaultMessage="Reset" />
                  </Button>
                )}
                {icon.canDelete && (
                  <Button kind="text" onClick={icon.deleteImage}>
                    <FormattedMessage defaultMessage="Delete" />
                  </Button>
                )}
              </div>
            </div>
          </FormGroup>
          <FormGroup
            label={intl.formatMessage({ defaultMessage: "Ordering Priority" })}
          >
            <TextInput
              defaultValue={defaultValues?.orderingPriority || 0}
              type="number"
              {...register("orderingPriority", {
                setValueAs: (value) => parseInt(value.trim() || "0"),
              })}
            />
          </FormGroup>
          <div className="pt-2 pb-8">
            <Button kind="primary" onClick={doSubmit} disabled={isDisabled}>
              <FormattedMessage defaultMessage="Save" />
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
