import * as Radix from "@radix-ui/react-popover";
import { cn } from "../utils/tailwind";
import { forwardRef } from "react";

export const Root = Radix.Root;

export const Trigger = Radix.Trigger;

export const Anchor = Radix.Anchor;

export const Arrow = (props: Radix.PopoverArrowProps) => (
  <Radix.Arrow {...props} className="opacity-10" />
);

export const Portal = Radix.Portal;

export const Content = forwardRef<HTMLDivElement, Radix.PopoverContentProps>(
  ({ className, sideOffset, ...props }, ref) => (
    <Radix.Content
      {...props}
      ref={ref}
      className={cn("rounded shadow bg-white px-4 py-2", className)}
      sideOffset={sideOffset ?? 5}
    />
  ),
);
