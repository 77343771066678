export default function Logo() {
  return (
    <svg
      className="w-full"
      viewBox="0 -20 990 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="145.216"
        cy="34.141"
        r="32.5438"
        transform="rotate(90 145.216 34.141)"
        fill="#0A1128"
      />
      <circle
        cx="34.1242"
        cy="145.234"
        r="32.5438"
        transform="rotate(90 34.1242 145.234)"
        fill="#0A1128"
      />
      <circle
        cx="49.1982"
        cy="49.1979"
        r="34.7882"
        transform="rotate(45 49.1982 49.1979)"
        fill="#0A1128"
      />
      <circle
        cx="130.138"
        cy="130.14"
        r="34.7882"
        transform="rotate(45 130.138 130.14)"
        fill="#0A1128"
      />
      <path
        d="M73.7995 24.6013C84.9087 35.7104 90.2285 56.9005 106.334 73.0057C122.439 89.1109 143.629 94.4304 154.738 105.54C135.694 124.584 115.856 144.422 105.54 154.738C94.4307 143.628 89.1112 122.439 73.006 106.333C56.9008 90.2282 35.7106 84.9082 24.6016 73.7992C33.7266 64.6734 60.3095 38.091 73.7995 24.6013Z"
        fill="url(#paint0_linear_224_6742)"
        fillOpacity="0.69"
      />
      <path
        d="M346.358 162.043C305.494 162.043 277.184 131.863 277.184 89.3962C277.184 46.6629 305.494 16.2153 344.756 16.2153C373.868 16.2153 389.359 34.9112 393.632 42.1224H395.235V19.6874H416.334V158.571H396.036V136.67H394.433C390.961 141.745 376.539 162.043 346.358 162.043ZM347.694 143.08C377.874 143.08 396.036 118.508 396.036 89.3962C396.036 55.2095 374.669 35.4454 347.159 35.4454C318.582 35.4454 298.817 57.8804 298.817 89.3962C298.817 121.98 318.582 143.08 347.694 143.08Z"
        fill="#0A1128"
      />
      <path
        d="M557.185 220V136.136H555.583C551.309 143.347 535.819 162.043 506.706 162.043C467.445 162.043 439.134 131.595 439.134 88.8621C439.134 46.3958 467.445 16.2153 508.309 16.2153C538.489 16.2153 552.912 36.5137 556.384 41.5883H557.987V19.6874H578.285V220H557.185ZM509.11 142.813C536.62 142.813 557.987 123.049 557.987 88.8621C557.987 59.75 539.825 35.1783 509.644 35.1783C480.532 35.1783 460.768 56.2779 460.768 88.8621C460.768 120.378 480.532 142.813 509.11 142.813Z"
        fill="#0A1128"
      />
      <path
        d="M673.465 162.043C631.8 162.043 601.085 131.328 601.085 89.1292C601.085 47.197 632.334 16.2153 673.732 16.2153C715.664 16.2153 746.111 47.4641 746.111 89.1292C746.111 130.794 715.13 162.043 673.465 162.043ZM673.465 142.813C703.378 142.813 724.478 120.111 724.478 89.1292C724.478 58.4145 703.378 35.4454 673.465 35.4454C643.551 35.4454 622.719 58.6816 622.719 89.1292C622.719 120.378 643.818 142.813 673.465 142.813Z"
        fill="#0A1128"
      />
      <path
        d="M771.372 158.571V19.6874H792.204V45.5945H793.807C797.813 33.8429 811.434 19.6874 831.733 19.6874H843.484V40.787H832C806.627 40.787 792.471 60.5512 792.471 86.9925V158.571H771.372Z"
        fill="#0A1128"
      />
      <path
        d="M919.565 162.043C878.702 162.043 850.391 131.863 850.391 89.3962C850.391 46.6629 878.702 16.2153 917.963 16.2153C947.075 16.2153 962.566 34.9112 966.839 42.1224H968.442V19.6874H989.541V158.571H969.243V136.67H967.641C964.168 141.745 949.746 162.043 919.565 162.043ZM920.901 143.08C951.081 143.08 969.243 118.508 969.243 89.3962C969.243 55.2095 947.876 35.4454 920.367 35.4454C891.789 35.4454 872.025 57.8804 872.025 89.3962C872.025 121.98 891.789 143.08 920.901 143.08Z"
        fill="#0A1128"
      />
      <defs>
        <linearGradient
          id="paint0_linear_224_6742"
          x1="114.269"
          y1="65.0703"
          x2="65.0706"
          y2="114.268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3D8AFF" />
          <stop offset="1" stopColor="#6640FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
