import { graphql, Environment } from "react-relay";
import { EventsPageQuery as EventsPageQueryType } from "./__generated__/EventsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventsPageQuery as EventsPageQueryType } from "./__generated__/EventsPageQuery.graphql";

export const EventsPageQuery = graphql`
  query EventsPageQuery {
    ...EventsFragment
  }
`;

export const eventsPageQueryLoader = (environment: Environment) => {
  return () => preload<EventsPageQueryType>(environment, EventsPageQuery);
};

export const useEventsPageQuery = () => usePreloaded<EventsPageQueryType>();
