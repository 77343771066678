import { useCompetitionCodeReadmePageQuery } from "./loaders/CompetitionCodeReadmePage";
import { FormattedMessage } from "react-intl";
import FileBrowser from "../components/FileBrowser";
import FileViewer from "../components/FileBrowser/FileViewer";

export default function CompetitionCodeReadmePage() {
  const {
    query,
    variables: { slug, fileKind },
  } = useCompetitionCodeReadmePageQuery();
  const readme =
    query.competitionBySlug?.useCase.latest?.fileByKind?.browse.readMeta;
  const file = query.competitionBySlug?.useCase.latest?.fileByKind;
  return (
    <div className="space-y-6">
      {file && (
        <FileBrowser
          root={`/competitions/${slug}/code/${fileKind.toLowerCase()}`}
          file={file}
        />
      )}
      {readme?.kind == "FILE" ? (
        <FileViewer entry={readme} disableScroll={true} />
      ) : (
        <div className="text-center text-gray-500">
          <FormattedMessage defaultMessage="No README.md found" />
        </div>
      )}
    </div>
  );
}
