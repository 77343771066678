import { useState } from "react";
import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import CompetitionMembers from "../components/CompetitionMembers";
import { useCompetitionMembersPageQuery } from "./loaders/CompetitionMembersPage";
import { CompetitionMembersPageCreateMutation as CompetitionMembersPageCreateMutationType } from "./__generated__/CompetitionMembersPageCreateMutation.graphql";
import Button from "../components/Button";
import UserAutocomplete from "../components/UserAutocomplete";
import ErrorPage from "./ErrorPage";
import { logger } from "../common/logger";
import { MetaLayout } from "../common/MetaLayout";

const CompetitionMembersPageCreateMutation = graphql`
  mutation CompetitionMembersPageCreateMutation(
    $competitionId: ID!
    $entityId: ID!
    $connections: [ID!]!
  ) {
    addCompetitionMember(competitionId: $competitionId, entityId: $entityId)
      @prependEdge(connections: $connections) {
      node {
        id
        kind
        viewerCanDelete: viewerCan(action: REMOVE_COMPETITION_MEMBER)
        entity {
          id
          username
          displayName
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
        competition {
          id
          viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)
          viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)
        }
      }
    }
  }
`;

type FormData = {
  entityId: string;
};

export default function CompetitionMembersPage() {
  const intl = useIntl();
  const { query } = useCompetitionMembersPageQuery();
  const competition = query.competitionBySlug;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [commitAddMutation, isAddMutationInFlight] =
    useMutation<CompetitionMembersPageCreateMutationType>(
      CompetitionMembersPageCreateMutation,
    );
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  const errorMessages = {
    entityId: {
      required: intl.formatMessage({
        defaultMessage: "Please select the username from the list",
      }),
    },
  };
  const onAddSubmit = handleSubmit(({ entityId }) => {
    setFormError(null);
    commitAddMutation({
      variables: {
        competitionId: competition.id,
        entityId,
        connections: [
          ConnectionHandler.getConnectionID(
            competition.id,
            "CompetitionMembersFragment_members",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage: "Something went wrong",
          }),
        );
      },
    });
  });
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{competitionTitle} | members" },
        { competitionTitle: query.competitionBySlug.title },
      )}
    >
      {competition.viewerCanEditMembers && (
        <div className="flex flex-col items-end pb-8">
          <form>
            <div className="flex flex-row space-x-2">
              <div>
                <UserAutocomplete
                  query={query}
                  placeholder={intl.formatMessage({
                    defaultMessage: "username",
                  })}
                  {...register("entityId", { required: true })}
                />
              </div>
              <div>
                <Button
                  kind="primary"
                  onClick={() => {
                    onAddSubmit();
                  }}
                  disabled={isAddMutationInFlight}
                >
                  <FormattedMessage defaultMessage="Add Member" />
                </Button>
              </div>
            </div>
          </form>
          <div>
            {typeof errors.entityId?.type === "string" && (
              <p className="text-red-500 text-sm">
                {
                  errorMessages.entityId[
                    errors.entityId.type as keyof typeof errorMessages.entityId
                  ]
                }
              </p>
            )}
            {formError && <p className="text-red-500 text-sm">{formError}</p>}
          </div>
        </div>
      )}
      <CompetitionMembers user={competition} />
    </MetaLayout>
  );
}
