import { useRef, useState, useEffect, useCallback } from "react";
import { useController } from "react-hook-form";
import { getCaretCoordinates, getCurrentWord, replaceWord } from "./utils";
import { useMarkdownEditor } from "./context";

export function useWrite() {
  const { name, control, defaultValue, setMarkdownValue } = useMarkdownEditor();
  const { field } = useController({ name, control, defaultValue });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [commandValue, setCommandValue] = useState("");
  const [cursorLine, setCursorLine] = useState(1);

  const handleBlur = useCallback(
    (_e: Event) => {
      field.onBlur();
      const dropdown = dropdownRef.current;
      if (dropdown) {
        dropdown.classList.add("hidden");
        setCommandValue("");
      }
    },
    [field],
  );

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const textarea = textareaRef.current;
    const input = inputRef.current;
    const dropdown = dropdownRef.current;
    if (textarea && input && dropdown) {
      const currentWord = getCurrentWord(textarea);
      const isDropdownHidden = dropdown.classList.contains("hidden");
      if (currentWord.startsWith("@") && !isDropdownHidden) {
        switch (event.key) {
          case "ArrowUp":
          case "ArrowDown":
          case "Enter":
          case "Tab":
            event.preventDefault();
            input.dispatchEvent(new KeyboardEvent("keydown", event));
            break;
          case "Escape":
            event.preventDefault();
            dropdown.classList.add("hidden");
            textarea.focus();
            break;
          default:
            break;
        }
      }
    }
  }, []);

  const onChange = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement>,
      onCommandValueChange: (search: string) => void,
    ) => {
      const text = event.target.value;
      const textarea = textareaRef.current;
      const dropdown = dropdownRef.current;

      if (textarea && dropdown) {
        const cursorPosition = event.target.selectionStart;
        const textBeforeCursor = event.target.value.slice(0, cursorPosition);
        const lineBreakBeforeCursor =
          (textBeforeCursor.match(/\n/g) || []).length + 1;
        setCursorLine(lineBreakBeforeCursor);
        const caret = getCaretCoordinates({
          element: textarea,
          position: textarea.selectionEnd,
        });
        const currentWord = getCurrentWord(textarea);
        setMarkdownValue(text);
        textarea.value = text;
        field.onChange(text.trim());
        if (currentWord.startsWith("@")) {
          setCommandValue(currentWord);
          onCommandValueChange(currentWord);
          dropdown.style.left = caret.left + "px";
          dropdown.style.top = caret.top + caret.height + "px";
          dropdown.classList.remove("hidden");
        } else {
          if (commandValue !== "") {
            setCommandValue("");
            dropdown.classList.add("hidden");
          }
        }
      }
    },
    [setMarkdownValue, commandValue, field],
  );

  const onCommandSelect = useCallback(
    (value: string) => {
      const textarea = textareaRef.current;
      const dropdown = dropdownRef.current;
      if (textarea && dropdown) {
        replaceWord(textarea, value);
        setMarkdownValue(textarea.value);
        field.onChange(textarea.value.trim());
        setCommandValue("");
        dropdown.classList.add("hidden");
      }
    },
    [setMarkdownValue, field],
  );

  const handleMouseDown = useCallback((event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleSectionChange = useCallback(
    (_event: Event) => {
      const textarea = textareaRef.current;
      const dropdown = dropdownRef.current;
      if (textarea && dropdown) {
        const currentWord = getCurrentWord(textarea);
        if (!currentWord.startsWith("@") && commandValue !== "") {
          setCommandValue("");
          dropdown.classList.add("hidden");
        }
      }
    },
    [commandValue],
  );

  const insertTextAtLine = useCallback(
    (textToInsert: string, lineNumber?: number) => {
      const ref = textareaRef.current;
      if (!ref) {
        return;
      }

      const currentValue = ref.value || "";
      const lines = currentValue.split("\n");

      const line = lineNumber ?? cursorLine;
      if (line > lines.length) {
        lines.push(...Array(line - lines.length).fill(""));
      }

      lines[line - 1] += textToInsert;
      const newValue = lines.join("\n");

      setMarkdownValue(newValue);
      ref.value = newValue;
      field.onChange(newValue.trim());
    },
    [cursorLine, setMarkdownValue, field],
  );

  useEffect(() => {
    const textarea = textareaRef.current;
    const dropdown = dropdownRef.current;
    textarea?.addEventListener("keydown", handleKeyDown);
    textarea?.addEventListener("blur", handleBlur);
    document?.addEventListener("selectionchange", handleSectionChange);
    dropdown?.addEventListener("mousedown", handleMouseDown);
    return () => {
      textarea?.removeEventListener("keydown", handleKeyDown);
      textarea?.removeEventListener("blur", handleBlur);
      document?.removeEventListener("selectionchange", handleSectionChange);
      dropdown?.removeEventListener("mousedown", handleMouseDown);
    };
  }, [handleBlur, handleKeyDown, handleMouseDown, handleSectionChange]);

  return {
    textareaRef,
    dropdownRef,
    inputRef,
    commandValue,
    handleBlur,
    onChange,
    onCommandSelect,
    insertTextAtLine,
  };
}
