/**
 * @generated SignedSource<<c54eddddd142ce603e3a34edf681fc18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FocusedCommentFragment$data = {
  readonly id: string;
  readonly parent: {
    readonly id: string;
    readonly parent: {
      readonly id: string;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"CommentDisplayFragment">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"CommentFragment">;
  readonly " $fragmentType": "FocusedCommentFragment";
};
export type FocusedCommentFragment$key = {
  readonly " $data"?: FocusedCommentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FocusedCommentFragment">;
};

import FocusedCommentFragmentQuery_graphql from './FocusedCommentFragmentQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "HOT",
      "kind": "LocalArgument",
      "name": "order"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": FocusedCommentFragmentQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "FocusedCommentFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Literal",
          "name": "topLevel",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "CommentFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Comment",
          "kind": "LinkedField",
          "name": "parent",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentDisplayFragment"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "f24fbbd35111c8a1a09eca7b0b85cc58";

export default node;
