import React from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import {
  CompetitionAutocompleteFragment$key,
  CompetitionAutocompleteFragment$data,
} from "./__generated__/CompetitionAutocompleteFragment.graphql";
import { Spread } from "../utils/types";
import NodeAutocomplete, { DisplayProps } from "./NodeAutocomplete";
import { MdOutlineCircle, MdOutlineCheckCircle } from "react-icons/md";

const CompetitionAutocompleteFragment = graphql`
  fragment CompetitionAutocompleteFragment on Query
  @refetchable(queryName: "CompetitionAutocompleteFragmentPaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    search: { type: "String" }
  ) {
    competitions(first: $count, search: $search) {
      edges {
        node {
          id
          title
          slug
        }
      }
    }
  }
`;

type CompetitionItem =
  CompetitionAutocompleteFragment$data["competitions"]["edges"][number]["node"];

function CompetitionDisplay({ node }: DisplayProps<CompetitionItem>) {
  return (
    <span className="w-full inline-block overflow-hidden">
      <strong>{node.slug}</strong>: {node.title}
    </span>
  );
}

function Prefix({ isValid }: { isValid: boolean }) {
  return isValid ? (
    <MdOutlineCheckCircle className="text-indigo" />
  ) : (
    <MdOutlineCircle className="text-gray-400" />
  );
}

interface PropsExtra {
  query: CompetitionAutocompleteFragment$key;
}

interface Props
  extends Spread<React.InputHTMLAttributes<HTMLInputElement>, PropsExtra> {}

const CompetitionAutocomplete = React.forwardRef(
  (
    {
      query: queryFragment,
      defaultValue: _defaultValue,
      prefix: _prefix,
      ...rest
    }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [query, refetch] = useRefetchableFragment(
      CompetitionAutocompleteFragment,
      queryFragment,
    );
    const nodes = query.competitions.edges.map(({ node }) => ({
      value: node.title,
      ...node,
    }));
    return (
      <NodeAutocomplete
        nodes={nodes}
        refetch={refetch}
        display={CompetitionDisplay}
        prefix={Prefix}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default CompetitionAutocomplete;
