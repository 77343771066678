import React from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { UserEntitiesAutocompleteFragment$key } from "./__generated__/UserEntitiesAutocompleteFragment.graphql";
import { Spread } from "../utils/types";
import EntityAutoComplete from "./EntityAutocomplete";

const UserEntitiesAutocompleteFragment = graphql`
  fragment UserEntitiesAutocompleteFragment on User
  @refetchable(queryName: "UserEntitiesAutocompleteFragmentPaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    search: { type: "String" }
    permission: { type: "Permission" }
  ) {
    id
    username
    entities(first: $count, search: $search, permission: $permission) {
      edges {
        node {
          id
          kind
          username
          displayName
        }
      }
    }
  }
`;

interface PropsExtra {
  query: UserEntitiesAutocompleteFragment$key;
}

interface Props
  extends Spread<React.InputHTMLAttributes<HTMLInputElement>, PropsExtra> {}

const UserEntitiesAutocomplete = React.forwardRef(
  (
    { query: queryFragment, ...rest }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const [query, refetch] = useRefetchableFragment(
      UserEntitiesAutocompleteFragment,
      queryFragment,
    );
    const entities = query.entities.edges.map((edge) => edge.node);
    return (
      <EntityAutoComplete
        defaultId={query.id}
        defaultUsername={query.username}
        entities={entities}
        refetch={refetch}
        {...rest}
        ref={ref}
      />
    );
  },
);

export default UserEntitiesAutocomplete;
