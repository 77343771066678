import Outlet from "../common/Outlet";
import { useEventScaffoldQuery } from "./loaders/EventScaffold";
import { Link } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "../components/EntityProfilePic";
import NavItem from "../components/NavItem";
import AspectDiv from "../components/AspectDiv";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { ScrollableScaffoldNav } from "../components/ScrollableScaffoldNav";
import { MetaLayout } from "../common/MetaLayout";

export default function EventScaffold() {
  const intl = useIntl();
  const {
    query: { eventBySlug: event, viewer },
  } = useEventScaffoldQuery();
  if (!event) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Event not found." />
        {!viewer && (
          <span>
            {" "}
            <Link
              className="text-blue-500"
              to={`/login?login_redirect=${encodeURIComponent(location.href)}`}
            >
              <FormattedMessage defaultMessage="Try logging in to view this page." />
            </Link>
          </span>
        )}
      </ErrorPage>
    );
  }
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "Event | {eventTitle}" },
        { eventTitle: event.title },
      )}
      metaDescription={event.description}
      metaImageAlt={event.thumbnail && event.shortDescription}
      metaImageUrl={event.thumbnail ?? ""}
    >
      <div className="pb-2">
        <AspectDiv
          className="rounded-t-lg bg-grey"
          style={{
            backgroundImage: `url(${defaultThumbnail})`,
            backgroundSize: "1000px",
          }}
          ratio={4.75}
        >
          {event.banner && (
            <img
              src={event.banner}
              className="w-full h-full rounded-t-lg object-cover object-center"
            />
          )}
        </AspectDiv>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="w-full">
          <h1 className="text-2xl font-bold font-poppins">{event.title}</h1>
          <h2 className="text-xl">{event.shortDescription}</h2>
          {event.viewerCanEdit && (
            <Link to={`/events/${event.slug}/edit`}>
              <FormattedMessage defaultMessage="Edit" />
            </Link>
          )}
        </div>
        <Link to={`/${event.host.username}`}>
          <div className="flex flex-row items-center hover:cursor-pointer">
            <div className="w-20 pr-4">
              <EntityProfilePic entity={event.host} />
            </div>
            <div>
              <p className="whitespace-nowrap">
                <FormattedMessage defaultMessage="Hosted by" />
              </p>
              <p>{event.host.displayName}</p>
            </div>
          </div>
        </Link>
      </div>
      <ScrollableScaffoldNav>
        <NavItem to={`/events/${event.slug}`} exact>
          <FormattedMessage defaultMessage="Overview" />
        </NavItem>
        {event.isPrivate && event.viewerCanAddMember && (
          <NavItem to={`/events/${event.slug}/members`}>
            <FormattedMessage defaultMessage="Members" />
          </NavItem>
        )}
        {event.viewerCanAddCompetition && (
          <NavItem to={`/events/${event.slug}/competitions`}>
            <FormattedMessage defaultMessage="Competitions" />
          </NavItem>
        )}
        <NavItem to={`/events/${event.slug}/agenda`}>
          <FormattedMessage defaultMessage="Agenda" />
        </NavItem>
      </ScrollableScaffoldNav>
      <Outlet />
    </MetaLayout>
  );
}
