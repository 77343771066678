import { useState } from "react";
import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import OrganizationUsers from "../components/OrganizationUsers";
import { useOrganizationUsersPageQuery } from "./loaders/OrganizationUsersPage";
import { OrganizationUsersPageAddMutation as OrganizationUsersPageAddMutationType } from "./__generated__/OrganizationUsersPageAddMutation.graphql";
import Button from "../components/Button";
import UserAutocomplete from "../components/UserAutocomplete";
import ErrorPage from "./ErrorPage";
import { logger } from "../common/logger";

const OrganizationUsersPageAddMutation = graphql`
  mutation OrganizationUsersPageAddMutation(
    $organizationId: ID!
    $userId: ID!
    $kind: OrganizationMembershipKind!
    $connections: [ID!]!
  ) {
    updateOrganizationMembership(
      organizationId: $organizationId
      userId: $userId
      kind: $kind
    ) @prependEdge(connections: $connections) {
      node {
        id
        kind
        viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)
        user {
          username
          displayName
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
        organization {
          viewerCanEdit: viewerCan(action: UPDATE_ORGANIZATION_MEMBERSHIP)
        }
      }
    }
  }
`;

type FormData = {
  userId: string;
};

export default function OrganizationUsersPage() {
  const intl = useIntl();
  const { variables, query } = useOrganizationUsersPageQuery();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<string | null>(null);
  const [commitAddMutation, isAddMutationInFlight] =
    useMutation<OrganizationUsersPageAddMutationType>(
      OrganizationUsersPageAddMutation,
    );
  if (!query.entityByUsername) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage(
          {
            defaultMessage: "@{username} not found",
          },
          {
            username: variables.username,
          },
        )}
      />
    );
  }
  const errorMessages = {
    userId: {
      required: intl.formatMessage({
        defaultMessage: "Please select the username from the list",
      }),
    },
  };
  const organization = query.entityByUsername;
  const onAddSubmit = handleSubmit(({ userId }) => {
    setFormError(null);
    commitAddMutation({
      variables: {
        organizationId: organization.id,
        userId,
        kind: "ADMIN",
        connections: [
          ConnectionHandler.getConnectionID(
            organization.id,
            "OrganizationUsersFragment_users",
          ),
          ConnectionHandler.getConnectionID(
            userId,
            "UserOrganizationsFragment_organizations",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage: "Something went wrong",
          }),
        );
      },
    });
  });
  return (
    <div>
      {organization.viewerCanEdit && (
        <div className="flex flex-col items-end pb-8">
          <form>
            <div className="flex flex-row space-x-2">
              <div>
                <UserAutocomplete
                  query={query}
                  placeholder={intl.formatMessage({
                    defaultMessage: "username",
                  })}
                  {...register("userId", { required: true })}
                />
              </div>
              <div>
                <Button
                  kind="primary"
                  onClick={() => {
                    onAddSubmit();
                  }}
                  disabled={isAddMutationInFlight}
                >
                  <FormattedMessage defaultMessage="Add Member" />
                </Button>
              </div>
            </div>
          </form>
          <div>
            {typeof errors.userId?.type === "string" && (
              <p className="text-red-500 text-sm">
                {
                  errorMessages.userId[
                    errors.userId.type as keyof typeof errorMessages.userId
                  ]
                }
              </p>
            )}
            {formError && <p className="text-red-500 text-sm">{formError}</p>}
          </div>
        </div>
      )}
      <OrganizationUsers user={organization} />
    </div>
  );
}
