import { TextareaHTMLAttributes } from "react";
import * as Tabs from "../Tabs";
import { Preview } from "./Preview";
import { ResetTextarea, Write } from "./Write";
import { FormattedMessage } from "react-intl";
import { Spread } from "../../utils/types";

interface PropsExtra {
  resetTextarea?: ResetTextarea;
}

interface Props
  extends Spread<PropsExtra, TextareaHTMLAttributes<HTMLTextAreaElement>> {}

export default function MarkdownEditor({ ...rest }: Readonly<Props>) {
  return (
    <Tabs.Root defaultValue="write" className="w-full">
      <Tabs.List>
        <Tabs.Trigger value="write">
          <FormattedMessage defaultMessage="Write" />
        </Tabs.Trigger>
        <Tabs.Trigger value="preview">
          <FormattedMessage defaultMessage="Preview" />
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="write">
        <Write {...rest} />
      </Tabs.Content>
      <Tabs.Content value="preview">
        <Preview />
      </Tabs.Content>
    </Tabs.Root>
  );
}
