import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionApprovalPageQuery as CompetitionApprovalPageQueryType } from "./__generated__/CompetitionApprovalPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionApprovalPageQuery as CompetitionApprovalPageQueryType } from "./__generated__/CompetitionApprovalPageQuery.graphql";

export const CompetitionApprovalPageQuery = graphql`
  query CompetitionApprovalPageQuery($slug: String!) {
    competitionBySlug(slug: $slug) {
      id
      title
      ...CompetitionApprovalPageFragment
    }
  }
`;

export const competitionApprovalPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionApprovalPageQueryType>(
      environment,
      CompetitionApprovalPageQuery,
      {
        slug,
      },
    );
  };
};

export const useCompetitionApprovalPageQuery = () =>
  usePreloaded<CompetitionApprovalPageQueryType>();
