export function nativeInputValueSet(
  input: HTMLInputElement,
  value: string,
): Event {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  )?.set;
  if (nativeInputValueSetter) {
    nativeInputValueSetter.call(input, value);
  }
  const event = new Event("change", { bubbles: true });
  input.dispatchEvent(event);
  return event;
}
