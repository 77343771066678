import React from "react";
import * as Dialog from "./Dialog";
import { TbAward } from "react-icons/tb";
import { FormattedMessage } from "react-intl";
import { EntityBadgeAwardForm } from "./EntityBadgeAwardForm";
import Button from "./Button";
import { EntityBadgeListFragment$key } from "./__generated__/EntityBadgeListFragment.graphql";

export interface EntityBadgeAwardButtonProps {
  entity: EntityBadgeListFragment$key;
  onAwarded?: () => void;
}

export function EntityBadgeAwardButton(props: EntityBadgeAwardButtonProps) {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <Dialog.Root open={dialogOpen} onOpenChange={setDialogOpen}>
      <Dialog.Trigger asChild>
        <Button kind="primary" className="flex flex-row gap-1 items-center">
          <FormattedMessage defaultMessage="Award badge" />
          <TbAward />
        </Button>
      </Dialog.Trigger>

      <Dialog.Content>
        <div className="flex flex-row items-center px-4 md:py-2 py-4">
          <Dialog.Title className="flex-1">
            <FormattedMessage defaultMessage="Award a badge" />
          </Dialog.Title>
          <Dialog.Close />
        </div>
        <div className="flex flex-col w-full px-4 md:py-2 py-4 gap-4">
          <EntityBadgeAwardForm
            entity={props.entity}
            onSubmit={() => {
              setDialogOpen(false);
              props.onAwarded?.();
            }}
            onCancel={() => setDialogOpen(false)}
          />
          <Dialog.Close.Mobile className="mt-8 w-full ">
            <Button kind="secondary" className="w-full">
              <FormattedMessage defaultMessage="Close" />
            </Button>
          </Dialog.Close.Mobile>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
