/**
 * @generated SignedSource<<a3c3e73ba17128ce5ae1816b09badc46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ArchiveKind = "TAR" | "ZIP";
export type ProjectDownloadButtonDownloadArchiveQuery$variables = {
  archKind: ArchiveKind;
  id: string;
};
export type ProjectDownloadButtonDownloadArchiveQuery$data = {
  readonly node: {
    readonly downloadUrl?: any;
  };
};
export type ProjectDownloadButtonDownloadArchiveQuery = {
  response: ProjectDownloadButtonDownloadArchiveQuery$data;
  variables: ProjectDownloadButtonDownloadArchiveQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "archKind"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "archKind",
          "variableName": "archKind"
        }
      ],
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    }
  ],
  "type": "ProjectVersionFile",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDownloadButtonDownloadArchiveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectDownloadButtonDownloadArchiveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd15349c884e595ce1a8eef0a0c88e7c",
    "id": null,
    "metadata": {},
    "name": "ProjectDownloadButtonDownloadArchiveQuery",
    "operationKind": "query",
    "text": "query ProjectDownloadButtonDownloadArchiveQuery(\n  $id: ID!\n  $archKind: ArchiveKind!\n) {\n  node(id: $id) {\n    __typename\n    ... on ProjectVersionFile {\n      downloadUrl(archKind: $archKind)\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ff603168025685125cd9307da3bdd19";

export default node;
