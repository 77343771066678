/**
 * @generated SignedSource<<b751659bc1efe7f254d15e82e321dbb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionScaffoldQuery$variables = {
  slug: string;
};
export type CompetitionScaffoldQuery$data = {
  readonly competitionBySlug: {
    readonly banner: any | null | undefined;
    readonly createdAt: string;
    readonly description: string | null | undefined;
    readonly host: {
      readonly displayName: string;
      readonly id: string;
      readonly username: string;
      readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
    };
    readonly id: string;
    readonly isPrivate: boolean;
    readonly requiresApproval: boolean;
    readonly shortDescription: string;
    readonly slug: string;
    readonly thumbnail: any | null | undefined;
    readonly title: string;
    readonly viewerCanEdit: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CompetitionSetupButtonFragment" | "SubjectSubscriptionButtonFragment">;
  } | null | undefined;
  readonly viewer: {
    readonly canApprove: boolean;
    readonly id: string;
  };
};
export type CompetitionScaffoldQuery = {
  response: CompetitionScaffoldQuery$data;
  variables: CompetitionScaffoldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": "canApprove",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "CREATE_PROJECT_VERSION_APPROVAL"
        }
      ],
      "kind": "ScalarField",
      "name": "can",
      "storageKey": "can(action:\"CREATE_PROJECT_VERSION_APPROVAL\")"
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v11 = {
  "alias": "viewerCanEdit",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_COMPETITION"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_COMPETITION\")"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPrivate",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresApproval",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionScaffoldQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "thumbnail",
                    "value": true
                  }
                ],
                "kind": "FragmentSpread",
                "name": "EntityProfilePicFragment"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompetitionSetupButtonFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubjectSubscriptionButtonFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionScaffoldQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "host",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v1/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isEntity"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UseCase",
            "kind": "LinkedField",
            "name": "useCase",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "latest",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "kind",
                        "value": "TEMPLATE"
                      }
                    ],
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      (v17/*: any*/),
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProjectVersion",
                        "kind": "LinkedField",
                        "name": "projectVersion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "project",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Competition",
                                "kind": "LinkedField",
                                "name": "competition",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "fileByKind(kind:\"TEMPLATE\")"
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "viewerCanSubscribe",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "CREATE_SUBJECT_SUBSCRIPTION"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"CREATE_SUBJECT_SUBSCRIPTION\")"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entitySubscription",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v1/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": "viewerCanUnsubscribe",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "action",
                        "value": "DELETE_SUBJECT_SUBSCRIPTION"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "viewerCan",
                    "storageKey": "viewerCan(action:\"DELETE_SUBJECT_SUBSCRIPTION\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Subscribable",
            "abstractKey": "__isSubscribable"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70ed519c79f69dca53f3b0983882fb29",
    "id": null,
    "metadata": {},
    "name": "CompetitionScaffoldQuery",
    "operationKind": "query",
    "text": "query CompetitionScaffoldQuery(\n  $slug: String!\n) {\n  viewer @ifAllowed {\n    id\n    canApprove: can(action: CREATE_PROJECT_VERSION_APPROVAL)\n  }\n  competitionBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    description\n    createdAt\n    banner\n    thumbnail\n    viewerCanEdit: viewerCan(action: UPDATE_COMPETITION)\n    isPrivate\n    requiresApproval\n    host {\n      __typename\n      id\n      displayName\n      username\n      ...EntityProfilePicFragment_49kWkb\n    }\n    ...CompetitionSetupButtonFragment\n    ...SubjectSubscriptionButtonFragment\n  }\n}\n\nfragment CompetitionSetupButtonFragment on Competition {\n  slug\n  useCase {\n    latest {\n      fileByKind(kind: TEMPLATE) {\n        kind\n        ...ProjectDownloadButtonFragment\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  imageThumbnail\n}\n\nfragment ProjectDownloadButtonFragment on ProjectVersionFile {\n  id\n  kind\n  projectVersion {\n    project {\n      __typename\n      competition {\n        slug\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment SubjectSubscriptionButtonFragment on Subscribable {\n  __isSubscribable: __typename\n  id\n  viewerCanSubscribe: viewerCan(action: CREATE_SUBJECT_SUBSCRIPTION)\n  entitySubscription @ifAllowed {\n    __typename\n    id\n    kind\n    viewerCanUnsubscribe: viewerCan(action: DELETE_SUBJECT_SUBSCRIPTION)\n  }\n}\n"
  }
};
})();

(node as any).hash = "1307825d79cb96d2a8de392ff4d5b6cb";

export default node;
