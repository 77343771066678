/**
 * @generated SignedSource<<7d4070fa4f7c1d92090eee608afc8b30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationEditFormFragment$data = {
  readonly bio: string | null | undefined;
  readonly displayName: string;
  readonly github: string | null | undefined;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly linkedin: string | null | undefined;
  readonly location: string | null | undefined;
  readonly username: string;
  readonly viewerCanDelete: boolean;
  readonly website: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  readonly " $fragmentType": "OrganizationEditFormFragment";
};
export type OrganizationEditFormFragment$key = {
  readonly " $data"?: OrganizationEditFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationEditFormFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationEditFormFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkedin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "github",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": "viewerCanDelete",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "DELETE_ORGANIZATION"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"DELETE_ORGANIZATION\")"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b5c35405f2640c1c53b865d6d3672259";

export default node;
