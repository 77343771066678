/**
 * @generated SignedSource<<84057fd529fbc0e5c386b3684034b761>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EventTimetablePageQuery$variables = {
  slug: string;
};
export type EventTimetablePageQuery$data = {
  readonly eventBySlug: {
    readonly agenda: any | null | undefined;
    readonly id: string;
    readonly title: string;
    readonly viewerCanUpdateAgenda: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EventTimetableFragment">;
  } | null | undefined;
};
export type EventTimetablePageQuery = {
  response: EventTimetablePageQuery$data;
  variables: EventTimetablePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": "viewerCanUpdateAgenda",
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "UPDATE_AGENDA"
    }
  ],
  "kind": "ScalarField",
  "name": "viewerCan",
  "storageKey": "viewerCan(action:\"UPDATE_AGENDA\")"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "agenda",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventTimetablePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EventTimetableFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventTimetablePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "eventBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": "timetable",
            "args": null,
            "kind": "ScalarField",
            "name": "agenda",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c3b4657648af43f678d1964647e3ddec",
    "id": null,
    "metadata": {},
    "name": "EventTimetablePageQuery",
    "operationKind": "query",
    "text": "query EventTimetablePageQuery(\n  $slug: String!\n) {\n  eventBySlug(slug: $slug) {\n    id\n    title\n    viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)\n    agenda\n    ...EventTimetableFragment\n  }\n}\n\nfragment EventTimetableFragment on Event {\n  timetable: agenda\n}\n"
  }
};
})();

(node as any).hash = "cdebdf277007cdbb8eae4da166efe2db";

export default node;
