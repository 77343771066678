import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { UserOrganizationsFragment$key } from "./__generated__/UserOrganizationsFragment.graphql";
import { UserOrganizationsDeleteMutation as UserOrganizationsDeleteMutationType } from "./__generated__/UserOrganizationsDeleteMutation.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import { Link } from "react-router-dom";
import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import { formatOrganizationMembershipKind } from "../utils/format";
import LoadMore from "./LoadMore";
import { ToolbarButton } from "./Button";
import { logger } from "../common/logger";

const UserOrganizationsFragment = graphql`
  fragment UserOrganizationsFragment on User
  @refetchable(queryName: "UserOrganizationsFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    organizations(first: $count, after: $cursor)
      @connection(key: "UserOrganizationsFragment_organizations") {
      edges {
        node {
          id
          kind
          viewerCanDelete: viewerCan(action: REMOVE_ORGANIZATION_MEMBER)
          organization {
            id
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
      }
    }
  }
`;

const UserOrganizationsDeleteMutation = graphql`
  mutation UserOrganizationsDeleteMutation($id: ID!, $connections: [ID!]!) {
    removeOrganizationMember(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  user: UserOrganizationsFragment$key;
}

export default function UserOrganizations({ user: userFragment }: Props) {
  const intl = useIntl();
  const {
    data: user,
    hasNext,
    loadNext,
    isLoadingNext,
  } = usePaginationFragment(UserOrganizationsFragment, userFragment);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<UserOrganizationsDeleteMutationType>(
      UserOrganizationsDeleteMutation,
    );
  if (user.organizations.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Organizations" />
      </p>
    );
  }
  const onDelete = (id: string, organizationId: string) => {
    commitDeleteMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            organizationId,
            "OrganizationUsersFragment_users",
          ),
          ConnectionHandler.getConnectionID(
            user.id,
            "UserOrganizationsFragment_organizations",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
    });
  };

  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Organization" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Membership" />
            </th>
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          {user.organizations.edges.map(({ node: membership }) => (
            <tr key={membership.id}>
              <td className="whitespace-nowrap py-2 px-3">
                <Link to={`/${membership.organization.username}`}>
                  <div className="flex flex-row items-center hover:cursor-pointer">
                    <div className="pr-4">
                      <EntityProfilePic
                        entity={membership.organization}
                        size="12"
                      />
                    </div>
                    <span>{membership.organization.displayName}</span>
                  </div>
                </Link>
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {formatOrganizationMembershipKind(intl, membership.kind)}
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                <Dropdown
                  trigger={
                    <ToolbarButton size="sm">
                      <MdMoreHoriz />
                    </ToolbarButton>
                  }
                >
                  {membership.kind !== "OWNER" &&
                    membership.viewerCanDelete && (
                      <Dropdown.Item
                        disabled={isDeleteMutationInFlight}
                        onClick={() => {
                          onDelete(membership.id, membership.organization.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Leave Organization" />
                      </Dropdown.Item>
                    )}
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <LoadMore
        hasMore={hasNext}
        loadMore={loadNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}
