import { graphql, Environment } from "react-relay";
import { JoinParisHackPageQuery as JoinParisHackPageQueryType } from "./__generated__/JoinParisHackPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { JoinParisHackPageQuery as JoinParisHackPageQueryType } from "./__generated__/JoinParisHackPageQuery.graphql";

export const JoinParisHackPageQuery = graphql`
  query JoinParisHackPageQuery {
    viewer @ifAllowed {
      id
      username
      email
      displayName
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
  }
`;

export const joinParisHackPageQueryLoader = (environment: Environment) => {
  return () =>
    preload<JoinParisHackPageQueryType>(environment, JoinParisHackPageQuery);
};

export const useJoinParisHackPageQuery = () =>
  usePreloaded<JoinParisHackPageQueryType>();
