import { graphql, Environment } from "react-relay";
import { HomePageQuery as HomePageQueryType } from "./__generated__/HomePageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import { EnvironmentOptions } from "../../common/relayEnvironment";

const REDIRECT_LANDING = import.meta.env.VITE_REDIRECT_LANDING;

export type { HomePageQuery as HomePageQueryType } from "./__generated__/HomePageQuery.graphql";

export const HomePageQuery = graphql`
  query HomePageQuery {
    ...CompetitionsFragment
    ...EventsFragment
  }
`;

export const homePageQueryLoader = (environment: Environment) => {
  return () => {
    // Redirect userId if logged out on HomePage load
    const userId = (environment.options as EnvironmentOptions).userId;
    if (!import.meta.env.SSR && REDIRECT_LANDING && !userId) {
      window.location.replace(REDIRECT_LANDING);
    }
    return preload<HomePageQueryType>(environment, HomePageQuery);
  };
};

export const useHomePageQuery = () => usePreloaded<HomePageQueryType>();
