import { graphql, Environment } from "react-relay";
import { ForumsPageQuery as ForumsPageQueryType } from "./__generated__/ForumsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { ForumsPageQuery as ForumsPageQueryType } from "./__generated__/ForumsPageQuery.graphql";

export const ForumsPageQuery = graphql`
  query ForumsPageQuery {
    ...ForumsFragment
  }
`;

export const forumsPageQueryLoader = (environment: Environment) => {
  return () => preload<ForumsPageQueryType>(environment, ForumsPageQuery);
};

export const useForumsPageQuery = () => usePreloaded<ForumsPageQueryType>();
