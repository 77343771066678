/**
 * @generated SignedSource<<17a86d2f33fe491ef67ae59fa51483ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentDisplayFragment$data = {
  readonly author: {
    readonly id: string;
    readonly username: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  };
  readonly content: string;
  readonly createdAt: string;
  readonly edited: boolean;
  readonly id: string;
  readonly parent: {
    readonly id: string;
  } | null | undefined;
  readonly topic: {
    readonly id: string;
  };
  readonly viewerCanDelete: boolean;
  readonly viewerCanUpdate: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CommentDeleteFragment" | "VoteDisplayFragment">;
  readonly " $fragmentType": "CommentDisplayFragment";
};
export type CommentDisplayFragment$key = {
  readonly " $data"?: CommentDisplayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentDisplayFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentDisplayFragment",
  "selections": [
    {
      "alias": "viewerCanDelete",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "DELETE_COMMENT"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"DELETE_COMMENT\")"
    },
    {
      "alias": "viewerCanUpdate",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "UPDATE_COMMENT"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"UPDATE_COMMENT\")"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "edited",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentDeleteFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "thumbnail",
              "value": true
            }
          ],
          "kind": "FragmentSpread",
          "name": "EntityProfilePicFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoteDisplayFragment"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};
})();

(node as any).hash = "6948a8ea40fa4e65d81952a10fbb3047";

export default node;
