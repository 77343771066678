import { graphql, Environment } from "react-relay";
import { CompetitionsPageQuery as CompetitionsPageQueryType } from "./__generated__/CompetitionsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionsPageQuery as CompetitionsPageQueryType } from "./__generated__/CompetitionsPageQuery.graphql";

export const CompetitionsPageQuery = graphql`
  query CompetitionsPageQuery {
    ...CompetitionsFragment
  }
`;

export const competitionsPageQueryLoader = (environment: Environment) => {
  return () =>
    preload<CompetitionsPageQueryType>(environment, CompetitionsPageQuery);
};

export const useCompetitionsPageQuery = () =>
  usePreloaded<CompetitionsPageQueryType>();
