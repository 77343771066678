import { Environment, graphql } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { UserTopicsPageQuery as UserTopicsPageQueryType } from "./__generated__/UserTopicsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { UserTopicsPageQueryType };

export const UserTopicsPageQuery = graphql`
  query UserTopicsPageQuery($username: String!) {
    entityByUsername(username: $username) {
      ...UserTopicsPageFragment
    }
  }
`;

export function userTopicsQueryPageLoader(environment: Environment) {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<UserTopicsPageQueryType>(environment, UserTopicsPageQuery, {
      username,
    });
  };
}

export const useUserTopicsPageQuery = () =>
  usePreloaded<UserTopicsPageQueryType>();
