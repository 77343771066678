import { useMemo } from "react";
import { graphql, usePaginationFragment, useSubscription } from "react-relay";
import { GraphQLSubscriptionConfig } from "relay-runtime";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { MetaLayout } from "../common/MetaLayout";
import LoadMore from "../components/LoadMore";
import EntityProfilePic from "../components/EntityProfilePic";
import SubmissionStatusBadge from "../components/SubmissionStatusBadge";
import ApproveProjectVersionButton from "../components/ApproveProjectVersionButton";
import { useCompetitionApprovalPageQuery } from "./loaders/CompetitionApprovalPage";
import { CompetitionApprovalPageFragment$key } from "./__generated__/CompetitionApprovalPageFragment.graphql";
import { CompetitionApprovalPageSubscription as CompetitionApprovalPageSubscriptionType } from "./__generated__/CompetitionApprovalPageSubscription.graphql";
import ErrorPage from "./ErrorPage";

const CompetitionApprovalPageFragment = graphql`
  fragment CompetitionApprovalPageFragment on Competition
  @refetchable(queryName: "CompetitionApprovalPageFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    slug
    submissions(needsApproval: true, after: $cursor, first: $count)
      @connection(key: "CompetitionApprovalPageFragment_submissions") {
      edges {
        node {
          id
          entity {
            username
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
          latest {
            id
            version
            ...SubmissionStatusBadgeFragment
            ...ApproveProjectVersionButtonFragment
          }
        }
      }
    }
  }
`;

const CompetitionApprovalPageSubscription = graphql`
  subscription CompetitionApprovalPageSubscription($competitionId: ID!) {
    projectVersionStatusUpdate(competitionId: $competitionId) {
      project {
        latest {
          ...SubmissionStatusBadgeFragment
          ...ApproveProjectVersionButtonFragment
        }
      }
    }
  }
`;

interface CompetitionApprovalsProps {
  competition: CompetitionApprovalPageFragment$key;
}

function CompetitionApprovals({
  competition: competitionFragment,
}: CompetitionApprovalsProps) {
  const {
    data: competition,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    CompetitionApprovalPageFragment,
    competitionFragment,
  );
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Status" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Approve" />
            </th>
          </tr>
        </thead>
        <tbody>
          {competition.submissions.edges.map(({ node }) => {
            const entity = node.entity;
            const version = node.latest;
            if (!entity || !version) {
              return null;
            }
            return (
              <tr key={node.id}>
                <td className="whitespace-nowrap py-2 px-3">
                  <Link to={`/${entity.username}`}>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                      <div className="pr-4">
                        <EntityProfilePic entity={entity} size="12" />
                      </div>
                      <span>{entity.username}</span>
                    </div>
                  </Link>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  <span className="inline-block">
                    <SubmissionStatusBadge
                      label={
                        <Link
                          className="underline"
                          to={`/${entity.username}/submissions/${competition.slug}/${version.version}/code/package`}
                        >
                          v{version.version}
                        </Link>
                      }
                      projectVersion={version}
                    />
                  </span>
                </td>
                <td className="whitespace-nowrap py-2 px-3 text-center">
                  <ApproveProjectVersionButton projectVersion={version} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <LoadMore
        loadMore={loadNext}
        hasMore={hasNext}
        isLoading={isLoadingNext}
      />
    </div>
  );
}

export default function CompetitionApprovalPage() {
  const intl = useIntl();
  const {
    query: { competitionBySlug: competition },
  } = useCompetitionApprovalPageQuery();
  useSubscription(
    useMemo(
      (): GraphQLSubscriptionConfig<CompetitionApprovalPageSubscriptionType> => ({
        variables: { competitionId: competition?.id || "" },
        subscription: CompetitionApprovalPageSubscription,
        onError: (error) => {
          if (competition?.id) {
            console.error(error);
          }
        },
      }),
      [competition?.id],
    ),
  );
  if (!competition) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{CompetitionTitle} | leaderboard" },
        { CompetitionTitle: competition.title },
      )}
    >
      <h1 className="text-2xl font-bold w-full font-poppins">
        <FormattedMessage defaultMessage="Review" />
      </h1>
      <div className="pt-4 pb-8">
        <CompetitionApprovals competition={competition} />
      </div>
    </MetaLayout>
  );
}
