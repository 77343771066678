import React, { useContext } from "react";

export interface LocationContextValue {
  href: string;
  protocol: string;
  host: string;
  port: string;
  hostname: string;
  pathname: string;
  search: string;
  hash: string;
  origin: string;
  replace: (url: string) => void;
  assign: (url: string) => void;
}

export const LocationContext = React.createContext<LocationContextValue>({
  href: "",
  protocol: "",
  host: "",
  hostname: "",
  port: "",
  pathname: "",
  search: "",
  hash: "",
  origin: "",
  assign: () => {},
  replace: () => {},
});

export const useLocation = () => useContext(LocationContext);
