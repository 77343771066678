import { useIntl } from "react-intl";
import { useRouteError, ErrorResponse } from "react-router-dom";

export interface Props {
  status?: number;
  statusText?: string;
  message?: string;
  children?: React.ReactNode;
}

export default function ErrorPage(props: Props) {
  const error = useRouteError() as ErrorResponse | undefined;
  const intl = useIntl();

  const KNOWN_STATUS_TEXT = {
    400: intl.formatMessage({ defaultMessage: "Bad Request" }),
    401: intl.formatMessage({ defaultMessage: "Not Authorized" }),
    404: intl.formatMessage({ defaultMessage: "Not Found" }),
    415: intl.formatMessage({ defaultMessage: "Unsupported Media Type" }),
  };

  const status = (error?.status || props.status) ?? 500;
  const statusText =
    (error?.statusText || props.statusText) ??
    (status in KNOWN_STATUS_TEXT
      ? KNOWN_STATUS_TEXT[status as keyof typeof KNOWN_STATUS_TEXT]
      : intl.formatMessage({ defaultMessage: "Oops! Something went wrong" }));
  const message = error?.data?.message || props.message;

  return (
    <div className="container mx-auto h-full flex justify-center items-center px-4 py-4">
      <div className="w-full text-center">
        <h1 className="text-2xl">{statusText}</h1>
        <p className="font-bold text-4xl pb-1">{status}</p>
        <p className="text-gray-500 italic">
          {message ?? ""}
          {props.children}
        </p>
      </div>
    </div>
  );
}
