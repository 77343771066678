import { graphql, useFragment } from "react-relay";
import { ActivityCardHostFragment$key } from "./__generated__/ActivityCardHostFragment.graphql";
import Card from "./Card";
import AspectDiv from "./AspectDiv";
import EntityProfilePic from "./EntityProfilePic";
import defaultThumbnail from "../assets/background_16_9_white.svg";
import { useNavigate } from "react-router-dom";
import { ActivityCardTagsFragment$key } from "./__generated__/ActivityCardTagsFragment.graphql";
import Tag from "./CompetitionTag";

const ActivityCardHostFragment = graphql`
  fragment ActivityCardHostFragment on Entity {
    username
    ...EntityProfilePicFragment @arguments(thumbnail: true)
  }
`;

export const ActivityCardTagsFragment = graphql`
  fragment ActivityCardTagsFragment on TagConnection {
    edges {
      node {
        id
        ...CompetitionTagFragment
      }
    }
  }
`;

interface Props {
  title: string;
  shortDescription: string;
  thumbnail?: string;
  url: string;
  host: ActivityCardHostFragment$key;
  tags?: ActivityCardTagsFragment$key;
}

export default function ActivityCard({
  title,
  shortDescription,
  thumbnail,
  url,
  host: hostFragment,
  tags: tagsFragment,
}: Props) {
  const host = useFragment(ActivityCardHostFragment, hostFragment);
  const tags = useFragment(ActivityCardTagsFragment, tagsFragment);
  const navigate = useNavigate();

  return (
    <Card
      className="h-full flex flex-col"
      onClick={(event) => {
        navigate(url);
        event.stopPropagation();
      }}
    >
      <div>
        <AspectDiv
          className="rounded-t-md bg-grey"
          style={{
            backgroundImage: `url(${defaultThumbnail})`,
            backgroundSize: "1000px",
          }}
          ratio={2}
        >
          {thumbnail && (
            <img
              src={thumbnail}
              className="h-full w-full rounded-t-md object-cover object-center"
            />
          )}
        </AspectDiv>
      </div>
      <div className="p-4 flex flex-row flex-1">
        <div className="flex-grow flex flex-col">
          {tags && (
            <div className="gap-2 flex flex-row flex-wrap">
              {tags.edges.map((tag) => (
                <Tag tag={tag.node} key={tag.node.id} />
              ))}
            </div>
          )}
          <div className="flex-1">
            <h1 className="text-xl font-bold font-poppins">{title}</h1>
          </div>
          <div className="flex-1">
            <h2 className="text-l">{shortDescription}</h2>
          </div>
        </div>
        <div
          className="flex-grow-0"
          onClick={(event) => {
            navigate(`/${host.username}`);
            event.stopPropagation();
          }}
        >
          <div className="w-12">
            <EntityProfilePic entity={host} />
          </div>
        </div>
      </div>
    </Card>
  );
}
