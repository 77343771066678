/**
 * @generated SignedSource<<6e4988becd5205ebd76e9efe1371ceba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectVersionFileKind = "DATA" | "PACKAGE" | "SUBMISSION_EVALUATION" | "TEMPLATE";
export type EntitySubmissionCodeTreePageQuery$variables = {
  entity?: string | null | undefined;
  fileKind: ProjectVersionFileKind;
  path?: string | null | undefined;
  slug: string;
  version?: string | null | undefined;
};
export type EntitySubmissionCodeTreePageQuery$data = {
  readonly competitionBySlug: {
    readonly submission: {
      readonly entity: {
        readonly username: string;
      };
      readonly version: {
        readonly fileByKind: {
          readonly " $fragmentSpreads": FragmentRefs<"FileBrowserFragment">;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type EntitySubmissionCodeTreePageQuery = {
  response: EntitySubmissionCodeTreePageQuery$data;
  variables: EntitySubmissionCodeTreePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entity"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileKind"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "path"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v5 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "entity",
    "variableName": "entity"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "version",
    "variableName": "version"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "fileKind"
  }
],
v10 = {
  "kind": "Variable",
  "name": "path",
  "variableName": "path"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntitySubmissionCodeTreePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Submission",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "args": [
                          (v10/*: any*/)
                        ],
                        "kind": "FragmentSpread",
                        "name": "FileBrowserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "EntitySubmissionCodeTreePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Competition",
        "kind": "LinkedField",
        "name": "competitionBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Submission",
            "kind": "LinkedField",
            "name": "submission",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "entity",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v7/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ProjectVersion",
                "kind": "LinkedField",
                "name": "version",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "ProjectVersionFile",
                    "kind": "LinkedField",
                    "name": "fileByKind",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FileBrowser",
                        "kind": "LinkedField",
                        "name": "browse",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "concreteType": "FileBrowserEntryConnection",
                            "kind": "LinkedField",
                            "name": "readDir",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FileBrowserEntryEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v11/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "kind",
                                        "storageKey": null
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "contentType",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "contentLength",
                                            "storageKey": null
                                          }
                                        ],
                                        "type": "FileBrowserFileEntry",
                                        "abstractKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PageInfo",
                                "kind": "LinkedField",
                                "name": "pageInfo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v13/*: any*/),
                            "filters": [
                              "path"
                            ],
                            "handle": "connection",
                            "key": "FileBrowser_readDir",
                            "kind": "LinkedHandle",
                            "name": "readDir"
                          }
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4ad270c7736d5251c5ee869ce5e1cba",
    "id": null,
    "metadata": {},
    "name": "EntitySubmissionCodeTreePageQuery",
    "operationKind": "query",
    "text": "query EntitySubmissionCodeTreePageQuery(\n  $entity: UsernameOrID\n  $slug: String!\n  $version: Semver\n  $fileKind: ProjectVersionFileKind!\n  $path: String\n) {\n  competitionBySlug(slug: $slug) {\n    submission(entity: $entity) {\n      entity {\n        __typename\n        username\n        id\n      }\n      version(version: $version) {\n        fileByKind(kind: $fileKind) {\n          ...FileBrowserFragment_1kBEMX\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment FileBrowserFragment_1kBEMX on ProjectVersionFile {\n  browse {\n    readDir(path: $path, first: 100) {\n      edges {\n        node {\n          __typename\n          name\n          kind\n          ... on FileBrowserFileEntry {\n            contentType\n            contentLength\n          }\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "048ca6850b36b369d5495008f3769ed3";

export default node;
