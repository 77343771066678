import * as Drawer from "../Drawer";
import * as Modal from "./Modal";
import { type PropsWithChildren } from "react";
import { DESKTOP_MEDIA_QUERY } from "../../common/constants";
import { useMediaQuery } from "../../utils/hooks";
import { cn } from "../../utils/tailwind";

export interface RootCredenzaProps extends PropsWithChildren {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

export interface CredenzaProps extends PropsWithChildren {
  className?: string;
  asChild?: true;
  onClick?: () => void;
}

const Root = ({ children, ...props }: RootCredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const Credenza = isDesktop ? Modal.Root : Drawer.Root;

  return <Credenza {...props}>{children}</Credenza>;
};

const Trigger = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaTrigger = isDesktop ? Modal.Trigger : Drawer.Trigger;

  return (
    <CredenzaTrigger className={className} {...props}>
      {children}
    </CredenzaTrigger>
  );
};

const Overlay = ({ className, ...props }: Omit<CredenzaProps, "children">) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaOverlay = isDesktop ? Modal.Overlay : Drawer.Overlay;

  return <CredenzaOverlay className={className} {...props} />;
};

const Close = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaClose = isDesktop ? Modal.Close : Drawer.Close;

  return (
    <>
      <CredenzaClose className={className} {...props}>
        {children}
      </CredenzaClose>
    </>
  );
};

const CloseDesktop = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);

  return (
    <>
      {isDesktop && (
        <Modal.Close className={className} {...props}>
          {children}
        </Modal.Close>
      )}
    </>
  );
};

const CloseMobile = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);

  return (
    <>
      {!isDesktop && (
        <Drawer.Close className={className} {...props}>
          {children}
        </Drawer.Close>
      )}
    </>
  );
};

const Content = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);

  const CredenzaContent = isDesktop ? Modal.Content : Drawer.Content;

  return (
    <CredenzaContent className={className} {...props}>
      {children}
    </CredenzaContent>
  );
};

const Description = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaDescription = isDesktop
    ? Modal.Description
    : Drawer.Description;

  return (
    <CredenzaDescription className={className} {...props}>
      {children}
    </CredenzaDescription>
  );
};

const Header = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaHeader = isDesktop ? Modal.Header : Drawer.Header;

  return (
    <CredenzaHeader className={className} {...props}>
      {children}
    </CredenzaHeader>
  );
};

const Title = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaTitle = isDesktop ? Modal.Title : Drawer.Title;

  return (
    <CredenzaTitle className={className} {...props}>
      {children}
    </CredenzaTitle>
  );
};

const Body = ({ className, children, ...props }: CredenzaProps) => {
  return (
    <div className={cn("px-4 md:px-0", className)} {...props}>
      {children}
    </div>
  );
};

const Footer = ({ className, children, ...props }: CredenzaProps) => {
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const CredenzaFooter = isDesktop ? Modal.Footer : Drawer.Footer;

  return (
    <CredenzaFooter className={className} {...props}>
      {children}
    </CredenzaFooter>
  );
};

Close.Desktop = CloseDesktop;
Close.Mobile = CloseMobile;

export {
  Root,
  Trigger,
  Overlay,
  Close,
  Content,
  Description,
  Header,
  Title,
  Body,
  Footer,
};
