import { FormattedRelativeTime } from "react-intl";

const DEFAULT_REFRESH_INTERVAL = 60;

type FormattedRelativeTimeProps = React.ComponentProps<
  typeof FormattedRelativeTime
>;

interface TimeAgoProps {
  createdAt: Date;
}

const getTimeDifference = (
  date: Date,
): {
  value: number;
  unit: FormattedRelativeTimeProps["unit"];
  refreshInterval?: number;
} => {
  const now = Date.now();
  const timeDifferenceInSeconds = (now - date.getTime()) / 1000;

  if (timeDifferenceInSeconds < 60) {
    // Less than 1 minute
    return {
      value: Math.round(timeDifferenceInSeconds),
      unit: "second",
      refreshInterval: DEFAULT_REFRESH_INTERVAL,
    };
  } else if (timeDifferenceInSeconds < 60 * 60) {
    // Less than 1 hour
    return {
      value: Math.round(timeDifferenceInSeconds / 60),
      unit: "minute",
      refreshInterval: DEFAULT_REFRESH_INTERVAL,
    };
  } else if (timeDifferenceInSeconds < 24 * 60 * 60) {
    // Less than 1 day
    return {
      value: Math.round(timeDifferenceInSeconds / (60 * 60)),
      unit: "hour",
      refreshInterval: DEFAULT_REFRESH_INTERVAL,
    };
  } else if (timeDifferenceInSeconds < 31 * 24 * 60 * 60) {
    // Less than 1 month
    return {
      value: Math.round(timeDifferenceInSeconds / (24 * 60 * 60)),
      unit: "day",
    };
  } else if (timeDifferenceInSeconds < 12 * 31 * 24 * 60 * 60) {
    // Less than 1 year
    return {
      value: Math.round(timeDifferenceInSeconds / (31 * 24 * 60 * 60)),
      unit: "month",
    };
  } else {
    // More than 1 year
    return {
      value: Math.round(timeDifferenceInSeconds / (12 * 31 * 24 * 60 * 60)),
      unit: "year",
    };
  }
};

export const TimeAgo = ({ createdAt }: Readonly<TimeAgoProps>) => {
  const { value, unit, refreshInterval } = getTimeDifference(createdAt);

  return (
    <time dateTime={createdAt.toISOString()} suppressHydrationWarning>
      <FormattedRelativeTime
        value={-value}
        unit={unit}
        numeric="auto"
        updateIntervalInSeconds={refreshInterval}
      />
    </time>
  );
};
