interface SocialShare {
  injectedText?: string;
  url: string;
}

const getLinkedInUrl = ({
  injectedText,
  url,
}: Readonly<SocialShare>): string => {
  const linkedInUrl = new URL("https://www.linkedin.com/feed/");
  linkedInUrl.searchParams.set("mini", "true");

  if (injectedText) {
    linkedInUrl.searchParams.set("text", injectedText);
  }

  linkedInUrl.searchParams.set("shareUrl", url);

  return linkedInUrl.toString().replace("mini=true", "shareActive&mini=true");
};

const getTwitterUrl = ({
  injectedText,
  url,
}: Readonly<SocialShare>): string => {
  const twitterUrl = new URL("https://twitter.com/intent/tweet");

  if (injectedText) {
    twitterUrl.searchParams.set("text", injectedText);
  }

  twitterUrl.searchParams.set("url", url);

  return twitterUrl.toString();
};

export { getLinkedInUrl, getTwitterUrl };
