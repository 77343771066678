/**
 * @generated SignedSource<<a92aefafbc3ea73018bb69ac5dbb82f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberSelectAllEntitiesAutoCompleteFragment$data = {
  readonly entities: {
    readonly " $fragmentSpreads": FragmentRefs<"MemberSelectAllEntitiesFragment">;
  };
  readonly " $fragmentType": "MemberSelectAllEntitiesAutoCompleteFragment";
};
export type MemberSelectAllEntitiesAutoCompleteFragment$key = {
  readonly " $data"?: MemberSelectAllEntitiesAutoCompleteFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberSelectAllEntitiesAutoCompleteFragment">;
};

import MemberSelectAllMembersAutoCompleteRefetchQuery_graphql from './MemberSelectAllMembersAutoCompleteRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": MemberSelectAllMembersAutoCompleteRefetchQuery_graphql
    }
  },
  "name": "MemberSelectAllEntitiesAutoCompleteFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "EntityConnection",
      "kind": "LinkedField",
      "name": "entities",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MemberSelectAllEntitiesFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "e286faa07e7f15c3d411cb5d800483c5";

export default node;
