import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useForumTopicCreatePageQuery } from "./loaders/ForumTopicCreatePage";
import {
  ForumTopicCreatePageMutation$variables,
  ForumTopicCreatePageMutation as ForumTopicCreatePageMutationType,
} from "./__generated__/ForumTopicCreatePageMutation.graphql";
import ErrorPage from "./ErrorPage";
import TopicEditForm from "../components/TopicEditForm";
import { DEFAULT_VOTABLE_ORDER } from "../utils/votableOrder";
import { getTopicPath } from "../utils/routing";

const ForumTopicCreatePageMutation = graphql`
  mutation ForumTopicCreatePageMutation(
    $forumId: ID!
    $input: CreateTopicInput!
    $connections: [ID!]!
  ) {
    createTopicForForum(forumId: $forumId, input: $input)
      @prependEdge(connections: $connections) {
      node {
        id
        title
      }
    }
  }
`;

export default function ForumTopicCreatePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const { query } = useForumTopicCreatePageQuery();
  const forum = query?.forumBySlug;
  const [commitMutation, isMutationInFlight] =
    useMutation<ForumTopicCreatePageMutationType>(ForumTopicCreatePageMutation);
  const { order } = location.state || {};

  if (!slug || !forum) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Discussion not found",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage={"New Topic"} />
      </h1>
      <TopicEditForm
        guidelines={forum.guidelines ?? undefined}
        onSubmit={({ data, setFormError }) => {
          const variables: ForumTopicCreatePageMutation$variables = {
            forumId: forum.id,
            input: {
              title: data.title,
              url: data.url || undefined,
              description: data.description || undefined,
            },
            connections: [
              ConnectionHandler.getConnectionID(
                forum.id,
                "ForumTopicsFragment_topics",
                {
                  order: order || DEFAULT_VOTABLE_ORDER,
                },
              ),
            ],
          };
          commitMutation({
            variables,
            onError: (error) => {
              console.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage: "Could not create topic :(",
                }),
              );
            },
            onCompleted: ({ createTopicForForum: { node } }) => {
              navigate(
                getTopicPath({
                  topic: node.id,
                  title: node.title,
                  parent: { forum: slug },
                }),
              );
            },
          });
        }}
        isDisabled={isMutationInFlight}
      />
    </div>
  );
}
