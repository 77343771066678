import { useHomePageQuery } from "./loaders/HomePage";
import Competitions from "../components/Competitions";
import Events from "../components/Events";
import { MetaLayout } from "../common/MetaLayout";
import { useIntl } from "react-intl";

export default function HomePage() {
  const intl = useIntl();
  const { query } = useHomePageQuery();
  return (
    <MetaLayout metaTitle={intl.formatMessage({ defaultMessage: "Home" })}>
      <div className="space-y-6 pb-10">
        <Events query={query} />
        <Competitions query={query} />
      </div>
    </MetaLayout>
  );
}
