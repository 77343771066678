import { useCompetitionCodeTreePageQuery } from "./loaders/CompetitionCodeTreePage";
import FileBrowser from "../components/FileBrowser";

export default function CompetitionCodeTreePage() {
  const {
    query,
    variables: { path, slug, fileKind },
  } = useCompetitionCodeTreePageQuery();
  const file = query.competitionBySlug?.useCase.latest?.fileByKind;
  return (
    <div className="space-y-6">
      {file && (
        <FileBrowser
          root={`/competitions/${slug}/code/${fileKind.toLowerCase()}`}
          file={file}
          path={path || ""}
        />
      )}
    </div>
  );
}
