import { useEventsPageQuery } from "./loaders/EventsPage";
import Events from "../components/Events";
import { useIntl } from "react-intl";
import { MetaLayout } from "../common/MetaLayout";

export default function EventsPage() {
  const intl = useIntl();
  const { query } = useEventsPageQuery();
  return (
    <MetaLayout metaTitle={intl.formatMessage({ defaultMessage: "Events" })}>
      <div className="pb-10">
        <Events query={query} />
      </div>
    </MetaLayout>
  );
}
