import { graphql, useFragment } from "react-relay";
import { EventCardFragment$key } from "./__generated__/EventCardFragment.graphql";
import ActivityCard from "./ActivityCard";

const EventCardFragment = graphql`
  fragment EventCardFragment on Event {
    id
    slug
    title
    shortDescription
    thumbnail
    host {
      ...ActivityCardHostFragment
    }
  }
`;

interface Props {
  event: EventCardFragment$key;
}

export default function EventCard({ event: eventFragment }: Props) {
  const { title, shortDescription, thumbnail, slug, host } = useFragment(
    EventCardFragment,
    eventFragment,
  );
  return (
    <ActivityCard
      title={title}
      shortDescription={shortDescription}
      thumbnail={thumbnail}
      url={`/events/${slug}`}
      host={host}
    />
  );
}
