import { ConnectionHandler, graphql, useMutation } from "react-relay";
import { EventMembersFragment$key } from "./__generated__/EventMembersFragment.graphql";
import { EventMembersDeleteMutation as EventMembersDeleteMutationType } from "./__generated__/EventMembersDeleteMutation.graphql";
import { EventMembersPageTransferOwnershipMutation as EventMembersPageTransferOwnershipMutationType } from "./__generated__/EventMembersPageTransferOwnershipMutation.graphql";
import { usePaginationFragment } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import EntityProfilePic from "./EntityProfilePic";
import { MdMoreHoriz } from "react-icons/md";
import Dropdown from "./Dropdown";
import { formatCompetitionMembershipKind } from "../utils/format";
import { useAuth } from "../utils/auth";
import LoadMore from "./LoadMore";
import { Link } from "react-router-dom";
import { ToolbarButton } from "./Button";
import { logger } from "../common/logger";

const EventMembersFragment = graphql`
  fragment EventMembersFragment on Event
  @refetchable(queryName: "EventMembersFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    id
    viewerCanEdit: viewerCan(action: UPDATE_EVENT)
    viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)
    viewerCanTransferOwnership: viewerCan(action: TRANSFER_EVENT_OWNERSHIP)
    members(first: $count, after: $cursor)
      @connection(key: "EventMembersFragment_members") {
      edges {
        node {
          id
          kind
          viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)
          entity {
            id
            username
            displayName
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
      }
    }
  }
`;

const EventMembersPageTransferOwnershipMutation = graphql`
  mutation EventMembersPageTransferOwnershipMutation(
    $eventId: ID!
    $toUserId: ID!
    $connections: [ID!]!
  ) {
    transferEventOwnership(eventId: $eventId, toEntityId: $toUserId)
      @prependEdge(connections: $connections) {
      node {
        id
        kind
        viewerCanDelete: viewerCan(action: REMOVE_EVENT_MEMBER)
        event {
          id
          viewerCanEdit: viewerCan(action: UPDATE_EVENT)
          viewerCanEditMembers: viewerCan(action: ADD_EVENT_MEMBER)
          viewerCanTransferOwnership: viewerCan(
            action: TRANSFER_EVENT_OWNERSHIP
          )
          host {
            id
            displayName
            username
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
        }
        entity {
          id
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
      }
    }
  }
`;

const EventMembersDeleteMutation = graphql`
  mutation EventMembersDeleteMutation($id: ID!, $connections: [ID!]!) {
    removeEventMember(id: $id) @deleteEdge(connections: $connections)
  }
`;

interface Props {
  user: EventMembersFragment$key;
}

export default function EventMembers({ user: userFragment }: Props) {
  const { userId: viewerId } = useAuth();
  const intl = useIntl();
  const {
    data: event,
    refetch,
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(EventMembersFragment, userFragment);
  const [commitDeleteMutation, isDeleteMutationInFlight] =
    useMutation<EventMembersDeleteMutationType>(EventMembersDeleteMutation);
  const [commitTransferOwnershipMutation, isTransferOwnershipMutationInFlight] =
    useMutation<EventMembersPageTransferOwnershipMutationType>(
      EventMembersPageTransferOwnershipMutation,
    );
  if (event.members.edges.length == 0) {
    return (
      <p className="text-gray-400">
        <FormattedMessage defaultMessage="No Users" />
      </p>
    );
  }
  const onDelete = (id: string, userId: string) => {
    commitDeleteMutation({
      variables: {
        id,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventMembersFragment_members",
          ),
        ],
      },
      onError: (error) => {
        logger.error(error);
      },
      onCompleted: () => {
        if (userId === viewerId) {
          refetch({});
        }
      },
    });
  };
  const onTransferOwnershipSubmit = (userId: string) => {
    commitTransferOwnershipMutation({
      variables: {
        eventId: event.id,
        toUserId: userId,
        connections: [
          ConnectionHandler.getConnectionID(
            event.id,
            "EventMembersFragment_members",
          ),
        ],
      },
      updater: (store) => {
        const record = store.get(event.id);
        if (!record) {
          return;
        }
        const connection = ConnectionHandler.getConnection(
          record,
          "EventMembersFragment_members",
        );
        if (!connection) {
          return;
        }
        const oldParticipantId = connection
          .getLinkedRecords("edges")
          ?.find((edge) => {
            const node = edge?.getLinkedRecord("node");
            return (
              node?.getValue("kind") === "PARTICIPANT" &&
              node?.getLinkedRecord("entity")?.getValue("id") === userId
            );
          })
          ?.getLinkedRecord("node")
          ?.getValue("id");
        if (typeof oldParticipantId === "string") {
          ConnectionHandler.deleteNode(connection, oldParticipantId);
        }
      },
      onError: (error) => {
        logger.error(error);
      },
    });
  };
  return (
    <div className="overflow-x-auto">
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="User" />
            </th>
            <th className="py-3 px-3 bg-gray-100">
              <FormattedMessage defaultMessage="Membership" />
            </th>
            <th className="py-3 px-3 bg-gray-100"></th>
          </tr>
        </thead>
        <tbody>
          {event.members.edges.map(({ node: membership }) => (
            <tr key={membership.id}>
              <td className="whitespace-nowrap py-2 px-3">
                <Link to={`/${membership.entity.username}`}>
                  <div className="flex flex-row items-center hover:cursor-pointer">
                    <div className="w-12 pr-4">
                      <EntityProfilePic entity={membership.entity} />
                    </div>
                    <span>{membership.entity.displayName}</span>
                  </div>
                </Link>
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {formatCompetitionMembershipKind(intl, membership.kind)}
              </td>
              <td className="whitespace-nowrap py-2 px-3 text-center">
                {membership.kind !== "HOST" && (
                  <Dropdown
                    trigger={
                      <ToolbarButton size="sm">
                        <MdMoreHoriz />
                      </ToolbarButton>
                    }
                  >
                    {membership.viewerCanDelete && (
                      <Dropdown.Item
                        disabled={isDeleteMutationInFlight}
                        onClick={() => {
                          onDelete(membership.id, membership.entity.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Remove user" />
                      </Dropdown.Item>
                    )}
                    {event.viewerCanTransferOwnership && (
                      <Dropdown.Item
                        disabled={isTransferOwnershipMutationInFlight}
                        onClick={() => {
                          onTransferOwnershipSubmit(membership.entity.id);
                        }}
                      >
                        <FormattedMessage defaultMessage="Transfer Ownership" />
                      </Dropdown.Item>
                    )}
                  </Dropdown>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="py-8">
        <LoadMore
          loadMore={loadNext}
          hasMore={hasNext}
          isLoading={isLoadingNext}
        />
      </div>
    </div>
  );
}
