/**
 * @generated SignedSource<<32f3234780e85496e2f4d6e82f8485fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventEditPageQuery$variables = {
  slug: string;
};
export type EventEditPageQuery$data = {
  readonly eventBySlug: {
    readonly banner: any | null | undefined;
    readonly id: string;
    readonly isPrivate: boolean;
    readonly shortDescription: string;
    readonly slug: string;
    readonly thumbnail: any | null | undefined;
    readonly title: string;
    readonly viewerCanUpdateAgenda: boolean;
  } | null | undefined;
};
export type EventEditPageQuery = {
  response: EventEditPageQuery$data;
  variables: EventEditPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Event",
    "kind": "LinkedField",
    "name": "eventBySlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "shortDescription",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "banner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "thumbnail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPrivate",
        "storageKey": null
      },
      {
        "alias": "viewerCanUpdateAgenda",
        "args": [
          {
            "kind": "Literal",
            "name": "action",
            "value": "UPDATE_AGENDA"
          }
        ],
        "kind": "ScalarField",
        "name": "viewerCan",
        "storageKey": "viewerCan(action:\"UPDATE_AGENDA\")"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventEditPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventEditPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02424f9233dcc07d71b1d0f4192083d7",
    "id": null,
    "metadata": {},
    "name": "EventEditPageQuery",
    "operationKind": "query",
    "text": "query EventEditPageQuery(\n  $slug: String!\n) {\n  eventBySlug(slug: $slug) {\n    id\n    slug\n    title\n    shortDescription\n    banner\n    thumbnail\n    isPrivate\n    viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)\n  }\n}\n"
  }
};
})();

(node as any).hash = "024f41b0b8ac48795725381d63084750";

export default node;
