/**
 * @generated SignedSource<<bcae46b2fc14758c83ded4f34abc8e8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionApprovalPageFragment$data = {
  readonly id: string;
  readonly slug: string;
  readonly submissions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly entity: {
          readonly username: string;
          readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
        };
        readonly id: string;
        readonly latest: {
          readonly id: string;
          readonly version: string;
          readonly " $fragmentSpreads": FragmentRefs<"ApproveProjectVersionButtonFragment" | "SubmissionStatusBadgeFragment">;
        } | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "CompetitionApprovalPageFragment";
};
export type CompetitionApprovalPageFragment$key = {
  readonly " $data"?: CompetitionApprovalPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionApprovalPageFragment">;
};

import CompetitionApprovalPageFragmentPaginationQuery_graphql from './CompetitionApprovalPageFragmentPaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "submissions"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": CompetitionApprovalPageFragmentPaginationQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "CompetitionApprovalPageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": "submissions",
      "args": [
        {
          "kind": "Literal",
          "name": "needsApproval",
          "value": true
        }
      ],
      "concreteType": "SubmissionConnection",
      "kind": "LinkedField",
      "name": "__CompetitionApprovalPageFragment_submissions_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SubmissionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Submission",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "entity",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    },
                    {
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "thumbnail",
                          "value": true
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "EntityProfilePicFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProjectVersion",
                  "kind": "LinkedField",
                  "name": "latest",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "version",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "SubmissionStatusBadgeFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ApproveProjectVersionButtonFragment"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__CompetitionApprovalPageFragment_submissions_connection(needsApproval:true)"
    },
    (v1/*: any*/)
  ],
  "type": "Competition",
  "abstractKey": null
};
})();

(node as any).hash = "102b6c3f0a632aaf53c451ec5078fc51";

export default node;
