import React from "react";
import { graphql, useMutation } from "react-relay";
import { CompetitionRulesPageUpdateCompetitionMutation as CompetitionRulesPageUpdateCompetitionMutationType } from "./__generated__/CompetitionRulesPageUpdateCompetitionMutation.graphql";
import { useCompetitionRulesPageQuery } from "./loaders/CompetitionRulesPage";
import ErrorPage from "./ErrorPage";
import { useIntl, FormattedMessage } from "react-intl";
import EditMarkdownSection from "../components/EditMarkdownSection";
import CompetitionAcceptRulesButton from "../components/CompetitionAcceptRulesButton";
import Loading from "../components/Loading";
import { logger } from "../common/logger";
import { MetaLayout } from "../common/MetaLayout";

const CompetitionRulesPageUpdateCompetitionMutation = graphql`
  mutation CompetitionRulesPageUpdateCompetitionMutation(
    $id: ID!
    $input: UpdateCompetitionInput!
  ) {
    updateCompetition(id: $id, input: $input) {
      node {
        id
        latestRule {
          id
          text
          entityAgreement {
            id
            createdAt
          }
        }
      }
    }
  }
`;

export default function CompetitionRulesPage() {
  const intl = useIntl();
  const { query } = useCompetitionRulesPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionRulesPageUpdateCompetitionMutationType>(
      CompetitionRulesPageUpdateCompetitionMutation,
    );
  if (!query.competitionBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Competition not found",
        })}
      />
    );
  }
  const competition = query.competitionBySlug;
  return (
    <MetaLayout
      metaTitle={intl.formatMessage(
        { defaultMessage: "{competitionTitle} | rules" },
        { competitionTitle: query.competitionBySlug.title },
      )}
      metaDescription={competition.latestRule.text}
    >
      <EditMarkdownSection
        members={{
          kind: "competition",
          members: competition,
        }}
        title={
          <div className="flex flex-row space-x-5">
            <div className="flex-grow">
              <h1 className="text-2xl font-bold w-full font-poppins">
                <FormattedMessage defaultMessage="Rules" />
              </h1>
              <p className="py-2">
                <FormattedMessage defaultMessage="By default all competitions must comply to the rules defined in" />{" "}
                <a
                  href="https://aqora.io/terms"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-400"
                >
                  <FormattedMessage defaultMessage="aqora's Terms of Use" />
                </a>
              </p>
            </div>
            <div className="self-center">
              {query.viewer && competition.viewerCanAccept && (
                <React.Suspense fallback={<Loading className="w-10" />}>
                  <CompetitionAcceptRulesButton competition={competition} />
                </React.Suspense>
              )}
            </div>
          </div>
        }
        canEdit={competition.viewerCanUpdate}
        disabled={isMutationInFlight}
        defaultValue={competition.latestRule.text}
        onSubmit={(value, setError, onCompleted) => {
          commitMutation({
            variables: {
              id: competition.id,
              input: {
                rules: value || null,
              },
            },
            onError: (error) => {
              logger.error(error);
              setError(
                intl.formatMessage({
                  defaultMessage:
                    "An error occurred while saving the description. Please, try again later.",
                }),
              );
            },
            onCompleted,
          });
        }}
      />
    </MetaLayout>
  );
}
