import { useMutation } from "react-relay";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { FormattedMessage, defineMessage, useIntl } from "react-intl";
import { UnsubscribePageMutation as UnsubscribePageMutationType } from "./__generated__/UnsubscribePageMutation.graphql";
import { toast } from "sonner";
import Button from "../components/Button";
import Card from "../components/Card";
import Logo from "../components/Logo";
import { formatNotificationToast } from "../utils/notifications";
import { MdNotificationsOff } from "react-icons/md";
import { logger } from "../common/logger";

const UnsubscribePageMutation = graphql`
  mutation UnsubscribePageMutation($token: String!) {
    notification: unsubscribeNotificationForToken(token: $token)
  }
`;

export default function UnsubscribePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const token = searchParams.get("tok");
  const [commitMutation, isCommiting] =
    useMutation<UnsubscribePageMutationType>(UnsubscribePageMutation);

  if (typeof token !== "string") {
    return <Navigate to="/" />;
  }

  const onUnsubscribe = (evt: React.MouseEvent) => {
    evt.preventDefault();

    commitMutation({
      variables: { token },
      onCompleted({ notification }, errors) {
        if (notification && !errors?.length) {
          navigate("/");
          toast(formatNotificationToast(intl, notification, false));
        } else {
          logger.error(errors);
          toast.error(
            intl.formatMessage({
              defaultMessage:
                "There was an error with your request, please try again",
            }),
          );
        }
      },
    });
  };

  return (
    <div className="container mx-auto h-full flex justify-center items-center px-4 py-4">
      <div className="w-full max-w-lg flex flex-col">
        <div className="w-48 py-4">
          <Logo />
        </div>
        <Card className="p-8 flex flex-col gap-4">
          <p>
            <FormattedMessage {...unsubscribeText} />
          </p>
          <Button
            onClick={onUnsubscribe}
            disabled={isCommiting}
            className="flex gap-1 items-center justify-center"
          >
            <span>Unsubscribe</span>
            <MdNotificationsOff />
          </Button>
        </Card>
      </div>
    </div>
  );
}

const unsubscribeText = defineMessage({
  defaultMessage: `To fully process your unsubscription request, please click the button below. \
This final step ensures that we accurately remove your information from our mailing list and cease all future communications. \
We value your privacy and strive to respect your preferences, so your prompt action will help us complete this request efficiently. \
Thank you for your cooperation.`,
});
